<script setup></script>

<template>
  <VRow class="full-height">
    <VCol cols="12" md="6" class="full-height">
      <VCard class="person-card full-height">
        <section class="top-title">
          <span class="st-number">6</span>
          <span>정보수집</span>
        </section>
        <section class="btm-body">
          <dl class="txt02">
            <dt>특징요약</dt>
            <dd>세상과 사물을 바라보는 방식은 논리적 판단, 호기심의 경향이 강하게 나타납니다.</dd>
          </dl>
          <dl class="txt02">
            <dt>기본특징</dt>
            <dd>
              내면적으로 외부 세계에 대한 호기심이 강하며, 새로운 변화가 필요하지만 변화를
              시도하기에 힘이 부족한 상황일 수 있습니다. 자기 중심적인 판단을 내리기 쉬워 다른
              사람들의 의견을 경청하지 않으며, 때로는 냉정하게 보일 수 있습니다. 이러한 성향은
              사회적 상호작용에서 고립을 초래할 수 있으므로, 타인의 의견을 수용하는 능력을 키워야
              합니다. 따라서, 단순함과 호기심을 통해 긍정적인 성장을 이루고 불안감을 극복하는
              방향으로 나아가야 합니다.
            </dd>
          </dl>
          <dl class="txt02">
            <dt>상세특징</dt>
            <dd>
              단순함과 호기심을 통해 긍정적인 성장을 이루고 불안감을 극복하는 방향으로 나아가야
              합니다.
            </dd>
          </dl>
          <div class="com-btm-line-01"></div>
          <dl class="txt02">
            <dt>주요 강점</dt>
            <dd class="keyword st-blue"><span>유연성</span><span>배려심</span></dd>
          </dl>
          <dl class="txt02">
            <dt>보완이 필요한 부분</dt>
            <dd class="keyword st-red"><span>결정지연</span><span>자기 표현 부족</span></dd>
          </dl>
        </section>
      </VCard>
    </VCol>
    <VCol cols="12" md="6">
      <VCard class="person-card full-height">
        <section class="top-title">
          <span class="st-number">7</span>
          <span>판단기준</span>
        </section>
        <section class="btm-body">
          <dl class="txt02">
            <dt>특징요약</dt>
            <dd>
              의사결정 과정에서 중시하는 핵심 가치는 예상치 못한 충돌, 내적 성장, 적응와 같은
              키워드입니다.
            </dd>
          </dl>
          <dl class="txt02">
            <dt>기본특징</dt>
            <dd>
              변화에 민감하게 반응하고 적응력이 뛰어난 성향이 있습니다. 새로운 환경이나 상황에서도
              빠르게 적응하며, 변화를 긍정적으로 받아들이는 능력을 가지고 있습니다. 다만, 너무 많은
              변화가 동시에 일어날 때는 심리적인 피로감을 느낄 수 있으므로, 자신의 속도에 맞춰
              조절하는 것이 필요합니다. 변화 속에서 기회를 찾아내면 큰 성과를 얻을 수
              있습니다. 또한, 특정 상황에서 사고하거나 결정을 내릴 때 중요하게 고려하는 특징은
              다음과 같습니다.
            </dd>
          </dl>
          <dl class="txt02">
            <dt>상세특징</dt>
            <dd>
              신체적으로 팔이나 다리, 또는 뼈와 관련된 부상이나 골절이 발생할 수 있는 상황에 노출될
              수 있습니다. 특히 과도한 무리나 부주의한 행동으로 인해 다칠 수 있으니, 평소에 안전과
              건강을 신경 쓰는 것이 필요합니다. 사고를 예방하기 위해선 무리한 활동을 자제하고 적절한
              휴식을 취하는 것이 중요합니다.
            </dd>
          </dl>
          <div class="com-btm-line-01"></div>
          <dl class="txt02">
            <dt>주요 강점</dt>
            <dd class="keyword st-blue"><span>유연성</span><span>배려심</span></dd>
          </dl>
          <dl class="txt02">
            <dt>보완이 필요한 부분</dt>
            <dd class="keyword st-red"><span>결정지연</span><span>자기 표현 부족</span></dd>
          </dl>
        </section>
      </VCard>
    </VCol>
  </VRow>
</template>
