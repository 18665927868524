<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  contents: Object,
  curIlgan: String,
  IlganKC: String,
})
const { ...five } = fiveMixin()

/** 조사 구분 */
// 두 번째 글자로 판단
const checkJongsung = (name) => {
  if (name.length < 2) return '이';
  const secondChar = name.charCodeAt(1);
  const isThereLastChar = (secondChar - 0xac00) % 28;
  return isThereLastChar ? '이' : '가';
};

</script>

<template>
    <div class="cont-pages">
        <div class="tit">
            <span class="main-tit ganji-txt" :class="five.getFiveClass(five.getGanjiColorFive(props.curIlgan))">
                 {{ contents.icon }} {{ contents.body.mainTitle }}
            </span>            
        </div>

        <div class="inner-cont">
            <span class="nor-txt">
                {{ contents.body.subTitle }}
            </span>
            <div v-for="word in contents.body.keywords" class="gray-box">
                <span class="gray-tit">
                    {{ word.title }}
                </span>
                <span class="gray-cont">
                    {{ word.content }}
                </span>
            </div>
            <div class="bottom" style="margin-top: 24px;">
                <span class="btm-txt">
                    하지만, 모든 {{ props.IlganKC }}{{ checkJongsung(props.IlganKC) }} 이러한 특성을 동일하게 가지는 것은 아닙니다. <br/>
                    사주 구성과 환경, 개인의 경험에 따라 {{ props.IlganKC }}의 기운은 다르게 나타날 수 있습니다.
                </span>
            </div>

        </div>

    </div>
</template>