import axios from "axios";
import {useApiClient}  from "@/api/ApiClient"
import { generateHeaders } from "./headers";
const endpoint = import.meta.env.VITE_APP_GW_URL
const systemId = import.meta.env.VITE_APP_SYSTEMID

const { get, get2, put, post, del} = useApiClient();


/** 팝업 내용 가져오기 */
export const getIlganPopup = async (code) =>{
    let path = `/contents2/${code}`;
    const {data} = await axios.get(path);
    return data;
}