<script setup>
import { useGesture } from '@/composables';
import { useDeviceDetect } from '@/composables/useDeviceDetect';
import '@/assets/nangman_style/popup/five_description_mobile.scss'
import { DEV_MODE } from '@/main';

definePage({ meta: { layout: 'empty' } });

/** 디바이스 감지 */
const { isMobile } = useDeviceDetect();

const currentPage = ref(0);
const bottomInset = ref(0);

onMounted(async () => {
  window.addEventListener('message', handleMessage);
  document.addEventListener('message', handleMessage);
});

onUnmounted(() => {
  window.removeEventListener('message', handleMessage);
  document.removeEventListener('message', handleMessage);
});

/** 모바일 앱과 통신 */
const handleMessage = async (event) => {
  try {
    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
    } else {
      data = event.data;
    }

    // React DevTools 메시지 무시
    if (data.source === 'react-devtools-content-script') {
      console.log('Ignoring React DevTools message.');
      return;
    }

    // PC 테스트용
    if (data.key === '__VUE_DEVTOOLS_VUE_DETECTED_EVENT__') {
      fetchIlgan();
      return;
    }

    if (data) {
      if (!isMobile && DEV_MODE) {
        // accessToken.value = store.getters['user/token'];
        // userId.value = store.getters['user/id'];
      } else {
        bottomInset.value = data.bottom;

        if (accessToken.value) {
          axios.defaults.headers.common['Authorization'] = accessToken.value;
        }
      }
    }
  } catch (e) {
    console.error('Invalid message received:', `${e}`);
  }
};

const clickPrevPage = () => {
  if (currentPage.value < 1) return;
  else currentPage.value -= 1;
}

const clickNextPage = () => {
  if (currentPage.value > 4) return;
  else currentPage.value += 1;
}

const onSwipeLeft = () => {
  if (currentPage.value === 0 || currentPage.value > 4) return;
  else currentPage.value += 1;
}

const onSwipeRight = () => {
  if (currentPage.value < 1) return;
  else currentPage.value -= 1;
}

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: onSwipeLeft,  // 왼쪽 스와이프 → 다음 페이지 이동
  onSwipeRight: onSwipeRight, // 오른쪽 스와이프 → 이전 페이지 이동
  SWIPE_THRESHOLD: 150,
});

const clickStart = () => {
  currentPage.value = 1;
}

/** 팝업 닫기 */
const close = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: "closeFive" }));
  }
}
</script>

<template>
  <div
    class="pop-five-star-container mobile"
    @mousedown="handleStart"
    @mousemove="handleMove"
    @mouseup="handleEnd"
    @touchstart="handleStart"
    @touchmove="handleMove"
    @touchend="handleEnd"  
  >
    <div v-if="currentPage === 0" class="pop-five-star-inner intro">
      <div class="pop-header__large">
        <button @click="close"><i class="pop-header__close"></i></button>
        <div class="pop-header__content">
          <div class="pop-header__icon">💫</div>
          <div class="pop-header__text">
            <div class="pop-header__title">사주와 오행도</div>
            <div class="pop-header__subtitle">오행의 흐름을 읽는 법</div>
          </div>
        </div>
      </div>
      <div class="pop-body pd-16">
        <div class="pop-body__inner">
          <div class="pop-body__title"><div class="icon">💫</div>낭만만세력 오행도?</div>
          <div class="pop-body__content">
            <div class="pop-body__desc">
              사람마다 오행의 구성이 다릅니다.<br>
              어떤 사람은 목(木)이 많아 창의력이 넘치지만,<br>
              현실적 기반이 약할 수도 있고,<br>
              또 어떤 사람은 금(金)이 강해 논리적이지만,<br>
              감성이 부족할 수도 있습니다.
            </div>
            <div class="pop-body__desc">
              내 사주의 목화토금수 오행의 흐름을<br>
              한눈에 볼 수 있도록 정리한 것이<br>
              낭만만세력의 오행도(五行圖)입니다.
            </div>
            <div class="pop-body__desc">
              오행도를 통해 사주 오행의 구성을 한눈에<br>
              파악할 수 있도록 돕고자 하는 것이<br>
              오행도 서비스의 목표입니다.
            </div>
            <div class="pop-body__desc">
              이 오행도가 스스로를 더 깊이 이해하고<br>
              삶의 균형을 찾아가는 데 도움되시길 희망합니다.
            </div>
          </div>
        </div>
        <button class="start-button" @click="clickStart">바로 시작하기</button>
        <div class="pop-footer" style="padding-bottom: 40px;">
          "사주명리학은 운명을 결정짓는 것이 아니라,<br>
          자신을 알고 세상을 바라보는 지혜를 길러주는 길입니다.<br>
          내가 가진 천간의 기운 속에서 숨겨진 가능성을 발견하고,<br>
          삶의 흐름 속에서 조화롭게 펼쳐가시길 바랍니다."
        </div>
      </div>
    </div>

    <div v-else="currentPage !== 0" class="pop-five-star-inner gray100">
      <div class="pop-header__small">
        <button @click="close"><i class="pop-header__close"></i></button>
        <div class="pop-header__content">
          <div class="pop-header__icon">💫</div>
          <div class="pop-header__text">
            <div class="pop-header__title">사주와 오행도</div>
            <div class="pop-header__subtitle">오행의 흐름을 읽는 법</div>
          </div>
        </div>
      </div>

      <div v-if="currentPage === 1" class="pop-body mobile">
        <div class="pop-body__inner">
          <div class="pop-body__title"><div class="icon">👤</div>일간(日干) : 사주의 주인공, 나 자신</div>
          <div class="pop-body__content">
            <div class="pop-body__desc">
              사주에서 <b>일간(日干)</b>은 곧 ‘나’입니다.<br>
              내 성향과 기질, 그리고 삶의 중심이 되는 에너지를 나타냅니다.
            </div>
            <div class="pop-body__desc">
              어떤 일간을 타고났느냐에 따라<br>
              나의 기본적인 성격과 삶의 방향이 결정됩니다.<br>
              그러나, 사주는 단순하지 않습니다.<br>
              ‘나’만이 아니라 주변 환경과 관계 속에서 흐름을 형성합니다.
            </div>
          </div>
          <div class="five-element-saju1"></div>
        </div>
      </div>
      <div v-if="currentPage === 2" class="pop-body mobile">
        <div class="pop-body__inner">
          <div class="pop-body__title"><div class="icon">👫</div>일지(日支) : 나의 뿌리와 삶의 기반</div>
          <div class="pop-body__content">
            <div class="pop-body__desc">
              <b>일지(日支)</b>는 일간이 뿌리 내리는 곳으로,<br>
              나의 내면, 생활 기반, 배우자운과도 깊이 연결됩니다.
            </div>
            <div class="pop-body__desc">
              일지는 ‘땅의 기운’을 품고 있으며, 일간의 뿌리 역할을 합니다.<br>
              이를 통해 나의 내면적인 안정감, 현실적인 생활 환경, 배우자와의 관계 등을 알 수 있습니다.
            </div>
          </div>
          <div class="five-element-saju2"></div>
        </div>
      </div>
      <div v-if="currentPage === 3" class="pop-body mobile">
        <div class="pop-body__inner">
          <div class="pop-body__title"><div class="icon">💫</div>오행(五行) : 세상을 이루는 다섯 가지 원리</div>
          <div class="pop-body__content">
            <div class="pop-body__desc">
              사주는 <b>다섯 가지 기운, 오행(五行)</b>으로 이루어집니다.<br>
              이는 단순한 요소가 아니라, 자연의 이치를 담은 흐름입니다.
            </div>
          </div>
          <div class="pop-card__list">
            <div class="pop-card">
              <div class="pop-card__icon">🌳</div>
              <div class="pop-card__text">
                <div class="pop-card__title ganji-txt five03">
                  목(木) : 시작과 성장 → 초록색, 청색
                </div>
                <div class="pop-card__desc">
                  나무처럼 뻗어 나가는 기운. 배움, 도전, 창조적인 사고를 의미합니다.
                </div>
              </div>
            </div>
            <div class="pop-card">
              <div class="pop-card__icon">🔥</div>
              <div class="pop-card__text">
                <div class="pop-card__title ganji-txt five01">
                  화(火) : 변화와 활력 →  빨간색, 주황색
                </div>
                <div class="pop-card__desc">
                  태양처럼 뜨거운 기운. 열정, 표현력, 추진력을 뜻합니다.
                </div>
              </div>
            </div>
            <div class="pop-card">
              <div class="pop-card__icon">⛰️</div>
              <div class="pop-card__text">
                <div class="pop-card__title ganji-txt five05">
                  토(土) : 균형과 연결 →  노란색, 갈색
                </div>
                <div class="pop-card__desc">
                  중심을 잡아주는 기운. 신뢰, 안정, 조화로운 관계를 나타냅니다.
                </div>
              </div>
            </div>
            <div class="pop-card">
              <div class="pop-card__icon">💎</div>
              <div class="pop-card__text">
                <div class="pop-card__title ganji-txt five04">
                  금(金) : 결실과 판단 → 흰색, 금색, 은색
                </div>
                <div class="pop-card__desc">
                  단단하게 굳어지는 기운. 집중력, 논리적 사고, 통찰력을 의미합니다.
                </div>
              </div>
            </div>
            <div class="pop-card">
              <div class="pop-card__icon">💧</div>
              <div class="pop-card__text">
                <div class="pop-card__title ganji-txt five02">
                  수(水) : 흐름과 지혜 →  파란색, 검은색
                </div>
                <div class="pop-card__desc">
                  강처럼 유연한 기운. 깊은 감성, 사고력, 지혜를 뜻합니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentPage === 4" class="pop-body mobile">
        <div class="pop-body__inner">
          <div class="pop-body__title"><div class="icon">🔎</div>오행의 균형 : 나에게 필요한 것은?</div>
          <div class="pop-body__content">
            <div class="pop-body__desc">
              사람마다 오행의 구성이 다릅니다.<br>
              어떤 사람은 목(木)이 많아 창의력이 넘치지만,<br>
              현실적 기반이 약할 수도 있고,<br>
              또 어떤 사람은 금(金)이 강해 논리적이지만,<br>
              감성이 부족할 수도 있습니다.
            </div>
            <div class="pop-body__desc">
              내 사주의 흐름을 한눈에 볼 수 있도록 정리한 것이 <b>오행도(五行圖)</b>입니다.<br>
              이를 통해 어떤 오행이 많고, 어떤 오행이 부족한지 파악할 수 있습니다.
            </div>
          </div>
          <div class="five-star-diagram mt-40"></div>
        </div>
      </div>
      <div v-if="currentPage === 5" class="pop-body mobile">
        <div class="pop-body__inner">
          <div class="pop-body__title"><div class="icon">🔑</div>사주, 흐름을 읽고 조화를 이루는 지혜</div>
          <div class="pop-body__content">
            <div class="pop-body__desc">
              사주는 내가 가진 기운을 어떻게 활용하고,<br>
              부족한 부분을 어떻게 채울 것인가를 깨닫는 지혜입니다.
            </div>
            <div class="pop-body__desc">
              특정 오행이 너무 강하면 조율하고,<br>
              부족한 오행은 보완하면서 자연스럽게 흘러가는<br>
              삶의 균형을 찾아가는 것,<br>
              그것이 사주를 보는 진정한 이유입니다.
            </div>
          </div>
          <div class="pop-card__large">
            <div class="pop-body__content">
              <div class="pop-body__desc">
                우리는 때로<br>
                🌳 목(木)처럼 성장하고,<br>
                🔥 화(火)처럼 뜨겁게 도전하며,<br>
                ⛰️ 토(土)처럼 중심을 잡고,<br>
                💎 금(金)처럼 단단해지며,<br>
                💧 수(水)처럼 부드럽게 흘러가야 합니다.
              </div>
              <div class="pop-body__desc">
                사주는 단순한 운명이 아니라,<br>
                자신을 알고 세상을 바라보는 깊은 통찰과 조화의 길입니다.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pop-pagination">
        <div class="pop-pagination__index">0{{ currentPage }} / 05</div>
        <button
          class="pop-pagination__prev"
          @click="clickPrevPage"
        >
          <i class="icon-chevron"></i>
        </button>
        <button
          class="pop-pagination__next"
          @click="clickNextPage"
        >
          <i class="icon-chevron right"></i>
        </button>
      </div>
    </div>
  </div>
</template>