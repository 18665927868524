<script setup>
import { DEV_MODE } from '@/main'

import { useAnalysisStore } from '@/store/modules/analysis'

import { getDetail } from '@/api/UserGroup'
import { fastSearch } from '@/api/Eoway'

import fiveMixin from '@/composables/fiveMixin'

import { AnalysisHeader } from '@/components/analysis'
import Dashboard from './dashboard.vue'
import Personality from './personality.vue'
import Five from './five.vue'
import FiveGuide from './fiveGuide.vue'

import personalityTab from '@/pages/analysis/components/personalityTab.vue'
import fiveTab from '@/pages/analysis/components/fiveTab.vue'

const { ...five } = fiveMixin()

definePage({ meta: { layout: 'analysis' } })

const route = useRoute()
const router = useRouter()

const analysisStore = useAnalysisStore()

const paramData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
})
const sajuData = ref({})
const inputdata = ref({})
const transdata = ref({})
const birthData = ref({})
const daeunsData = ref({})

const langMode = ref('CHNKOR')

const isPageLoading = ref(true)

const birthEightTxt = ref('')

onMounted(async () => {
  if (!DEV_MODE) router.push('/saju')

  await getSajuParams(route.params.id)
  await getManseForce()
  isPageLoading.value = false
})

const getSajuParams = async (id) => {
  if (!id) return

  try {
    const result = await getDetail(id)

    const dateTime = result.integrationBirth.split('T')
    const [year, month, day] = dateTime[0].split('-')
    const [hour, min] = dateTime[1]?.split(':')

    paramData.value.year = year
    paramData.value.month = month.padStart(2, '0')
    paramData.value.day = day.padStart(2, '0')
    paramData.value.hour = hour.padStart(2, '0')
    paramData.value.min = min.padStart(2, '0')
    paramData.value.gender = result.gender === 'MEN' ? 1 : 0
    paramData.value.userName = result.guestUserName
    paramData.value.isLunar = result.isLuna === null ? false : result.isLuna
    paramData.value.lunar = result.lunar
    paramData.value.isLeapYear = result.isLeapYear
    paramData.value.bornAreaId = result.bornAreaId
    paramData.value.birthTime = result.birthTime
    paramData.value.unknownTime = result.unknownTime
    paramData.value.option1 = result.option1
    paramData.value.option2 = result.option2
    paramData.value.option3 = result.option3
    paramData.value.option4 = result.option4
    paramData.value.option5 = result.option5
    paramData.value.addressTxt = result.bornArea
    // paramData.value.lang = result.lang
    // paramData.value.langYN = result.langYN
    // paramData.value.userGroupId = result.userGroupId
  } catch (e) {
    DEV_MODE && console.error('사주정보 조회 오류:', e)
  }
}

const padNumber = (num) => num.toString().padStart(2, '0')

const convertManseForceParams = () => {
  const { year, month, day, hour, min } = paramData.value

  paramData.value.birth = `${year}-${padNumber(month)}-${padNumber(day)}`
  paramData.value.birthTime = `${padNumber(hour)}:${padNumber(min)}`

  if (paramData.value.birth_time) {
    paramData.value.hour = '--'
    paramData.value.min = '--'
    paramData.value.birthTime = '25:00'
    paramData.value.unknownTime = 'Y'
  }
  if (paramData.value.birthTime === '25:00') paramData.value.unknownTime = 'Y'
  if (!paramData.value.unknownTime) paramData.value.unknownTime = 'N'
  if (!paramData.value.isLeapYear) paramData.value.isLeapYear = false
  if (paramData.value.bornAreaId === null) delete paramData.value.bornAreaId
}

const getManseForce = async () => {
  convertManseForceParams()

  try {
    const result = await fastSearch(paramData.value)

    sajuData.value = result
    inputdata.value = result.inputdata
    transdata.value = result.transdata
    birthData.value = result.birthganji
    daeunsData.value = result.daeuns

    //
    const skyValues = [
      five.getHanjaTxt(birthData.value.sky.year.value, 'sky'),
      five.getHanjaTxt(birthData.value.sky.month.value, 'sky'),
      five.getHanjaTxt(birthData.value.sky.day.value, 'sky'),
      birthData.value.sky.hour?.value !== '-'
        ? five.getHanjaTxt(birthData.value.sky.hour.value, 'sky')
        : '-',
    ]
    const earthValues = [
      five.getHanjaTxt(birthData.value.earth.year.value, 'earth'),
      five.getHanjaTxt(birthData.value.earth.month.value, 'earth'),
      five.getHanjaTxt(birthData.value.earth.day.value, 'earth'),
      birthData.value.earth.hour?.value !== '-'
        ? five.getHanjaTxt(birthData.value.earth.hour.value, 'earth')
        : '-',
    ]
    birthEightTxt.value = skyValues.join('') + earthValues.join('')
    if (birthEightTxt.value === '') {
      const skyValues = [
        birthData.value.sky.year.value,
        birthData.value.sky.month.value,
        birthData.value.sky.day.value,
        birthData.value.sky.hour?.value ?? '-',
      ]
      const earthValues = [
        birthData.value.earth.year.value,
        birthData.value.earth.month.value,
        birthData.value.earth.day.value,
        birthData.value.earth.hour?.value ?? '-',
      ]
      birthEightTxt.value = skyValues.join('') + earthValues.join('')
    }
  } catch (e) {
    DEV_MODE && console.error('만세력 조회 오류:', e)
  }
}

/** 선택된 설명 내비게이션 탭 - 'basic', 'image', 'detail' */
const selectedDescNav = ref('basic')
const selectedDescNavSub = ref('first')

const toggleTabOpenClass = ref('')
const toggleTabContent = () => {
  if (toggleTabOpenClass.value === '') {
    toggleTabOpenClass.value = 'wide'
  } else {
    toggleTabOpenClass.value = ''
  }
}

const updateSelectedDescNav = (val) => {
  selectedDescNav.value = val
}
const updateSelectedDescNavSub = (val) => {
  selectedDescNavSub.value = val
}
</script>

<template>
  <div v-if="DEV_MODE && !isPageLoading" class="analysis-main" :class="toggleTabOpenClass">
    <section class="analysis-header">
      <AnalysisHeader :inputdata="inputdata" :transdata="transdata" />
    </section>

    <Dashboard v-if="analysisStore.selectedMenu.startsWith('/dashboard')" />
    <Personality v-if="analysisStore.selectedMenu.startsWith('/personality')" />

    <Five
      :birthData="birthData"
      :birthEightTxt="birthEightTxt"
      v-if="analysisStore.selectedMenu.startsWith('/five')"
    />

    <FiveGuide
      :birthData="birthData"
      :birthEightTxt="birthEightTxt"
      :selectedDescNavSub="selectedDescNavSub"
      v-if="analysisStore.selectedMenu.startsWith('/five-guide')"
    />
  </div>

  <div
    class="analysis-desc"
    :class="toggleTabOpenClass"
    v-if="analysisStore.selectedMenu.startsWith('/dashboard')"
  >
    <div class="desc-header">
      <div class="desc-nav">
        <button
          :class="['nav-button', { active: selectedDescNav === 'basic' }]"
          @click="selectedDescNav = 'basic'"
        >
          기본해석
        </button>
        <button
          :class="['nav-button', { active: selectedDescNav === 'image' }]"
          @click="selectedDescNav = 'image'"
        >
          이미지
        </button>
        <button
          :class="['nav-button', { active: selectedDescNav === 'detail' }]"
          @click="selectedDescNav = 'detail'"
        >
          상세분석
        </button>
      </div>

      <i class="bot-button"></i>
    </div>

    <div v-if="selectedDescNav === 'basic'" class="desc-container">
      <div v-if="analysisStore.selectedMenu.startsWith('/dashboard')" class="inner">
        <div class="main-card">
          <div class="title">
            초년운
            <div class="rate-box">
              <i class="star"></i>
              <i class="star"></i>
              <i class="star"></i>
              <i class="star"></i>
              <i class="star empty"></i>
            </div>
          </div>

          <div class="main-desc">
            전반적으로 매우 안정적이며 성취와 만족감을 느낄 수 있는 시기입니다.
          </div>

          <div class="main-desc-text">
            이 시기는 평온하고 안정된 환경 속에서 학습과 개인의 성장이 두드러지는 시기입니다.
            학업에서는 집중력이 높아지며, 노력한 만큼의 성과가 나타나 성취감과 자신감을 경험합니다.
            특히 다양한 분야에 대한 탐구심이 활발해져 학습이 즐거운 경험으로 이어집니다.
          </div>
        </div>

        <div :class="['desc-card', 'positive']">
          <div class="title"><i class="rate5"></i>긍정적 요소</div>
          <div class="keyword-box">
            <div class="keyword">학습 성취</div>
            <div class="keyword">가족 화합</div>
            <div class="keyword">건강 유지</div>
          </div>
          <div class="desc-text">
            학습에서 꾸준한 성과를 이루고 가족의 지지를 통해 정서적 안정감을 느낄 수 있습니다. 건강
            관리와 긍적적 인간관계를 통해 활기찬 에너지를 경험하며 사회성을 키울 수 있습니다.
          </div>
          <div class="desc-goal">
            안정된 환경을 바탕으로 작은 목표를 지속적으로 설정하고 새로운 도전을 통해 성장을
            이어가야 합니다.
          </div>
        </div>

        <div :class="['desc-card', 'negative']">
          <div class="title"><i class="rate1"></i>주의 요소</div>
          <div class="keyword-box">
            <div class="keyword">도전 부족</div>
            <div class="keyword">상황 리듬</div>
          </div>
          <div class="desc-text">
            안정감에 만족해 새로운 도전이나 변화의 기회를 놓칠 수 있으며, 건강 관리에 소홀하면 생활
            리듬이 흐트러질 수 있습니다.
          </div>
          <div class="desc-goal">
            가족과 함께 새로운 활동을 시도하고 꾸준한 운동과 취미를 통해 무기력함을 극복해야 합니다.
          </div>
        </div>

        <div :class="['desc-card', 'key-point']">
          <div class="title"><i class="pin"></i>핵심 특성</div>
          <div class="keyword-box">
            <div class="keyword">학습 성취</div>
            <div class="keyword">정서 안정</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-fold-toggle" :class="toggleTabOpenClass">
    <button class="btn" @click="toggleTabContent()"></button>
  </div>

  <div
    class="analysis-desc"
    :class="toggleTabOpenClass"
    v-if="analysisStore.selectedMenu.startsWith('/personality')"
  >
    <personalityTab :selectedDescNav="selectedDescNav" />
  </div>
  <div
    class="analysis-desc st-five"
    :class="toggleTabOpenClass"
    v-if="analysisStore.selectedMenu.startsWith('/five')"
  >
    <fiveTab
      :selectedDescNav="selectedDescNav"
      :selectedDescNavSub="selectedDescNavSub"
      @updateSelectedDescNav="updateSelectedDescNav"
      @updateSelectedDescNavSub="updateSelectedDescNavSub"
    />
  </div>
</template>
