<script setup>
import { useAnalysisStore } from '@/store/modules/analysis'

import FiveStartComponent from '@/pages/manse/components/FiveStartComponent.vue'
import FiveStartGuideComponent from '@/pages/manse/components/FiveStartGuideComponent.vue'

import IconArrow05 from '@/components/svg/IconArrow05.vue'
import IconStop from '@/components/svg/IconStop.vue'
import IconPause from '@/components/svg/IconPause.vue'
import IconStart from '@/components/svg/IconStart.vue'

import { defineProps } from 'vue'
const props = defineProps({
  birthData: {
    type: Object,
  },
  birthEightTxt: {
    type: String,
  },
  selectedDescNavSub: {
    type: String,
  },
})

const store = useAnalysisStore()

const indexNo = ref(1)

const rootPage = ref('analysis')

const pageMode = ref('ani')

const classes = ['five1', 'five2', 'five3', 'five4', 'five5', 'finish']
const currentClass = ref(classes[0]) // 초기 클래스 설정
let index = 0
let intervalId = null // 애니메이션을 위한 interval ID

const changeClass = () => {
  // 현재 클래스 배열에 새 클래스를 추가
  //currentClass.value.push(classes[index])
  currentClass.value = classes[index]
  index++

  // 모든 단계가 끝나면 애니메이션을 멈춤
  if (index >= classes.length) {
    stopAnimation()
  }
}

const startAnimation = () => {
  if (intervalId) return // 이미 애니메이션이 실행 중이면 무시
  index = 0 // 인덱스 초기화
  currentClass.value = [] // 이전 클래스 초기화
  changeClass() // 처음 클래스 변경
  intervalId = setInterval(changeClass, 1000) // 0.5초 간격으로 클래스 변경
}

const stopAnimation = () => {
  clearInterval(intervalId) // 애니메이션 중지
  intervalId = null // interval ID 초기화
  index = 0 // 인덱스 초기화
  //currentClass.value = [] // 클래스 배열 초기화
}

onMounted(() => {
  startAnimation() // 컴포넌트가 마운트될 때 클래스 변경 시작
})
</script>

<template>
  <div class="ani-btn-section" v-if="selectedDescNavSub === 'first'">
    <button class="btn white" @click="stopAnimation"><IconPause />정지</button>
    <button class="btn white" @click="stopAnimation"><IconStop />정지</button>
    <button class="btn orange" @click="startAnimation"><IconStart />시작</button>
  </div>

  <div
    v-if="selectedDescNavSub === 'first'"
    class="chart-section full-height full-center five-round-bg"
    :class="currentClass"
  >
    <div class="step-bar">
      <div
        class="sp-line first"
        :class="{
          active:
            currentClass === 'five1' ||
            currentClass === 'five2' ||
            currentClass === 'five3' ||
            currentClass === 'five4' ||
            currentClass === 'five5' ||
            currentClass === 'finish',
        }"
      >
        <div
          class="icon-arrow"
          :class="{
            active: currentClass === 'five1' || currentClass === 'finish',
          }"
        >
          <IconArrow05 />
          <span class="txt">목</span>
        </div>
      </div>
      <div
        class="sp-line"
        :class="{
          active:
            currentClass === 'five2' ||
            currentClass === 'five3' ||
            currentClass === 'five4' ||
            currentClass === 'five5' ||
            currentClass === 'finish',
        }"
      >
        <div
          class="icon-arrow"
          :class="{
            active: currentClass === 'five2' || currentClass === 'finish',
          }"
        >
          <IconArrow05 /><span class="txt">화</span>
        </div>
      </div>
      <div
        class="sp-line"
        :class="{
          active:
            currentClass === 'five3' ||
            currentClass === 'five4' ||
            currentClass === 'five5' ||
            currentClass === 'finish',
        }"
      >
        <div
          class="icon-arrow"
          :class="{
            active: currentClass === 'five3' || currentClass === 'finish',
          }"
        >
          <IconArrow05 /><span class="txt">토</span>
        </div>
      </div>
      <div
        class="sp-line"
        :class="{
          active: currentClass === 'five4' || currentClass === 'five5' || currentClass === 'finish',
        }"
      >
        <div
          class="icon-arrow"
          :class="{
            active: currentClass === 'five4' || currentClass === 'finish',
          }"
        >
          <IconArrow05 /><span class="txt">금</span>
        </div>
      </div>
      <div
        class="sp-line last"
        :class="{
          active: currentClass === 'five5' || currentClass === 'finish',
        }"
      >
        <div
          class="icon-arrow"
          :class="{
            active: currentClass === 'five5' || currentClass === 'finish',
          }"
        >
          <IconArrow05 /><span class="txt">수</span>
        </div>
      </div>
    </div>

    <FiveStartGuideComponent
      ref="starRef"
      :birthData="birthData"
      :birthEightTxt="birthEightTxt"
      :rootPage="rootPage"
      :rootFiveClass="currentClass"
      @skyDay-click="handleSkyDayClick"
      @skyDay-reset="resetSkyDay"
      @open-ilgan="openIlgan"
      @close-ilgan="closePopIlgan()"
      @openPopFiveStar="openPopFiveStar"
      @closePopFiveStar="closePopFiveStar"
      @clickIlganButton="clickIlganButton"
    />
  </div>
  <div
    v-if="selectedDescNavSub === 'second'"
    class="chart-section full-height full-center five-round-bg finish five-tab-second"
    :class="{
      five1: birthData.five.five1.num > 0,
      five2: birthData.five.five2.num > 0,
      five3: birthData.five.five3.num > 0,
      five4: birthData.five.five4.num > 0,
      five5: birthData.five.five5.num > 0,
    }"
  >
    <FiveStartComponent
      ref="starRef"
      :birthData="birthData"
      :birthEightTxt="birthEightTxt"
      :rootPage="rootPage"
      @skyDay-click="handleSkyDayClick"
      @skyDay-reset="resetSkyDay"
      @open-ilgan="openIlgan"
      @close-ilgan="closePopIlgan()"
      @openPopFiveStar="openPopFiveStar"
      @closePopFiveStar="closePopFiveStar"
      @clickIlganButton="clickIlganButton"
    />
  </div>
  <div
    v-if="
      selectedDescNavSub === 'third' ||
      selectedDescNavSub === 'fourth' ||
      selectedDescNavSub === 'fifth'
    "
    class="chart-section full-height full-center five-round-bg five1 five2 five3 five4 five5 finish"
    :class="[
      selectedDescNavSub === 'fourth' ? 'five-tab-fourth' : '',
      selectedDescNavSub === 'third' ? 'five-tab-third' : '',
      selectedDescNavSub === 'fifth' ? 'five-tab-fifth' : '',
    ]"
  >
    <FiveStartGuideComponent
      ref="starRef"
      :birthData="birthData"
      :birthEightTxt="birthEightTxt"
      :rootPage="rootPage"
      :rootFiveClass="currentClass"
      @skyDay-click="handleSkyDayClick"
      @skyDay-reset="resetSkyDay"
      @open-ilgan="openIlgan"
      @close-ilgan="closePopIlgan()"
      @openPopFiveStar="openPopFiveStar"
      @closePopFiveStar="closePopFiveStar"
      @clickIlganButton="clickIlganButton"
    />
  </div>
</template>
<style>
@import '/src/assets/nangman_style/personality.scss';
</style>
