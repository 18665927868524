<script setup>
import '@/assets/nangman_style/popup/PopFiveStar.scss';

const emit = defineEmits(['close']);

const popFiveStarRef = ref(true);

const currentPage = ref(0);

const close = () => emit('close');

const clickStart = () => {
  currentPage.value = 1;
}

const clickPrevPage = () => {
  if (currentPage.value < 1) return;
  else currentPage.value -= 1;
}

const clickNextPage = () => {
  if (currentPage.value > 4) return;
  else currentPage.value += 1;
}

const onSwipeLeft = () => {
  if (currentPage.value === 0 || currentPage.value > 4) return;
  else currentPage.value += 1;
}

const onSwipeRight = () => {
  if (currentPage.value < 1) return;
  else currentPage.value -= 1;
}

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: onSwipeLeft,  // 왼쪽 스와이프 → 다음 페이지 이동
  onSwipeRight: onSwipeRight, // 오른쪽 스와이프 → 이전 페이지 이동
  SWIPE_THRESHOLD: 150,
});

watch(popFiveStarRef, (newState) => {
  if (!newState) close();
});
</script>

<template>
  <VDialog
    v-model="popFiveStarRef"
    width="740px"
    height="734px"
    max-width="100%"
    class="no-drag"
  >
    <div
      class="pop-five-star-container"
      @mousedown="handleStart"
      @mousemove="handleMove"
      @mouseup="handleEnd"
      @touchstart="handleStart"
      @touchmove="handleMove"
      @touchend="handleEnd"  
    >
      <VCard v-if="currentPage === 0" class="br16 pop-five-star-inner option">
        <div class="pop-header__large">
          <button @click="close"><i class="pop-header__close"></i></button>
          <div class="pop-header__content">
            <div class="pop-header__icon">📡</div>
            <div class="pop-header__text">
              <div class="pop-header__title">표준시/한국시</div>
              <div class="pop-header__subtitle">어떻게 활용해야 할까요?</div>
            </div>
          </div>
        </div>
        <div class="pop-body option-intro">
          <div class="pop-body__inner option-intro">
            <div class="pop-body__title"><div class="icon option">👀</div> 표준시/한국시란?</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                대한민국의 표준시는 동경 135도를 기준으로 설정되어 있지만,<br/>
                실제 국토의 중심인 청주(동경 127도 30분) 기준으로 보면 태양 남중 시각이 약 30분 차이가 납니다.
              </div>
              <div class="pop-body__desc">
                이에 따라 사주 해석 시, 표준시 대신 태양시(한국시)를 적용해야 한다는 분석이 있으며, <br/>
                이를 반영하면 오시(午時)는 기존 11시 ~ 13시에서 조정하여 11시 30분 ~ 13시 30분으로 조정되어야 한다는 이론입니다.
              </div>
              <div class="pop-body__desc">
                편의상 정오를 기준으로 표준시(동경시)와 태양시(한국시)의 차이를 안내 드리고자 합니다.
              </div>
              <div class="pop-body__desc">
                오시(午時)는 통상 태양 남중을 기준으로 앞뒤 1시간씩을 포함한 시간으로 11시 ~ 13시를 뜻하며,<br/>
                태양 남중(南中)은 하루 중 태양이 가장 높이 뜨는 순간 (= 정오)을 의미합니다.
              </div>
            </div>
            
          </div>
          <button class="start-button" @click="clickStart">바로 시작하기</button>
          <div class="pop-footer">
            "사주명리학은 운명을 결정짓는 것이 아니라, 자신을 알고 세상을 바라보는 지혜를 길러주는 길입니다.<br>
            내가 가진 천간의 기운 속에서 숨겨진 가능성을 발견하고, 삶의 흐름 속에서 조화롭게 펼쳐가시길 바랍니다."
          </div>
        </div>
      </VCard>

      <VCard v-else="currentPage !== 0" class="br16 pop-five-star-inner option">
        <div class="pop-header">
          <button @click="close"><i class="pop-header__close"></i></button>
          <div class="pop-header__content">
            <div class="pop-header__icon">📡</div>
            <div class="pop-header__text">
              <div class="pop-header__title">표준시/한국시</div>
              <div class="pop-header__subtitle">어떻게 활용해야 할까요?</div>
            </div>
          </div>
        </div>

        <div v-if="currentPage === 1" class="pop-body">
          <div class="pop-body__inner">
            <div class="pop-body__title"><div class="icon">📍</div>대한민국의 표준시와 청주 기점</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                대한민국의 표준시는 동경 135도를 기준으로 설정되어 사용하고 있습니다.<br/>
                하지만 우리 나라의 중심 경도(중앙경도)를 정확히 구분하면 청주기준 동경 127도 30분이 됩니다.
              </div>

              <div class="pop-body__desc">
                <div class="pop-body__subtitle primary">
                  청주가 기준이 되는 이유?
                </div>
                청주는 국토의 중앙에 위치해 전국 평균적인 태양 남중 시간을 대표할 수 있기 때문입니다.<br/>
                때문에 한국의 중앙 기점인 청주 기준으로 태양시를 적용하면 오시(午時)는 11시 30분부터 시작되고 <br/>
                <span class="underlined-txt">정남중하는 시각인 정오는 12시 30분</span>이 된다는 분석입니다.
              </div>
              <div class="pop-card__list option">
              <div class="pop-card">
                <div class="pop-card__icon">🌏</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    표준시를 사용하는 경우
                  </div>
                  <div class="pop-card__desc">
                    11시 ~ 13시 안에 태어난 분들은 모두 오시(午時)로 구분할 수 있습니다.
                  </div>
                </div>
              </div>
              <div class="pop-card">
                <div class="pop-card__icon">🏙️</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    태양시(한국시)를 사용하는 경우
                  </div>
                  <div class="pop-card__desc">
                    11시 30분 ~ 13시 30분까지를 오시(午時)로 구분하게 됩니다.
                  </div>
                </div>
              </div>
            </div>
            <div class="pop-body__desc">
              아주 정밀한 사주 해석을 하고자 하는 경우 한국의 평균 경도보다 동쪽에 위치한 지역이나<br/>
              서쪽에 위치한 지역의 남중의 시간을 정확하게 적용해서 시주를 면밀하게 분석하기도 합니다.
              </div>
            <div class="pop-body__footnote">
              * 지역별 정남중 시간 : 청주 12:30, 서울 12:32, 부산 12:25, 제주 12:34 등
            </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage === 2" class="pop-body">
          <div class="pop-body__inner">
            <div class="pop-body__title"><div class="icon">🔎</div>지역을 적용한 사례</div>
            <div class="pop-body__content">
              <div class="pop-body__subtitle">
                <div class="icon">✅</div>부산, 11시 27분 출생한 분의 경우
              </div>
              <div class="pop-card__list option">
              <div class="pop-card">
                <div class="pop-card__icon">🌏</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    표준시(동경시)를 적용하는 경우
                  </div>
                  <div class="pop-card__desc">
                    11시부터가 오시(午時)이므로 변화가 없습니다.
                  </div>
                </div>
              </div>
              <div class="pop-card">
                <div class="pop-card__icon">🏙️</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    태양시(한국시)를 사용하는 경우
                  </div>
                  <div class="pop-card__desc">
                    11시 30분부터 오시(午時) 구간이지만 엄밀하게 지역시를 넣어 정확한 분석을 적용할 경우 <br/>
                    부산은 12시 25분부터 이미 오시(午時) 범주에 들었기 때문에 30분을 넘지 않았어도 <br/>
                    <span class="colored-txt primary">오시(午時)</span>로 적용할 수 있다는 것을 의미합니다.
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage === 3" class="pop-body">
          <div class="pop-body__inner">
            <div class="pop-body__title"><div class="icon">🔎</div>지역을 넣지 않고 표준시, 한국시를 적용한 사례</div>
            <div class="pop-body__content">
              <div class="pop-body__subtitle">
                <div class="icon">✅</div>11시 10분 출생한 분의 경우
              </div>
              <div class="pop-card__list option">
              <div class="pop-card">
                <div class="pop-card__icon">🌏</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    표준시(동경시)를 적용하는 경우
                  </div>
                  <div class="pop-card__desc">
                    11시부터가 오시(午時)이므로 변화가 없습니다.
                  </div>
                </div>
              </div>
              <div class="pop-card">
                <div class="pop-card__icon">🏙️</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    태양시(한국시)를 사용하는 경우
                  </div>
                  <div class="pop-card__desc">
                    11시 30분부터가 오시(午時) 구간이므로 표준시와 달리 <span class="colored-txt primary">사시(巳時)</span>로 적용됩니다.
                  </div>
                </div>
              </div>
            </div>
            <div class="pop-body__subtitle" style="margin-top: 4px;">
                <div class="icon">✅</div>11시 31분 출생한 분의 경우
              </div>
              <div class="pop-card__list option">
              <div class="pop-card">
                <div class="pop-card__icon">🌏</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    표준시(동경시)를 적용하는 경우
                  </div>
                  <div class="pop-card__desc">
                    11시부터가 오시(午時)이므로 변화가 없습니다.
                  </div>
                </div>
              </div>
              <div class="pop-card">
                <div class="pop-card__icon">🏙️</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    태양시(한국시)를 사용하는 경우
                  </div>
                  <div class="pop-card__desc">
                    11시 30분부터가 오시(午時) 구간이므로 표준시와 동일하게 <span class="colored-txt primary">오시(午時)</span>로 적용됩니다.
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage === 4" class="pop-body">
          <div class="pop-body__inner">
            <div class="pop-body__title"><div class="icon">📑</div>표준시(동경시) 적용과 한국시(태양시) 적용의 명리학적 차이</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                사주 명리학에서는 출생 시간을 정확하게 적용하는 것이 중요합니다.
              </div>
              <div class="pop-card__list option">
                <div class="pop-card">
                  <div class="pop-card__text">
                    <div class="pop-card__desc">
                      출생시간이 뚜렷하고, 태어난 시가 경계선에 걸쳐 있지 않은 경우에는 영향이 없습니다.
                    </div>
                  </div>
                </div>
                <div class="pop-card">
                  <div class="pop-card__text">
                    <div class="pop-card__desc">
                      출생시간이 변화하는 경계선 (예, A시에서 B시로 넘어가는 구간)에 있는 경우에는 <br/>
                      <span class="colored-txt primary">표준시와 태양시(한국시) 선택에 따라 변화</span>가 있을 수 있습니다.
                    </div>
                  </div>
                </div>
              </div>
              <div class="pop-body__desc">
                아울러 보다 정밀한 사주 해석을 원하시는 경우에는 태어난 지역까지 계산하기도 합니다.
              </div>
              <div class="pop-body__desc">
                태어난 지역을 입력하시면, 해당 지역의 태양 시간을 반영하여 보다 정밀한 계산이 이루어집니다.<br/>
                정확한 사주 간명을 위한 활용에 도움 되시길 바랍니다.
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage === 5" class="pop-body">
          <div class="pop-body__inner">
              <div class="KST-option-img"></div>
          </div>
        </div>

        <div class="pop-pagination">
          <div class="pop-pagination__index">0{{ currentPage }} / 05</div>
          <button
            class="pop-pagination__prev"
            @click="clickPrevPage"
          >
            <i class="icon-chevron"></i>
          </button>
          <button
            class="pop-pagination__next"
            :class="{'inactive': currentPage === 5}"
            @click="clickNextPage"
          >
            <i 
              class="icon-chevron right"
              :class="{'inactive': currentPage === 5}"></i>
            <span v-if="currentPage === 5" class="tooltip">마지막 페이지입니다</span>
          </button>
        </div>
      </VCard>
    </div>
  </VDialog>
</template>