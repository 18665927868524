<script setup></script>

<template>
  <VRow class="full-height">
    <VCol cols="12" md="6" class="full-height">
      <VCard class="person-card full-height">
        <section class="top-title">
          <span class="st-number">8</span>
          <span>관계방식</span>
        </section>
        <section class="btm-body">
          <dl class="txt02">
            <dt>특징요약</dt>
            <dd>나의 사회 관계에서 내면적 특징은 재정적 손실, 대비책, 성장 입니다.</dd>
          </dl>
          <dl class="txt02">
            <dt>기본특징</dt>
            <dd>
              내면의 감정 변화를 잘 인식하고 그것을 조절하는 능력이 있습니다. 감정적으로 동요할
              때에도 상황을 분석하고 차분하게 대처하는 편입니다. 다만, 때로는 감정을 억제하려다 내적
              스트레스를 느낄 수 있으니, 필요할 때는 감정을 자연스럽게 표출하는 것도 도움이 됩니다.
              자기 감정에 솔직해지면 더 큰 내적 성숙을 이룰 수 있습니다.  따라서, 단순함과 호기심을
              통해 긍정적인 성장을 이루고 불안감을 극복하는 방향으로 나아가야 합니다. 삶에서
              지향하는 욕구는 강한 열정을 바탕으로 목표를 설정하고 이를 빠르게 달성하며, 성과에 대한
              외부의 인정을 통해 더 높은 목표를 지향합니다.
            </dd>
          </dl>
          <dl class="txt02">
            <dt>상세특징</dt>
            <dd>
              또한, 의사소통 방식은 권위와 규범을 중시하며 조직 내에서의 안정적인 위치를 유지하는
              경향이 있습니다. 책임감과 성실함을 바탕으로 신뢰받는 사람이 되며, 권위자의 지지를 받아
              조직 내에서 성장하거나 중요한 역할을 맡는 경우가 많습니다.
            </dd>
          </dl>
          <div class="com-btm-line-01"></div>
          <dl class="txt02">
            <dt>주요 강점</dt>
            <dd class="keyword st-blue"><span>유연성</span><span>배려심</span></dd>
          </dl>
          <dl class="txt02">
            <dt>보완이 필요한 부분</dt>
            <dd class="keyword st-red"><span>결정지연</span><span>자기 표현 부족</span></dd>
          </dl>
        </section>
      </VCard>
    </VCol>
    <VCol cols="12" md="6">
      <VCard class="person-card full-height">
        <section class="top-title">
          <span class="st-number">9</span>
          <span>발전방향</span>
        </section>
        <section class="btm-body">
          <dl class="txt02">
            <dt>특징요약</dt>
            <dd>당신은  세밀함, 성실함, 실현 역할이 어울립니다.</dd>
          </dl>
          <dl class="txt02">
            <dt>기본특징</dt>
            <dd>
              세부적인 부분까지 꼼꼼하게 관리함으로써 큰 성과를 이끌어내는 것이 중요합니다. 작은
              실수가 큰 문제로 이어질 수 있기 때문에 모든 상황에서 세심하게 계획을 세우고, 작은
              부분도 소홀히 하지 않아야 합니다. 이를 통해 전체적인 성과를 극대화할 수 있습니다.
              또한, 구체적으로 실천해야 할 활동은 다음과 같습니다.
            </dd>
          </dl>
          <dl class="txt02">
            <dt>상세특징</dt>
            <dd>
              사회적 책임을 다하며, 타인에게 신뢰를 주는 행동을 지속해야 합니다. 규칙과 질서를
              따르며, 자신이 맡은 역할에 대한 책임을 다함으로써 신뢰받는 리더로 자리매김할 수
              있습니다. 타인과의 관계에서 신뢰를 기반으로 협력하며 더 큰 사회적 기여를 이룰 수 있는
              성장을 지향해야 합니다.
            </dd>
          </dl>
          <div class="com-btm-line-01"></div>
          <dl class="txt02">
            <dt>주요 강점</dt>
            <dd class="keyword st-blue"><span>유연성</span><span>배려심</span></dd>
          </dl>
          <dl class="txt02">
            <dt>보완이 필요한 부분</dt>
            <dd class="keyword st-red"><span>결정지연</span><span>자기 표현 부족</span></dd>
          </dl>
        </section>
      </VCard>
    </VCol>
  </VRow>
</template>
