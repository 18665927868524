<script setup>
const props = defineProps({
  contents: Object,
  curIlgan: String,
})

const { ...five } = fiveMixin()

</script>

<template>
    <div class="cont-pages">
        <div class="tit">
        <span class="main-tit ganji-txt" :class="five.getFiveClass(five.getGanjiColorFive(props.curIlgan))">
             {{ contents.icon }} {{ contents.title }}
        </span>            
        </div>
        <div class="inner-cont">
            <div v-for="word in contents.body.keywords" class="gray-box">
                <span class="gray-tit">
                    {{ word.title }}
                </span>
                <span class="gray-cont">
                    {{ word.content }}
                </span>
            </div>
        </div>
        <span class="nor-txt">
            
        </span>
    </div>
</template>