<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getEssay, patchEssayLike } from '@/api/EowayApi';
import { IconLike } from '@/components/svg'
import { branchShareDataMap, convertPathToBranchPage, createBranchLink } from '@/composables/useBranch';
import { DEV_MODE } from '@/main';

const props = defineProps({
  essayId: {
    type: String,
    default: ''
  },
});

const store = useStore()
const router = useRouter()

const {
  VITE_KAKAO_CHANNEL_BASE_URL,
  VITE_KAKAO_CHANNEL_PUBLIC_ID,
  VITE_APP_AWS_S3_ENDPOINT,
  VITE_SHARE_THUMBNAIL_PATH,
  VITE_WEB_BASE_URL
} = import.meta.env

const ADD_KAKAO_CHANNEL_URL = `${VITE_KAKAO_CHANNEL_BASE_URL}/${VITE_KAKAO_CHANNEL_PUBLIC_ID}`

const userId = store.getters['user/id']

const essayData = ref(null)
const likeData = ref({likeCnt: null, likeClicked: false})
const isBranchInitialized = ref(false)

onMounted(async() => {
  /** 링크 공유를 통해 게시물로 오려면 페이지 마운트될 때 게시물을 호출해야 함 */
  await _getEssay()

  /** Branch SDK 로드 */
  const script = document.createElement('script');
  script.src = 'https://cdn.branch.io/branch-latest.min.js';
  script.async = true;
  document.head.appendChild(script);
  /** Branch - 스크립트 로드 완료 시 필요한 설정 */
  const options = { open_app: true };
  script.onload = () => {
    branch.init(import.meta.env.VITE_BRANCH_KEY, options, (err, data) => {
      if (err) {
        DEV_MODE && console.error('Branch SDK 초기화 중 오류:', err);
      } else {
        DEV_MODE && console.log('Branch SDK 초기화 성공:', data);
        isBranchInitialized.value = true;
      }
    });
  };
})

onUnmounted(() => {
  /** Branch - 언마운트 시 SDK 스크립트 제거 (선택 사항) */
  const script = document.querySelector('script[src="https://cdn.branch.io/branch-latest.min.js"]');
  if (script) {
    document.head.removeChild(script);
  }
});

/** 에세이 상세 요청 */
const _getEssay = async () => {
  try {
    const result = await getEssay({essayId: props.essayId})
    if (!result) router.go(-1)
    essayData.value = result
    likeData.value.likeCnt = result.likes
    likeData.value.likeClicked = result.likesChecked
  } catch (error) {
    router.go(-1)
  }
}

/** 목록으로 이동 */
const goViewList = () => router.push(`/customer/essay`)

/** 카카오 채널 추가하기 */
const addKakaoChannel = () => {
  window.open(ADD_KAKAO_CHANNEL_URL, '_blank');
}

/** 좋아요 버튼 클릭 */
const patchLike = async () => {
  try {
    const result = await patchEssayLike(props.essayId)
    likeData.value = result
  } catch (error) {}
}

/** 공유하기 버튼 클릭 */
const onPressShare = (item) => {
  if (!item) return null
  else shareWithKakao(item)
}

/** 카카오 공유하기 */
const shareWithKakao = async (item) => {
  // 앱에 전달할 페이지
  const page = convertPathToBranchPage('/customer/essay');

  // 카톡 공유하기 메시지
  let message =
    page && branchShareDataMap[page]
      ? branchShareDataMap[page].messagePreset
      : branchShareDataMap['default'].messagePreset;

  // 카톡 공유하기 이미지 - 에세이는 text 타입으로 공유하므로 미사용

  let mobileWebUrl = ``;
  // Branch SDK가 초기화되었는지 확인  
  if (!isBranchInitialized.value) {
    DEV_MODE && console.error('Branch SDK가 초기화되지 않았습니다.');
  } else {
    try {
      const branchUrl = await createBranchLink({
        page,
        contentId: item.essayId,
        contentTitle: item.firstTitle,
        login: true,
      })
      if (branchUrl) {
        message += branchUrl;
        mobileWebUrl = branchUrl;
      }
    } catch (e) {
      DEV_MODE && console.log('branch link 생성에 실패하였습니다. :', e)
    }
  }

  const content = modifyEssayContent(message.length, item)
  if (content) message = `${content}`;

  const baseUrl = DEV_MODE ? 'https://d25web.thegam.io' : VITE_WEB_BASE_URL;
  const webUrl = `${baseUrl}/essay/${props.essayId}`;
  if (!mobileWebUrl || mobileWebUrl.length < 1) mobileWebUrl = webUrl
  
  if (typeof Kakao !== 'undefined') {
    if (!Kakao.isInitialized()) {
      await Kakao.init(import.meta.env.VITE_KAKAO_APP_KEY);
    }
  } else {
    DEV_MODE && console.error('Kakao SDK가 로드되지 않았습니다.');
    return;
  }

  Kakao.Share.sendDefault({
    objectType: 'text',
    text: message,
    link: {
      mobileWebUrl,
      webUrl,
      androidExecutionParams: `page=${page}&articleId=${item.essayId}`,
      iosExecutionParams: `page=${page}&articleId=${item.essayId}`,
    },
  });
}
</script>

<template>
  <div v-if="essayData" class="essay-page">
    <div class="essay-title-box">
      <img
        v-if="essayData.attachFiles && essayData.attachFiles.length"
        :src="essayData.attachFiles[0].publishedUri"
        alt="attached file"
      >
      <div class="txt-box">
        <div class="tit">
          {{ essayData.mainTitle }}
        </div>
        <div class="date">
          {{ formatCustomDate(essayData.displayDate) }}
        </div>
      </div>
    </div>

    <div class="hd-inner-base pc between essay-content-box">
      <div class="essay-view-content" v-html="essayData.contents"/>
    </div>

    <section class="btn-com gap-xl ceneter" >
      <button
        class="btn-line w-large bg-w"
        @click="goViewList"
      >
        목록으로
      </button>
    </section>

    <section class="share-btm-box">
      <div class="hd-inner-base pc between">
        <div class="tit-box"  @click="addKakaoChannel">
          <i class="icon-essay" />
          <span>카카오 채널 '낭만만세력' 친구 추가하면 매일매일 에세이를 받을 수 있어요! </span>
        </div>
        <div class="like-box">
          <button class="icon-box" @click="patchLike()">
            <IconLike
              class="icon-like"
              :class="{'active': likeData.likeClicked}"
            />
          </button>
          <span class="num">{{ likeData.likeCnt }}</span>
          <button
            class="icon-share none-txt w24"
            @click="onPressShare(essayData)"
          >
            공유하기
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.essay-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  .essay-content-box {
    display: flex;
    flex: 1;
  }
}

.essay-view-content {
  padding: 40px;
  color: #575757;
  font-family: 'Nanum Myeongjo';
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}
</style>