<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { DEV_MODE } from '@/main'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  birthData: {
    type: Object,
    default: null,
  },
  birthEightTxt: {
    type: String,
    default: '',
  },
  isPrint: {
    type: Boolean,
    default: false,
  },
  rootPage: {
    type: String,
    default: '',
  },
  rootFiveClass: {
    type: String,
    default: '',
  },
})
onMounted(() => {})

const isMatch = (char) => {
  return props.birthEightTxt.includes(char)
}

const getChars = (char) => {
  const matchedChars = props.birthEightTxt?.split('').filter((c) => c === char)
  return matchedChars.length > 0 ? matchedChars : [char]
}

/** 받은 데이터에서 일간 일지 문자 구하기 */
const getDayData = (text) => {
  if (!text) return { sky: { char: '', index: 2 }, earth: { char: '', index: 6 } }
  const data = text.split('')
  return {
    sky: {
      char: data[2],
      index: 2,
    },
    earth: {
      char: data[6],
      index: 6,
    },
  }
}

const skyDayData = ref('')
const earthDayData = ref('')
watch(
  () => props.birthEightTxt,
  (newVal) => {
    const dayData = getDayData(newVal)
    skyDayData.value = dayData.sky
    earthDayData.value = dayData.earth
    DEV_MODE && console.log('일간:', skyDayData.value, '/ 일지:', earthDayData.value)
  },
  { immediate: true },
)

// birthEightTxt에 대한 index
const globalIndex = (localIndex, char) => {
  const data = props.birthEightTxt?.split('')
  let count = 0
  for (let i = 0; i < data.length; i++) {
    if (data[i] === char) {
      if (count === localIndex) {
        return i // 로컬 인덱스에 대응하는 전역 인덱스 반환
      }
      count++
    }
  }
  return -1
}

// skyDay 추상화
const isSkyDay = (char, index, charType, isReversed = false) => {
  const chars = getProcessedChars(charType, isReversed)
  const processedIndex = isReversed ? originalIndex(index, chars.length) : index

  return char === skyDayData.char && processedIndex === skyDayData.index
}

// 역순이 필요한 자리에 넣을 함수
const getProcessedChars = (charType, isReversed = false) => {
  const chars = getChars(charType).map((char, index) => ({ char, index })) // 데이터와 인덱스 묶기
  if (isReversed) {
    return chars.reverse() // 화면 순서 반전
  }
  return chars // 원래 순서
}

// 부모로 이벤트 emit
const emit = defineEmits([
  'skyDay-click',
  'open-ilgan',
  'skyDay-reset',
  'resetSelectedState',
  'openPopFiveStar',
  'closePopFiveStar',
  'clickIlganButton',
]) // 부모 컴포넌트에서 'skyDay-click'을 받아야 함

const CJClickedIdx = ref(null)
const selectedChar = ref(null)
const selectedIndex = ref(null)
const updateClickIdx = (char, index) => {
  selectedChar.value = char
  CJClickedIdx.value = index
}
/** 천간, 지지 클릭시 */
const handleClick = (day, char, index) => {
  if (props.isPrint) {
    return
  }
  // five pro top component에 표시 해제
  if (CJClickedIdx.value === index && selectedChar.value === char) {
    CJClickedIdx.value = null
    selectedChar.value = null
    emit('skyDay-reset')
    return
  }
  updateClickIdx(char, index) // 뒷 배경 표시
  // five pro top component에 표시
  const globalIdx = globalIndex(index, char)
  const isSkyDayClicked = char === skyDayData.value.char && globalIdx === skyDayData.value.index
  const whichDay = day
  emit('skyDay-click', {
    whichDay,
    isSkyDayClicked,
    globalIdx,
  })
  DEV_MODE && console.log('star컴포넌트에서 클릭된 데이터', char, whichDay, globalIdx)
  if (isSkyDayClicked) {
    // 일간 클릭했을 경우 팝업 띄우기
    emit('open-ilgan', { char, whichDay, globalIdx })
  } else {
    emit('close-ilgan')
  }
}

// 격국
const isGyeokClicked = ref(false)
const openGyeokguk = () => {
  isGyeokClicked.value = true
}
const closeGyeokguk = () => {
  isGyeokClicked.value = false
}

// 용신
const isYongClicked = ref(false)
const openYongsin = () => {
  isYongClicked.value = true
}
const closeYongsin = () => {
  isYongClicked.value = false
}

const resetSelectedState = () => {
  CJClickedIdx.value = null
  selectedChar.value = null
  // emit('skyDay-reset');
}

const clickPopFiveStarButton = () => {
  emit('openPopFiveStar')
}

const clickIlganButton = () => emit('clickIlganButton')

defineExpose({
  resetSelectedState,
})
</script>
<template>
  <div class="pro-star-box">
    <div class="div-back five-back01"></div>
    <!-- tue -->
    <div class="div-back five-back02"></div>
    <!-- wed -->
    <div class="div-back five-back03"></div>
    <!-- thu -->
    <div class="div-back five-back04"></div>
    <!-- fri -->
    <div class="div-back five-back05"></div>
    <!-- sat -->
    <div class="div-txt st-fri">金</div>
    <div class="div-txt st-sat">土</div>
    <div class="div-txt st-tue">火</div>
    <div class="div-txt st-wed">水</div>
    <div class="div-txt st-thu">木</div>
    <!--클릭/마우스오버 인식 필요해서 레이어 순서 변경-->

    <!-- TODO: 격국 옆 데이터는 받는 데이터 -->
    <!-- <div class="right-btn">
        <button class="gyeok-yong" @click="openGyeokguk()">격국 : 정재격 <div class="arrow"></div></button>
        <button class="gyeok-yong" @click="openYongsin()">용신 <div class="arrow"></div></button>
     </div> -->

    <!-- TODO: 리팩토링? -->
    <!-- 토 -->
    <div class="txt-five-box st-05">
      <div class="txt-top">
        <div class="al-left-box">
          <div class="char-wrapper">
            <!-- "일간" 표시 -->
            <div class="skyDay-label nor">무토</div>
            <div class="skyDay-label fifth">양 中 양</div>
            <!-- 문자 표시 -->
            <span> 戊 </span>
          </div>
        </div>
        <div class="al-right-box">
          <div class="char-wrapper">
            <!-- "일간" 표시 -->
            <div class="skyDay-label nor">기토</div>
            <div class="skyDay-label fifth">음 中 음</div>
            <!-- 문자 표시 -->
            <span> 己 </span>
          </div>
        </div>
      </div>
      <div class="txt-line-box"></div>
      <div class="txt-btm">
        <div class="al-left-box">
          <div class="char-wrapper">
            <!-- 일지 표시 -->
            <div class="skyDay-label bottom nor">진토</div>
            <div class="skyDay-label bottom fifth">양 中 양</div>
            <span>辰</span>
          </div>

          <div class="char-wrapper">
            <!-- 일지 표시 -->
            <div class="skyDay-label bottom nor">술토</div>
            <div class="skyDay-label bottom fifth">양 中 음</div>
            <span>戌</span>
          </div>
        </div>
        <div class="al-right-box">
          <div class="char-wrapper">
            <!-- 일지 표시 -->
            <div class="skyDay-label bottom nor">축토</div>
            <div class="skyDay-label bottom fifth">음 中 양</div>
            <span>丑</span>
          </div>
          <div class="char-wrapper">
            <!-- 일지 표시 -->
            <div class="skyDay-label bottom nor">미토</div>
            <div class="skyDay-label bottom fifth">음 中 음</div>
            <span>未</span>
          </div>
        </div>
      </div>
      <div class="txt-mid">
        <span class="txt-rd-big">간절기</span>
      </div>
    </div>
    <!-- 금 -->
    <div class="txt-five-box st-04">
      <div class="txt-top">
        <div class="al-left-box">
          <div class="char-wrapper">
            <!-- "일간" 표시 -->
            <div class="skyDay-label-left nor">경금</div>
            <div class="skyDay-label-left fifth">음 中 양</div>
            <!-- 문자 표시 -->
            <span> 庚 </span>
          </div>
        </div>
        <div class="al-right-box">
          <div class="char-wrapper">
            <!-- "일간" 표시 -->
            <div class="skyDay-label-right nor">신금</div>
            <div class="skyDay-label-right fifth">음 中 음</div>
            <!-- 문자 표시 -->
            <span @click="handleClick('fri', char, index)"> 辛 </span>
          </div>
        </div>
      </div>
      <div class="txt-line-box"></div>
      <div class="txt-btm">
        <div class="al-left-box">
          <div class="char-wrapper">
            <!-- 일지 표시 -->
            <div class="skyDay-label-left nor">신금</div>
            <div class="skyDay-label-left fifth">음 中 양</div>
            <span>申</span>
          </div>
        </div>
        <div class="al-right-box">
          <div class="char-wrapper">
            <!-- 일지 표시 -->
            <div class="skyDay-label-right nor">유금</div>
            <div class="skyDay-label-right fifth">음 中 음</div>
            <span @click="handleClick('fri', char, index)">酉</span>
          </div>
        </div>
      </div>
      <div class="txt-mid">
        <span class="txt-rd-big">가을</span>
      </div>
    </div>

    <!-- 목 -->
    <div class="txt-five-box st-02">
      <div class="inner-center-box">
        <div class="txt-top">
          <div class="al-left-box">
            <div class="char-wrapper">
              <!-- "일간" 표시 -->
              <div class="skyDay-label nor">임수</div>
              <div class="skyDay-label fifth">음 中 양</div>
              <!-- 문자 표시 -->
              <span @click="handleClick('wed', char, index)"> 壬 </span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper">
              <!-- "일간" 표시 -->
              <div class="skyDay-label nor">계수</div>
              <div class="skyDay-label fifth">음 中 음</div>
              <!-- 문자 표시 -->
              <span @click="handleClick('wed', char, index)"> 癸 </span>
            </div>
          </div>
        </div>
        <div class="txt-line-box"></div>
        <div class="txt-btm">
          <div class="al-left-box">
            <div class="char-wrapper">
              <!-- 일지 표시 -->
              <div class="skyDay-label bottom nor">해수</div>
              <div class="skyDay-label bottom fifth">음 中 양</div>
              <span>亥</span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper">
              <!-- 일지 표시 -->
              <div class="skyDay-label bottom nor">자수</div>
              <div class="skyDay-label bottom fifth">음 中 음</div>
              <span>子</span>
            </div>
          </div>
        </div>
      </div>
      <div class="txt-mid">
        <span class="txt-rd-big">겨울</span>
      </div>
    </div>

    <!-- 수 -->
    <div class="txt-five-box st-03">
      <div class="inner-center-box">
        <div class="txt-top">
          <div class="al-left-box">
            <div class="char-wrapper">
              <!-- "일간" 표시 -->
              <div class="skyDay-label nor">갑목</div>
              <div class="skyDay-label fifth">양 中 양</div>
              <!-- 문자 표시 -->
              <span> 甲 </span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper">
              <!-- "일간" 표시 -->
              <div class="skyDay-label nor">을목</div>
              <div class="skyDay-label fifth">양 中 음</div>
              <!-- 문자 표시 -->
              <span> 乙 </span>
            </div>
          </div>
        </div>
        <div class="txt-line-box"></div>
        <div class="txt-btm">
          <div class="al-left-box">
            <div class="char-wrapper">
              <!-- 일지 표시 -->
              <div class="skyDay-label bottom nor">인목</div>
              <div class="skyDay-label bottom fifth">양 中 양</div>
              <span @click="handleClick('thu', char, index)">寅</span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper">
              <!-- 일지 표시 -->
              <div class="skyDay-label bottom nor">묘목</div>
              <div class="skyDay-label bottom fifth">양 中 음</div>
              <span @click="handleClick('thu', char, index)">卯</span>
            </div>
          </div>
        </div>
      </div>
      <div class="txt-mid">
        <span class="txt-rd-big">봄</span>
      </div>
    </div>

    <!-- 화 -->
    <div class="txt-five-box st-01">
      <div class="inner-center-box">
        <div class="txt-top">
          <div class="al-left-box">
            <div class="char-wrapper">
              <!-- "일간" 표시 -->
              <div class="skyDay-label-left nor">병화</div>
              <div class="skyDay-label-left fifth">양 中 양</div>
              <!-- 문자 표시 -->
              <span> 丙 </span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper">
              <!-- "일간" 표시 -->
              <div class="skyDay-label-right nor">정화</div>
              <div class="skyDay-label-right fifth">양 中 음</div>
              <!-- 문자 표시 -->
              <span> 丁 </span>
            </div>
          </div>
        </div>
        <div class="txt-line-box"></div>
        <div class="txt-btm">
          <div class="al-left-box">
            <!-- 일지 추가 테스트 -->
            <div class="char-wrapper">
              <!-- 일지 표시 -->
              <div class="skyDay-label-left nor">사화</div>
              <div class="skyDay-label-left fifth">양 中 양</div>
              <span>巳</span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper">
              <!-- 일지 표시 -->
              <div class="skyDay-label-right nor">오화</div>
              <div class="skyDay-label-right fifth">양 中 음</div>
              <span>午</span>
            </div>
          </div>
        </div>
      </div>
      <div class="txt-mid">
        <span class="txt-rd-big">여름</span>
      </div>
    </div>
  </div>
</template>
