import { canNavigate } from '@layouts/plugins/casl'
//import AlertComponent from '@/pages/manse/components/Alert.vue'
import store from '@/store'
import { DEV_MODE } from '@/main'
import { useLoginStore } from '@/store/modules/login'

export const setupGuards = (router) => {
  router.beforeEach(async (to, from) => {
    const loginStore = useLoginStore();
    if (to.meta.public) return true

    if (to.path === '/manse_app' && !DEV_MODE) {
      return { path: '/main' }
    }

    const isLoggedIn = !!(store.getters['user/data'] && store.getters['user/token'])
    
    if (to.path === '/saju' && !isLoggedIn) {
      if (from.path === '/auth/login') return { path:'/main' };

      // const confirmLogin = confirm('로그인 후 이용 가능 합니다.')
      const confirmLogin = await loginStore.showLoginModal();
      return confirmLogin
    }

    if (to.meta.unauthenticatedOnly) {
      if (isLoggedIn) return '/'
      else return undefined
    }

    const canAccess = await canNavigate(to)

    if (!canAccess) {
      // 로그인된 사용자가 권한이 없을 경우 Not Authorized 페이지로 이동
      return {
        name: 'login',
        query: {
          ...to.query,
          to: to.fullPath !== '/' ? to.fullPath : undefined,
        },
      }
    }

    return true
  })
}

// export const setupGuards = (router) => {
//   // 👉 router.beforeEach
//   // Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
//   router.beforeEach((to) => {

//     /*
//     const isLogged = !!(store.getters["user/token"])
//     if(to.path === '/web/saju'){
//       alert(store.getters["user/token"])
//       alert(isLogged)
//       if(!isLogged){
//         if(confirm("로그인 후 이용가능 합니다.\n 로그인 하시겠습니까?")){
//           //{ name: "login", query: {to.query} }
//           return router.push({ path: '/auth/login', query: { redirect: to.fullPath }})
//         }else{
//           return;
//         }
//       }
//     }*/
//     /*
//      * If it's a public route, continue navigation. This kind of pages are allowed to visited by login & non-login users. Basically, without any restrictions.
//      * Examples of public routes are, 404, under maintenance, etc.
//      */
//     if (to.meta.public) return

//     /**
//      * Check if user is logged in by checking if token & user data exists in local storage
//      * Feel free to update this logic to suit your needs
//      */

//     // const isLoggedIn = !!(useCookie("userData").value && useCookie("accessToken").value)
//     const isLoggedIn = !!(store.getters["user/data"] && store.getters["user/token"])

//     /*
//      * If user is logged in and is trying to access login like page, redirect to home
//      * else allow visiting the page
//      * (WARN: Don't allow executing further by return statement because next code will check for permissions)
//      */
//     if (to.meta.unauthenticatedOnly) {
//       if (isLoggedIn) return "/"
//       else return undefined
//     }
//     if (!canNavigate(to)) {
//       /* eslint-disable indent */
//       return isLoggedIn
//         ? { name: "not-authorized" }
//         : {
//             name: "login",
//             query: {
//               ...to.query,
//               to: to.fullPath !== "/" ? to.path : undefined,
//             },
//           }
//       /* eslint-enable indent */
//     }
//   })
// }
