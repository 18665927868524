<script setup>
// 🧩 일간 팝업 - 로그인 후 보이는 내용들 중 마지막 페이지

const props = defineProps({
    outroData:{
        type: Object,
    },
    curIcon: {
      type: String,
    },
    curIlgan: {
      type: String,
    }
})
const { ...five } = fiveMixin()
const emit = defineEmits(['goToFirst'])

// 다시보기 -> 표지로
const goToFirst = () => {
  emit('goToFirst')
}
</script>

<template>
    <div class="inner-box outtro">
        <div class="out-inner">
            <div class="icon-area">
                {{ props.curIcon }}
            </div>
            <div class="txt-area">
                <span 
                v-for="out in props.outroData.body.norTxt"
                class="txt">
                {{ out }}
              </span>
              <span
                class="txt-bold ganji-txt"
                :class="five.getFiveClass(five.getGanjiColorFive(props.curIlgan))">
                {{  props.outroData.body.boldTxt }}
              </span>
            </div>
            <div class="btn-area">
              <button 
                class="return-btn"
                @click="goToFirst">
                <i class="return-icon" />
                다시보기
              </button>
            </div>
        </div>

    </div>
    <div class="bottom-description">
        "사주명리학은 운명을 결정짓는 것이 아니라, 자신을 알고 세상을 바라보는 지혜를 길러주는 길입니다. <br />
        내가 가진 천간의 기운 속에서 숨겨진 가능성을 발견하고, 삶의 흐름 속에서 조화롭게 펼쳐가시길 바랍니다."
    </div>
</template>