import { defineStore } from "pinia";

export const useManseStore = defineStore('manse', () => {
  /** 임시 사주입력 데이터 */
  const tempFormData = ref(null);

  /** 임시 사주입력 데이터 저장 */
  const setTempFormData = (formData) => {
    tempFormData.value = formData;
  };

  /** 임시 사주입력 데이터 초기화 */
  const clearTempFormData = () => {
    tempFormData.value = null;
  };

  return {
    tempFormData,
    setTempFormData,
    clearTempFormData,
  }
})