<script setup>
import { IconPrev, IconNext } from '@/components/svg'
import { computed } from 'vue'

// Props 정의
const props = defineProps({
  totalPages: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  maxVisiblePages: {
    // 보여줄 최대 페이지 개수 (기본값: 10)
    type: Number,
    default: 10,
  },
})

// Emits 정의
const emit = defineEmits(['page-changed'])

// 페이지 변경 메서드
const changePage = (page) => {
  emit('page-changed', page)
}

// 보여줄 페이지 범위 계산
const startPage = computed(() => {
  let start = Math.max(1, props.currentPage - Math.floor(props.maxVisiblePages / 2))
  if (start + props.maxVisiblePages - 1 > props.totalPages) {
    start = props.totalPages - props.maxVisiblePages + 1
  }
  return Math.max(1, start)
})

const endPage = computed(() => {
  let end = Math.min(props.totalPages, startPage.value + props.maxVisiblePages - 1)
  return end
})

// 보여줄 페이지 번호 배열
const visiblePages = computed(() => {
  const pages = []
  for (let i = startPage.value; i <= endPage.value; i++) {
    pages.push(i)
  }
  return pages
})
</script>

<template>
  <div class="pagination">
    <button
      :disabled="props.currentPage === 1"
      class="pg-prev"
      @click="changePage(props.currentPage - 1)"
    >
      <IconPrev />
    </button>

    <button v-if="startPage > 1" @click="changePage(1)">1</button>
    <span v-if="startPage > 2">...</span>

    <button
      v-for="page in visiblePages"
      :key="page"
      :class="{ active: props.currentPage === page }"
      @click="changePage(page)"
    >
      {{ page }}
    </button>

    <span v-if="endPage < props.totalPages - 1">...</span>
    <button v-if="endPage < props.totalPages" @click="changePage(props.totalPages)">
      {{ props.totalPages }}
    </button>

    <button
      :disabled="props.currentPage === props.totalPages"
      class="pg-next"
      @click="changePage(props.currentPage + 1)"
    >
      <IconNext />
    </button>
  </div>
</template>
