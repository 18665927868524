const ENDPOINT = import.meta.env.VITE_APP_AWS_S3_ENDPOINT
const SHARE_THUMBNAIL_PATH = import.meta.env.VITE_SHARE_THUMBNAIL_PATH
const THUMBNAIL_URL = `${ENDPOINT}${SHARE_THUMBNAIL_PATH}`

export const branchShareDataMap = {
  Notice: {
    titlePrefix: `[낭만 만세력] `,
    messagePreset: `[낭만 만세력] 공지사항 공유해요!`,
  },
  SajuGuide: {
    titlePrefix: `[낭만 만세력] `,
    messagePreset: `[낭만 만세력] 사주 활용법 공유해요!`,
  },
  Event: {
    titlePrefix: `[낭만 만세력] `,
    messagePreset: `[낭만 만세력] 이벤트 공유해요!`,
  },
  EssayBoard: {
    titlePrefix: `[낭만 만세력] `,
    messagePreset: `[낭만 만세력]\n낭만 에세이 공유해요!`,
  },
  Question: {
    titlePrefix: `[낭만 만세력] `,
    messagePreset: `[낭만 만세력] 자주 묻는 질문 공유해요!`,
  },
  default: {
    titlePrefix: `[낭만 만세력] `,
    messagePreset: `[낭만 만세력] 콘텐츠를 공유해요!`,    
  },
}

/** 낭만센터 path를 앱 내 게시판명으로 변환 */
export const convertPathToBranchPage = (path) => {
  if (path.startsWith('/customer/notice')) return 'Notice'
  if (path.startsWith('/customer/word')) return 'SajuGuide'
  if (path.startsWith('/customer/event')) return 'Event'
  if (path.startsWith('/customer/essay')) return 'EssayBoard'
  if (path.startsWith('/customer/faq')) return 'Question'

  return null
}

/** Branch 링크 생성 */
export const createBranchLink = async (data) => {
  const { contentId, page, contentTitle, login, imageUrl } = data

  const linkProps = {
    // channel: 'web',
    // feature: 'share',
    // campaign: 'content-sharing',
    // stage: 'new user',
    // tags: ['essay', 'share'],
    data: {
      // 앱 내 branch 리스너 형식에 맞게 작성되었음
      $canonical_identifier: `${page}/${contentId}`,
      $og_title: `${branchShareDataMap[page].titlePrefix}${contentTitle}`,
      $og_image_url: login && imageUrl ? imageUrl : THUMBNAIL_URL,
      // 앱에서 열릴 수 있게 추가
      // deeplink_path: `${page}/${contentId}`,
      // $android_deeplink_path: `${page}/${contentId}`,
      // $ios_deeplink_path: `${page}/${contentId}`,
    },
  }

  const url = await generateLink(linkProps)

  return url
}

const generateLink = (linkProps) => {
  return new Promise((resolve, reject) => {
    branch.link(linkProps, (err, link) => {
      if (err) {
        reject(err)
      } else {
        resolve(link)
      }
    })
  })
}

export const modifyEssayContent = (messageLength, item) => {
  let content = ''
  if (item.firstTitle) content += `\n\n${item.firstTitle}`
  if (item.secondTitle) content += `\n\n${item.secondTitle}`
  if (item.thirdTitle) content += `\n\n${item.thirdTitle}`
  if (item.fourthTitle) content += `\n\n${item.fourthTitle}`
  if (item.contents) content += replaceEssayContentTag(`\n\n${item.contents}`)
  if (content.length + messageLength > 500)
    content = content.substring(0, 500 - messageLength - 6) + '...'
  return content
}

export const replaceEssayContentTag = (contents) => {
  if (!contents) return ''
  return contents
    // 줄바꿈 대체용 태그는 \n으로
    .replace(/<(div|p|br)[^>]*>/gi, '')
    .replace(/<\/(div|p)>/gi, '\n')

    // 인라인 스타일 태그는 제거 (span, strong, em 등)
    .replace(/<(span|strong|em|b|i)[^>]*>/gi, '')
    .replace(/<\/(span|strong|em|b|i)>/gi, '')

    // 이미지 태그 제거
    .replace(/<img[^>]*>/gi, '')

    // &nbsp; 제거
    .replace(/&nbsp;/gi, ' ')

    // 그 외 남은 HTML 태그 제거
    .replace(/<[^>]+>/g, '')

    // 연속된 줄바꿈 정리 (선택적)
    .replace(/\n{2,}/g, '\n\n') // 2줄 이상은 2줄로
    .trim()
}
