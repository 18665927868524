<script setup>
const toggleBirthClass = ref('open')
const toggleBirthClassTxt = ref('접기')
const toggleBirth = () => {
  if (toggleBirthClass.value === '') {
    toggleBirthClass.value = 'open'
    toggleBirthClassTxt.value = '접기'
  } else {
    toggleBirthClass.value = ''
    toggleBirthClassTxt.value = '보기'
  }
}
</script>
<template>
  <div class="eight-box" :class="toggleBirthClass">
    <div class="name-box">
      <div class="txt1-box">
        <span class="name">손흥민님의 사주 원국</span>
        <button class="btn-person-order"><IconOrder /> <span>순서</span></button>
      </div>
      <div class="txt2-box">
        <button class="btn-person-fold" @click="toggleBirth()">
          {{ toggleBirthClassTxt }}
          <IconFold v-if="toggleBirthClass === 'open'" />
          <IconFoldDown v-if="toggleBirthClass === ''" />
        </button>
      </div>
    </div>
    <div class="eight-birth-box">
      <div class="sky-box">
        <div class="s-tit">천간</div>
        <div class="text-box txt-big">
          <div class="s-box">
            <div class="ss-tit">시간</div>
            <span class="txt-round xl five01 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">일간</div>
            <span class="txt-round xl five02 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">월간</div>
            <span class="txt-round xl five03 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">년간</div>
            <span class="txt-round xl five04 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
        </div>
      </div>

      <div class="earth-box">
        <div class="s-tit">지지</div>
        <div class="text-box txt-big">
          <div class="s-box">
            <div class="ss-tit">시간</div>
            <span class="txt-round xl five01 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">일간</div>
            <span class="txt-round xl five02 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">월간</div>
            <span class="txt-round xl five03 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">년간</div>
            <span class="txt-round xl five04 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
