import { DEV_MODE } from '@/main';
import _ from 'lodash';

export const skyKor = '갑을병정무기경신임계'
export const skyChn = '甲乙丙丁戊己庚辛壬癸'
export const earthKor = '자축인묘진사오미신유술해'
export const earthChn = '子丑寅卯辰巳午未申酉戌亥'

export default function fiveMixin() {
  /** 오행 10간 12지 데이터 */
  const ganjiJsonData = {
    "five":{
      "木":{"color":"#5e9f1c"},
      "火":{"color":"#c23535"},
      "土":{"color":"#db9823"},
      "金":{"color":"#949494"},
      "水":{"color":"#404040"},
    },
    "ganji":{
      "갑":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "甲":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "을":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "乙":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "병":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "丙":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "정":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "丁":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "무":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "戊":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "기":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "己":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "경":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "庚":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "신":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "辛":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "임":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "壬":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "계":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "癸":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "자":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "子":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "축":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "丑":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "인":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "寅":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "묘":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "卯":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "진":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "辰":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "사":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "巳":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "오":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "午":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "미":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "未":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      
      "申":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "유":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "酉":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "술":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "戌":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "해":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "亥":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
    }
  };
  /** 간지 텍스트의 오행 정보 확인 */
  const getGanjiColorFive = (txt) => ganjiJsonData.ganji[txt]?.five;

  /** 오행별 클래스 추가 */
  const getFiveClass = (fiveValue) => {
    const normalizedFiveValue = fiveValue?.normalize('NFC').trim();
    return {
      five01: normalizedFiveValue === '火' || normalizedFiveValue === '화',
      five02: normalizedFiveValue === '水' || normalizedFiveValue === '수',
      five03: normalizedFiveValue === '木' || normalizedFiveValue === '목',
      five04: normalizedFiveValue === '金' || normalizedFiveValue === '금',
      five05: normalizedFiveValue === '土' || normalizedFiveValue === '토',
      five06: normalizedFiveValue === '-' || normalizedFiveValue === '-',
    };
  };

  const getFiveClassText = (fiveValue) => {
    const fiveClass = getFiveClass(fiveValue);
    return Object.keys(fiveClass).filter((key) => fiveClass[key]);
  };

  const getGanjiColor = (txt) => {
    const matchingFive = ganjiJsonData.ganji[txt]?.five;
    return getFiveClass(matchingFive);
  };

  const getGanjiColorOnly = (txt) => {
    if (txt === undefined || txt === '') return '';
    let $bgColor = '';
    const fiveValue = ganjiJsonData.value?.ganji[txt]?.five;

    if (fiveValue === '火' || fiveValue === '화') $bgColor = '#C23535';
    if (fiveValue === '水' || fiveValue === '수') $bgColor = '#404040';
    if (fiveValue === '木' || fiveValue === '목') $bgColor = '#5E9F1C';
    if (fiveValue === '金' || fiveValue === '금') $bgColor = '#949494';
    if (fiveValue === '土' || fiveValue === '토') $bgColor = '#DB9823';
    return $bgColor;
  };

  const getColorFiveText = (txt) => {
    const kor = '갑을병정무기경신임계자축인묘진사오미신유술해甲乙丙丁戊己庚辛壬癸子丑寅卯辰巳午未申酉戌亥-';
    const fiveColor = '목목화화토토금금수수수토목목토화화토금금토수목목화화토토금금수수수토목목토화화토금금토수-';
    const index = kor.indexOf(txt);
    return fiveColor[index];
  }
  
  const getColorFive = (txt) => {
    const kor      = '갑을병정무기경신임계자축인묘진사오미신유술해甲乙丙丁戊己庚辛壬癸子丑寅卯辰巳午未申酉戌亥-';
    const fieColor = '목목화화토토금금수수수토목목토화화토금금토수목목화화토토금금수수수토목목토화화토금금토수-';
    const idx = kor.indexOf(txt);
    if (idx !== -1) {
      return getFiveClass(fieColor[idx]);
    }
    return '';
  }

  const getYinYang = (txt, pos) => {
    const skyKor = '갑을병정무기경신임계';
    const skyChn = '甲乙丙丁戊己庚辛壬癸';
  
    const earthKor = '자축인묘진사오미신유술해';
    const earthChn = '子丑寅卯辰巳午未申酉戌亥';
  
    const kor = pos === 'sky' ? skyKor : earthKor;
    const chn = pos === 'sky' ? skyChn : earthChn;
  
    // 한글로 입력된 경우
    let idx = kor.indexOf(txt);
    if (idx !== -1) {
      return idx % 2 === 0 ? '양' : '음'; // 홀수: 양, 짝수: 음
    }
  
    // 한자로 입력된 경우
    idx = chn.indexOf(txt);
    if (idx !== -1) {
      return idx % 2 === 0 ? '양' : '음'; // 홀수: 양, 짝수: 음
    }
  
    // 유효하지 않은 입력일 경우
    return '';
  };

  const getHanguelTxt = (txt, pos) => {
    const skyKor = '갑을병정무기경신임계';
    const skyChn = '甲乙丙丁戊己庚辛壬癸';

    const earthKor = '자축인묘진사오미신유술해';
    const earthChn = '子丑寅卯辰巳午未申酉戌亥';

    const kor = pos === 'sky' ? skyKor : earthKor;
    const chn = pos === 'sky' ? skyChn : earthChn;

    const idx = chn.indexOf(txt);
    if (idx !== -1) {
      return kor[idx];
    }
    return '';
  }

  const getHanjaTxt = (txt, pos) => {
    const skyKor = '갑을병정무기경신임계';
    const skyChn = '甲乙丙丁戊己庚辛壬癸';

    const earthKor = '자축인묘진사오미신유술해';
    const earthChn = '子丑寅卯辰巳午未申酉戌亥';

    const kor = pos === 'sky' ? skyKor : earthKor;
    const chn = pos === 'sky' ? skyChn : earthChn;

    const idx = kor.indexOf(txt);
    if (idx !== -1) {
      return chn[idx];
    }
    return '';
  };
  
  const getHanjaTxt2 = (txt) => {
    const kor = '갑을병정무기경신임계자축인묘진사오미신유술해'
    const chn = '甲乙丙丁戊己庚辛壬癸子丑寅卯辰巳午未申酉戌亥'
    const idx = kor.indexOf(txt);
    if (idx !== -1) {
      return chn[idx];
    }
    return '';
  };

  const getKorTxt = (txt, pos) => {
    const kor = pos === 'sky' ? skyKor : earthKor;
    const chn = pos === 'sky' ? skyChn : earthChn;
  
    const idx = chn.indexOf(txt);
    if (idx !== -1) {
      return kor[idx];
    }
    return '';
  };

  const formatGanJi = (txt, no) => {
    if (typeof (txt) === 'undefined') {
      return '';
    }
    const character = txt.charAt(no - 1);
    return character;
  };


  const fiveColorCnt = (curDaeun, curBirthFive) => {
    DEV_MODE && console.log("curBirthFive ---- ")
    DEV_MODE && console.log(curBirthFive)

    const fiveCnt = JSON.parse(JSON.stringify(curBirthFive))

    const fiveMap = {
      木: "five1",
      목: "five1",
      火: "five2",
      화: "five2",
      土: "five3",
      토: "five3",
      金: "five4",
      금: "five4",
      水: "five5",
      수: "five5",
    }

    const incrementNum = (element) => {
      const key = element?.five
      if (key && fiveMap[key]) {
        fiveCnt[fiveMap[key]].num += 1
      }
    }

    incrementNum(curDaeun?.sky[0])
    incrementNum(curDaeun?.earth[0])

    return fiveCnt
  }


  const convertHap = ({hap, isSky, langMode}) => {
    if (!hap) return []
    const result = []

    let _hap = []
    let _hyung = []
    let _chung = []
    let _pa = []
    let _hae = []

    const relationNames = {
      hap: langMode === 'CHN' ? '合' : '합',
      hyung: langMode === 'CHN' ? '刑' : '형',
      chung: langMode === 'CHN' ? '沖' : '충',
      pa: langMode === 'CHN' ? '破' : '파',
      hae: langMode === 'CHN' ? '害' : '해'
    }

    if (isSky) {
      if (!hap.sky) return []
      _hap = _.chunk([...hap.sky.hap], 2)
      _chung = _.chunk([...hap.sky.chung], 2)
    } else {
      _hap = _.chunk([...hap.earth.hap], 2)
      _hyung = _.chunk([...hap.earth.hyung], 2)
      _chung = _.chunk([...hap.earth.chung], 2)
      _pa = _.chunk([...hap.earth.pa], 2)
      _hae = _.chunk([...hap.earth.hae], 2)
    }

    for (const hapItem of _hap) {
      const [item1, item2] = hapItem
      const orderedHap = sortByOrderHap({item1, item2, isSky, langMode})
      result.push(`${orderedHap}${relationNames.hap}`)
    }
    for (const hyungItem of _hyung) {
      const [item1, item2] = hyungItem
      const orderedHap = sortByOrderHap({item1, item2, isSky, langMode})
      result.push(`${orderedHap}${relationNames.hyung}`)
    }
    for (const chungItem of _chung) {
      const [item1, item2] = chungItem
      const orderedHap = sortByOrderHap({item1, item2, isSky, langMode})
      result.push(`${orderedHap}${relationNames.chung}`)
    }
    for (const paItem of _pa) {
      const [item1, item2] = paItem
      const orderedHap = sortByOrderHap({item1, item2, isSky, langMode})
      result.push(`${orderedHap}${relationNames.pa}`)
    }
    for (const haeItem of _hae) {
      const [item1, item2] = haeItem
      const orderedHap = sortByOrderHap({item1, item2, isSky, langMode})
      result.push(`${orderedHap}${relationNames.hae}`)
    }

    // 중복 데이터 제거
    const filteredResult = [...new Set(result)];

    return filteredResult
  }

  /** 천간/지지합 순서 정렬 */
  const sortByOrderHap = ({item1, item2, isSky, langMode}) => {
    let object = '';
    if (langMode === 'CHN' && isSky) object = skyChn
    else if (langMode === 'CHN' && !isSky) object = earthChn
    else if (langMode !== 'CHN' && isSky) object = skyKor
    else object = earthKor
  
    const firstIndex = object.indexOf(item1.value)
    const secondIndex = object.indexOf(item2.value)
  
    return firstIndex <= secondIndex 
      ? item1.value + item2.value
      : item2.value + item1.value
  }

  const getBirthTimeTxt = (str) => {
    return str === '25:00' ? '--:--' : str;
  }
  const getTimeTxt2 = (time) => {
    time = String(time);
    return time?.padStart(2, '0');
  }

  const getGanjiDescription = (ganji) => {
    // 천간과 지지 데이터 정의
    const skyKor = '갑을병정무기경신임계';
    const skyChn = '甲乙丙丁戊己庚辛壬癸';
    const earthKor = '자축인묘진사오미신유술해';
    const earthChn = '子丑寅卯辰巳午未申酉戌亥';
  
    // 색상 및 동물 정의
    const skyColors = {
      목: '푸른',
      화: '붉은',
      토: '황금',
      금: '하얀',
      수: '검은',
    };
    const earthAnimals = {
      자: '쥐',
      축: '소',
      인: '호랑이',
      묘: '토끼',
      진: '용',
      사: '뱀',
      오: '말',
      미: '양',
      신: '원숭이',
      유: '닭',
      술: '개',
      해: '돼지',
    };
  
    // 오행 매핑
    const skyToFive = {
      갑: '목', 을: '목',
      병: '화', 정: '화',
      무: '토', 기: '토',
      경: '금', 신: '금',
      임: '수', 계: '수',
      甲: '목', 乙: '목',
      丙: '화', 丁: '화',
      戊: '토', 己: '토',
      庚: '금', 辛: '금',
      壬: '수', 癸: '수',
    };
  
    // 입력 값이 두 글자인지 확인
    if (ganji.length !== 2) {
      return '';
    }
  
    // 천간과 지지를 분리
    const sky = ganji[0]; // 첫 글자: 천간
    const earth = ganji[1]; // 두 번째 글자: 지지
  
    // 천간과 지지 유효성 검사
    const skyIndex = skyKor.indexOf(sky) !== -1 ? skyKor.indexOf(sky) : skyChn.indexOf(sky);
    const earthIndex = earthKor.indexOf(earth) !== -1 ? earthKor.indexOf(earth) : earthChn.indexOf(earth);
  
    if (skyIndex === -1 || earthIndex === -1) {
      return '유효하지 않은 입력입니다. 올바른 천간과 지지를 입력해주세요.';
    }
  
    // 천간 색상 가져오기
    const skyChar = skyKor[skyIndex] || skyChn[skyIndex];
    const fiveElement = skyToFive[skyChar];
    const color = skyColors[fiveElement];
  
    // 지지 동물 가져오기
    const earthChar = earthKor[earthIndex] || earthChn[earthIndex];
    const animal = earthAnimals[earthChar];
  
    // 최종 결과 반환
    return `${color} ${animal}의 날`;
  }

  const getGanjiCoverData = (ganji) => {
    // 천간과 지지 데이터 정의
    const skyKor = '갑을병정무기경신임계';
    const skyChn = '甲乙丙丁戊己庚辛壬癸';
    const earthKor = '자축인묘진사오미신유술해';
    const earthChn = '子丑寅卯辰巳午未申酉戌亥';
  
    // 오행 매핑 (목화토금수 -> five01 ~ five05)
    const skyToFive = {
      갑: 'five03', 을: 'five03', // 목
      병: 'five01', 정: 'five01', // 화
      무: 'five05', 기: 'five05', // 토
      경: 'five04', 신: 'five04', // 금
      임: 'five02', 계: 'five02', // 수
      甲: 'five03', 乙: 'five03',
      丙: 'five01', 丁: 'five01',
      戊: 'five05', 己: 'five05',
      庚: 'five04', 辛: 'five04',
      壬: 'five02', 癸: 'five02',
    };
  
    // 지지 동물 매핑 (영어)
    const earthAnimalsEng = {
      자: 'rat', 축: 'ox', 인: 'tiger', 묘: 'rabbit', 진: 'dragon',
      사: 'snake', 오: 'horse', 미: 'goat', 신: 'monkey', 유: 'rooster',
      술: 'dog', 해: 'pig',
    };
  
    // 입력 값이 두 글자인지 확인
    if (!ganji || ganji.length !== 2) {
      return { five: '', animal: '' };
    }
  
    // 천간과 지지를 분리
    const sky = ganji[0]; // 첫 글자: 천간
    const earth = ganji[1]; // 두 번째 글자: 지지
  
    // 천간과 지지 유효성 검사
    const skyIndex = skyKor.indexOf(sky) !== -1 ? skyKor.indexOf(sky) : skyChn.indexOf(sky);
    const earthIndex = earthKor.indexOf(earth) !== -1 ? earthKor.indexOf(earth) : earthChn.indexOf(earth);
  
    if (skyIndex === -1 || earthIndex === -1) {
      return { five: '', animal: '' };
    }
  
    // 천간 오행 가져오기
    const skyChar = skyKor[skyIndex] || skyChn[skyIndex];
    const five = skyToFive[skyChar] || '';
  
    // 지지 동물 가져오기
    const earthChar = earthKor[earthIndex] || earthChn[earthIndex];
    const animal = earthAnimalsEng[earthChar] || '';
  
    // 결과 반환
    return { five, animal };
  };

  const skyStemFiveMap = {
    갑: { kor: "갑목", chn: "甲木" },
    甲: { kor: "갑목", chn: "甲木" },
    을: { kor: "을목", chn: "乙木" },
    乙: { kor: "을목", chn: "乙木" },
    병: { kor: "병화", chn: "丙火" },
    丙: { kor: "병화", chn: "丙火" },
    정: { kor: "정화", chn: "丁火" },
    丁: { kor: "정화", chn: "丁火" },
    무: { kor: "무토", chn: "戊土" },
    戊: { kor: "무토", chn: "戊土" },
    기: { kor: "기토", chn: "己土" },
    己: { kor: "기토", chn: "己土" },
    경: { kor: "경금", chn: "庚金" },
    庚: { kor: "경금", chn: "庚金" },
    신: { kor: "신금", chn: "辛金" },
    辛: { kor: "신금", chn: "辛金" },
    임: { kor: "임수", chn: "壬水" },
    壬: { kor: "임수", chn: "壬水" },
    계: { kor: "계수", chn: "癸水" },
    癸: { kor: "계수", chn: "癸水" }
  };
  
  const getSkyStemFive = (element) => {
    return skyStemFiveMap[element] || null;
  };

  const earthBranchFiveMap = {
    자: { kor: "자수", chn: "子水" },
    子: { kor: "자수", chn: "子水" },
    축: { kor: "축토", chn: "丑土" },
    丑: { kor: "축토", chn: "丑土" },
    인: { kor: "인목", chn: "寅木" },
    寅: { kor: "인목", chn: "寅木" },
    묘: { kor: "묘목", chn: "卯木" },
    卯: { kor: "묘목", chn: "卯木" },
    진: { kor: "진토", chn: "辰土" },
    辰: { kor: "진토", chn: "辰土" },
    사: { kor: "사화", chn: "巳火" },
    巳: { kor: "사화", chn: "巳火" },
    오: { kor: "오화", chn: "午火" },
    午: { kor: "오화", chn: "午火" },
    미: { kor: "미토", chn: "未土" },
    未: { kor: "미토", chn: "未土" },
    신: { kor: "신금", chn: "申金" },
    申: { kor: "신금", chn: "申金" },
    유: { kor: "유금", chn: "酉金" },
    酉: { kor: "유금", chn: "酉金" },
    술: { kor: "술토", chn: "戌土" },
    戌: { kor: "술토", chn: "戌土" },
    해: { kor: "해수", chn: "亥水" },
    亥: { kor: "해수", chn: "亥水" }
  };

  const getEarthBranchFive = (element) => {
    return earthBranchFiveMap[element] || null;
  }

  return { ganjiJsonData, getFiveClass, getFiveClassText, getGanjiColor, getGanjiColorOnly, getGanjiColorFive, getColorFiveText, getColorFive, getYinYang, getHanguelTxt, getHanjaTxt, getHanjaTxt2, getKorTxt, formatGanJi, fiveColorCnt, convertHap, getBirthTimeTxt, getTimeTxt2, getGanjiDescription, getSkyStemFive, getEarthBranchFive, getGanjiCoverData }
};

