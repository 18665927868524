<template>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path
        d="M10 0H0L4.23178 5.07813C4.63157 5.55789 5.36843 5.55789 5.76822 5.07814L10 0Z"
        fill="#FF8800"
      />
    </svg>
  </span>
</template>
