import axios from "axios";
import { useLogsStore } from "@/store/modules/logsStore";
import { DEV_MODE } from "@/main";

const systemId = import.meta.env.VITE_APP_SYSTEMID;

/** 컨텐츠 리스트를 가져오는 API */
export const getLogStatsAPI = async () => {
  const path = `/contents/${systemId}/get`;
  const response = await axios.get(path);
  return response.data;
};

/** 컨텐츠 로그를 기록하는 API */
export const addContentLogAPI = async (args) => {
  if (!args) return;
  if (DEV_MODE) {
    console.log('========================================')
    console.log('addContentLogAPI:', args)
    console.log('========================================')
  }
  const logData = await convertContentLogData(args);
  if (!logData) return;

  const path = `/contents/log`;
  const data = { ...logData };

  try {
    const result = await axios.post(path, data);    
    return result;
  } catch (e) {
    console.error('addContentLogAPI error:', e);
  }
};

/** 컨텐츠 로그 데이터를 변환하는 함수 */
export const convertContentLogData = async args => {
  const logsStore = useLogsStore();
  let contentLogIds = logsStore.contentLogIds;

  if (!contentLogIds) {
    const result = await getLogStatsAPI();
    logsStore.setLogContents(result);
    contentLogIds = logsStore.contentLogIds;
  }

  if (typeof args === 'string') {
    const contentId = contentLogIds[args] ?? -1;
    if (contentId === -1) return null;

    return {
      systemId,
      contents: {contentId},
    };
  }

  const {contentType, servicePk, serviceType, option3} = args;
  const contentId = contentLogIds[contentType] ?? -1;
  if (contentId === -1 || !contentType) return null;

  return {
    systemId,
    contents: {contentId},
    serviceType,
    servicePk,
    option3,
  };
};