<script setup>
import { VRow } from 'vuetify/lib/components/index.mjs'
import IlganPopIntro from './IlganPopIntro.vue'
import IlganPopContent from './IlganPopContent.vue'
import { getIlganPopup } from '@/api/IlganPop'

// import {popupData as detailExpList} from '../data/popupExp.js'

const props = defineProps({
  toggleProMode: {
    type: String,
    default: null,
  },
  pgmode: {
    type: String,
    default: null,
  },
  ilganInfo:{
    type: Object,
  },
})
const emit = defineEmits()
const { ...five } = fiveMixin();

// 데이터 요청
/** 일간 정보에 따른 code 생성 */
const korIlgan = five.getHanguelTxt(props.ilganInfo.char, 'sky')
const dayMap = {
  tue: "화",
  wed: "수",
  thu: "목",
  fri: "금",
  sat: "토",
};
const koreanDay = computed(() => dayMap[props.ilganInfo.whichDay] || "");
const ilganCode = computed(() => `일간팝업_${korIlgan}${koreanDay.value}`);

/** code를 활용하여 정보 요청 */
const ilganDataRaw = ref({});
const fetchIlgan = async () =>{
  const data = await getIlganPopup(ilganCode.value);
  ilganDataRaw.value = data;
  await nextTick();
}
const ilganData = computed(() => ilganDataRaw.value); 

// 컬러코드 데이터
const colorMap = {
  tue: "fifive01",
  wed: "fifive02",
  thu: "fifive03",
  fri: "fifive04",
  sat: "fifive05",
};
const fiveElements = computed(() => colorMap[props.ilganInfo.whichDay] || "")

const PopManseDetailBox = ref(true)
const close = () => {
  emit('close')
}
function open() {
  PopManseDetailBox.value = true
}


/** 마우스 우클릭 방지 */
const disableRightClick = (event) => {
  event.preventDefault()
}

onMounted(async () => {
  fetchIlgan();
  await nextTick();
  document.addEventListener('contextmenu', disableRightClick)
})

onBeforeUnmount(() => {
  document.removeEventListener('contextmenu', disableRightClick)
})

/** 콘텐트로 넘어가기 */
const isContentVisible = ref(false);
const showIlganContent = () =>{
  isContentVisible.value = true;
}

/** 다시보기 */
const showIntro = () =>{
  isContentVisible.value = false;
}

watch(PopManseDetailBox, (newState) => {
  if (!newState) close();
});
</script>
<template>
  <VDialog
    v-model="PopManseDetailBox"
    width="740px"
    height="734px"
    max-width="100%"
    class="dialog-ilgan-five"
    :class="[
      'dialog-saju-keyword',
    ]"
  >
    <VCard class="br16 ilgan-inner-cd">
      <div
        class="ilgan-inner-cd"
        :class="fiveElements"
      >
      <template v-if="!isContentVisible">
        <div class="pop-tit-box ">
          <button class="dial-close ilganIntro" @click="close"></button>
        </div>
        <div class="pop-detail-body">
          <IlganPopIntro
            v-if="Object.keys(ilganData).length && !isContentVisible"
            @show-content-page="showIlganContent"
            :ilganData="ilganData" 
            :fiveElements="fiveElements"
            />
        </div>
      </template>
        <IlganPopContent
          v-else
          :ilganData="ilganData"
          @close="close"
          @showIntro="showIntro"
          />

      </div>
    </VCard>
  </VDialog>
</template>

