<script setup>
import fiveTabBasic from '@/pages/analysis/components/fiveTabBasic.vue'
import fiveTabImage from '@/pages/analysis/components/fiveTabImage.vue'
import fiveTabDetail from '@/pages/analysis/components/fiveTabDetail.vue'

import birthSkyEarth from '@/pages/analysis/components/birthSkyEarth.vue'

import { defineProps, defineEmits } from 'vue'
const props = defineProps({
  selectedDescNav: {
    type: String,
  },
  selectedDescNavSub: {
    type: String,
  },
})

const emit = defineEmits(['updateSelectedDescNav', 'updateSelectedDescNavSub'])

function updateNav(value) {
  emit('updateSelectedDescNav', value)
}

function updateNavSub(value) {
  emit('updateSelectedDescNavSub', value)
}
</script>
<template>
  <div class="desc-header">
    <div class="desc-nav">
      <button
        :class="['nav-button', { active: selectedDescNav === 'basic' }]"
        @click="updateNav('basic')"
      >
        기본해석
      </button>
      <!-- <button
        :class="['nav-button', { active: selectedDescNav === 'image' }]"
        @click="selectedDescNav = 'image'"
      >
        이미지
      </button>
      <button
        :class="['nav-button', { active: selectedDescNav === 'detail' }]"
        @click="selectedDescNav = 'detail'"
      >
        상세분석
      </button> -->
    </div>
    <i class="bot-button"></i>
  </div>
  <div class="desc-nav-sub">
    <button
      class="btn-sub"
      :class="[{ active: selectedDescNavSub === 'first' }]"
      @click="updateNavSub('first')"
    >
      오행도란
    </button>
    <button
      class="btn-sub"
      :class="[{ active: selectedDescNavSub === 'second' }]"
      @click="updateNavSub('second')"
    >
      사주구성
    </button>
    <button
      class="btn-sub"
      :class="[{ active: selectedDescNavSub === 'third' }]"
      @click="updateNavSub('third')"
    >
      천간
    </button>
    <button
      class="btn-sub"
      :class="[{ active: selectedDescNavSub === 'fourth' }]"
      @click="updateNavSub('fourth')"
    >
      지지
    </button>
    <button
      class="btn-sub"
      :class="[{ active: selectedDescNavSub === 'fifth' }]"
      @click="updateNavSub('fifth')"
    >
      양/음
    </button>
  </div>
  <div v-if="selectedDescNavSub === 'first'" class="desc-container w-100">
    <div class="inner">
      <div class="main-card st-five-first">
        <div class="tit">오행도란?</div>
        <div class="exp">
          오행(五行)은 음양오행(陰陽五行)중 오행을 말하며, 동양에서 우주 만물의 변화를 나무(木),
          불(火), 흙(土), 쇠(金), 물(水)의 다섯 가지 기운으로 압축해 설명하려고 했던 사상. 종종
          서양의 4원소설과 비교되기도 한다. 4원소설과는 불, 물, 흙 3가지가 겹치고 마지막 공기 대신
          나무와 쇠가 들어가는 차이점이 있다. 인, 의, 예, 지, 신의 오덕(五德)이나 근대 이전 육안으로
          관찰되었던 태양계 5행성과 연결을 짓기도 하며, 김 삿갓 등의 일화 중에는 부수를 포함하여
          오행에 맞춰 시를 짓는 내기도 있다.
        </div>
      </div>
    </div>
  </div>
  <div v-if="selectedDescNavSub === 'second'" class="desc-container w-100">
    <div class="inner">
      <div class="main-card st-five-first">
        <div class="tit">사주의 구성은?</div>
        <div class="exp">
          내가 태어난 년,월,일,시의 숫자를 60간지로 구성된 역학 달력의 간지로 표현한 것을 뜻합니다.
          4개의 천간과 4개의 지지가 만나 총 8개의 사주팔자로 형성됩니다.
        </div>
        <div class="btm-gap-xlg"></div>
        <div class="tit">사주 구성 예시</div>
        <div class="exp">아래는 손흥민님 사주의 구성입니다. 사주 원국이라고도 합니다.</div>
        <div class="btm-gap-xlg"></div>
        <div class="">
          <birthSkyEarth />
        </div>
      </div>
    </div>
  </div>
  <div v-if="selectedDescNavSub === 'third'" class="desc-container w-100">
    <div class="inner">
      <div class="main-card st-five-first">
        <div class="tit">천간은?</div>
        <div class="exp">
          사주 팔자의 8개 글자 중 윗부분에 위치하는 글자로 내가 태어난 년, 월, 일, 시를 뜻합니다.
        </div>
        <div class="btm-gap-xlg"></div>
        <div class="exp">
          십간(十干)이라고 하며, 또는 천간(天干)의 준말로 쓰입니다. 총 5개의 오행이 음양으로
          구분되어 10개의 천간을 구성합니다.
        </div>
        <div class="btm-gap-xlg"></div>
        <div class="tb-tab-01">
          <table>
            <colgroup>
              <col width="33.33%" />
              <col width="33.33%" />
              <col width="33.33%" />
            </colgroup>
            <thead>
              <tr>
                <th>오행(五行)</th>
                <th>양(陽)</th>
                <th>음(陰)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="b thu">목(木)</td>
                <td>甲(갑)</td>
                <td>乙(을)</td>
              </tr>
              <tr>
                <td class="b tue">화(火)</td>
                <td>丙(병)</td>
                <td>丁(정)</td>
              </tr>
              <tr>
                <td class="b sat">토(土)</td>
                <td>戊(무)</td>
                <td>己(기)</td>
              </tr>
              <tr>
                <td class="b fri">금(金)</td>
                <td>庚(경)</td>
                <td>辛(신)</td>
              </tr>
              <tr>
                <td class="b wed">수(水)</td>
                <td>壬(임)</td>
                <td>癸(계)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-if="selectedDescNavSub === 'fourth'" class="desc-container w-100">
    <div class="inner">
      <div class="main-card st-five-first">
        <div class="tit">지지는?</div>
        <div class="exp">
          내 사주의 천간 아래에 위치하는 글자로 한자로는 地(땅 지), 支(가를 지)를 사용합니다. 한자를
          풀이하면 땅의 기운을 뜻하고 넓게는 천간에 에너지를 받는 땅의 기운이라고 할 수 있습니다.
        </div>
        <div class="btm-gap-xlg"></div>
        <div class="exp">地支(지지)에는 12개의 十二支(십이지)가 있습니다.</div>
        <div class="btm-gap-xlg"></div>
        <div class="tb-tab-01">
          <table>
            <colgroup>
              <col width="33.33%" />
              <col width="33.33%" />
              <col width="33.33%" />
            </colgroup>
            <thead>
              <tr>
                <th>오행(五行)</th>
                <th>양(陽)</th>
                <th>음(陰)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="b thu">목(木)</td>
                <td>寅(인)</td>
                <td>卯(묘)</td>
              </tr>
              <tr>
                <td class="b tue">화(火)</td>
                <td>巳(사)</td>
                <td>午(오)</td>
              </tr>
              <tr>
                <td class="b sat">토(土)</td>
                <td>戌(술)ㆍ未(미)</td>
                <td>辰(진)ㆍ丑(축)</td>
              </tr>
              <tr>
                <td class="b fri">금(金)</td>
                <td>申(신)</td>
                <td>酉(유)</td>
              </tr>
              <tr>
                <td class="b wed">수(水)</td>
                <td>자(子)</td>
                <td>壬(임)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-if="selectedDescNavSub === 'fifth'" class="desc-container w-100">
    <div class="inner">
      <div class="main-card st-five-first st-col-6">
        <div class="tit">음양은?</div>
        <div class="exp">
          명리학의 기본 철학은 음양학에 기반을 두고 있습니다. 밝음과 어둠, 밤과 낮, 남과 여, 생과 사
          등 세상의 모든 물질은 양과 음의 성질로 크게 구분되고, 음양의 철학은 우리의 일상 생활속에
          중요한 기준점이 되어줍니다. <br /><br />음과 양은 떨어질 수 없는 자연의 법칙으로
          생활속에서 학문과 과학을 통해 경험하고 해석됩니다. 낮이 있으면 밤이 있듯, 음과 양은 우리의
          일상생활속에 중요한 의미로 해석하고 응용될 수 있습니다.
        </div>
        <div class="btm-gap-xlg"></div>
        <div class="tb-tab-01">
          <table>
            <colgroup>
              <col width="16%" />
              <col width="16%" />
              <col width="16%" />
              <col width="1%" />
              <col width="16%" />
              <col width="16%" />
              <col width="16%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="3">천간 양(陽)</th>
                <th class="bg-white"></th>
                <th colspan="3">천간 음(陰)</th>
              </tr>
              <tr>
                <th>오행</th>
                <th>양 中 양</th>
                <th>양 中 음</th>
                <th class="bg-white"></th>
                <th>음 中 양</th>
                <th>음 中 음</th>
                <th>오행</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="b thu">목(木)</td>
                <td>甲(갑)</td>
                <td>乙(을)</td>
                <td></td>
                <td>庚(경)</td>
                <td>辛(신)</td>
                <td class="b fri">금(金)</td>
              </tr>
              <tr>
                <td class="b thu">화(火)</td>
                <td>丙(병)</td>
                <td>丁(정)</td>
                <td></td>
                <td>壬(임)</td>
                <td>癸(계)</td>
                <td class="b wed">수(水)</td>
              </tr>
              <tr>
                <td class="b sat">토(土)</td>
                <td>戊(무)</td>
                <td></td>
                <td></td>
                <td></td>
                <td>己(기)</td>
                <td class="b fri">토(土)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="btm-gap-xlg"></div>
        <div class="tb-tab-01">
          <table>
            <colgroup>
              <col width="16%" />
              <col width="16%" />
              <col width="16%" />
              <col width="1%" />
              <col width="16%" />
              <col width="16%" />
              <col width="16%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="3">지지 양(陽)</th>
                <th class="bg-white"></th>
                <th colspan="3">지지 음(陰)</th>
              </tr>
              <tr>
                <th>오행</th>
                <th>양 中 양</th>
                <th>양 中 음</th>
                <th class="bg-white"></th>
                <th>음 中 양</th>
                <th>음 中 음</th>
                <th>오행</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="b thu">목(木)</td>
                <td>寅(인)</td>
                <td>卯(묘)</td>
                <td></td>
                <td>申(신)</td>
                <td>酉(유)</td>
                <td class="b fri">금(金)</td>
              </tr>
              <tr>
                <td class="b thu">화(火)</td>
                <td>巳(사)</td>
                <td>午(오)</td>
                <td></td>
                <td>亥(해)</td>
                <td>子(자)</td>
                <td class="b wed">수(水)</td>
              </tr>
              <tr>
                <td class="b sat">토(土)</td>
                <td>辰(진)</td>
                <td>戌(술)</td>
                <td></td>
                <td>丑(축)</td>
                <td>未(미)</td>
                <td class="b fri">토(土)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-area-tab-btm">
    <button class="btn-line-box st-five-guide">나의 사주구성 바로 확인하러 가기</button>
  </div>
</template>
