<script setup>
const props = defineProps({
  contents: Object,
  curIlgan: String,
  IlganKC: String,
})
const { ...five } = fiveMixin()

const getColorClass = (key) => {
  const secondChar = key.charAt(1); // 두 번째 글자 추출
  switch (secondChar) {
    case '목': return 'five03';
    case '화': return 'five01';
    case '토': return 'five05';
    case '금': return 'five04';
    case '수': return 'five02';
    default: return ''; // 기본 색상 유지
  }
};
</script>

<template>
    <div class="cont-pages">
        <div class="tit">
            <span class="main-tit ganji-txt" :class="five.getFiveClass(five.getGanjiColorFive(props.curIlgan))">
                {{ contents.icon }} {{ contents.title }}
            </span>            
        </div>
        <div class="inner-cont">
            <span class="nor-txt">
                {{ contents.body.descriptions[0] }}
            </span>
            <div v-for="har in contents.body.harmony" class="gray-box">
                <span class="gray-tit">
                    {{ har.title }}
                </span>
                <span
                    v-for="(value, key) in har.content"
                    class="gray-cont list">
                    <span class="ganji-txt" :class="getColorClass(key)" style="font-weight: 500; padding-left: 5px">{{ key }} →</span> {{ value }}
                </span>
            </div>
        </div>
    </div>
</template>