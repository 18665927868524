<template>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.5 7.13397C14.1667 7.51887 14.1667 8.48113 13.5 8.86603L6 13.1962C5.33333 13.5811 4.5 13.0999 4.5 12.3301L4.5 3.66987C4.5 2.90007 5.33333 2.41895 6 2.80385L13.5 7.13397Z"
        fill="white"
      />
    </svg>
  </span>
</template>
