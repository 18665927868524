{
  "Nangman Calendar": "낭만만세력",
  "Essay": "에세이",
  "Download": "다운로드",
  "AccessStats": "접속통계",
  "GuestManse": "Guest(만세력)",
  "SignUp": "회원가입",
  "UserLogin": "로그인",
  "PaymentHistory": "결제내역",
  "ContentUsage": "컨텐츠 이용",
  "BannerClick": "배너클릭",
  "CustomerService": "고객센터",
  "Configuration": "환경설정",

  "First Section": "Management",
  "User Management": "회원관리",
  "Member List": "회원목록",
  "Guest List": "게스트목록",
  "Second Section": "Essay&Banner&Board",
  "Payment Management": "결제관리",
  "Payment List": "결제 목록",
  "Appstore Settlemet": "Appstore정산",
  "Inquiry Management": "문의관리",
  "Board Inquiry": "1:1 문의",
  "Board Saju Inquiry": "명리상담",
  "Essay & Banner": "에세이&배너관리",
  "Essay Home": "에세이(홈)",
  "Essay Board": "에세이(게시판)",
  "Banner Management": "배너관리",
  "Popup Management": "팝업관리",
  "Fifth Section": "Boards",
  "Operations Board": "운영게시판",
  "Announcements": "공지사항",
  "Events": "이벤트",
  "Frequently Asked Questions": "자주 묻는 질문",
  "Main Management": "메인관리",
  "Board Management": "게시판관리",
  "Sixth Section": "Settings",
  "Settings Management": "설정관리",
  "Board Setting": "게시판설정",
  "Version Management": "버전관리",
  "Permission Management": "권한관리",
  "Chat Bot": "챗봇",

  "Seventh Section": "Others",
  "Data Management": "데이터관리",
  "Idiom": "고사성어",
  "Four Idioms": "사자성어",

  "Templates": "Templates",
  "UI Elements": "UI Elements",
  "Forms & Tables": "Forms & Tables",
  "Pages": "Pages",
  "Charts & Maps": "Charts & Maps",
  "Others": "Others",
  "Typography": "Typography",
  "Cards": "Cards",
  "Basic": "Basic",
  "Advance": "Advance",
  "Analytic": "Analytic",
  "Components": "Components",
  "Alert": "Alert",
  "Close Alert": "Close Alert",
  "Avatar": "Avatar",
  "Badge": "Badge",
  "Button": "Button",
  "Calendar": "Calendar",
  "Image": "Image",
  "Pagination": "Pagination",
  "Progress Circular": "Progress Circular",
  "Progress Linear": "Progress Linear",
  "Autocomplete": "Autocomplete",
  "Tooltip": "Tooltip",
  "Slider": "Slider",
  "Date Time Picker": "Date Time Picker",
  "Select": "Select",
  "Switch": "Switch",
  "Checkbox": "Checkbox",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Rating",
  "File Input": "File Input",
  "Otp Input": "Otp Input",
  "Form Layout": "Form Layout",
  "Form Validation": "Form Validation",
  "Charts": "Charts",
  "Apex Chart": "Apex Chart",
  "Chartjs": "Chartjs",
  "Account Settings": "Account Settings",
  "User Profile": "User Profile",
  "FAQ": "FAQ",
  "Dialog Examples": "Dialog Examples",
  "Pricing": "Pricing",
  "List": "List",
  "Edit": "Edit",
  "Nav Levels": "Nav Levels",
  "Level 2.1": "Level 2.1",
  "Level 2.2": "Level 2.2",
  "Level 3.1": "Level 3.1",
  "Level 3.2": "Level 3.2",
  "Raise Support": "Raise Support",
  "Documentation": "Documentation",
  "Analytics": "Analytics",
  "Apps & Pages": "Apps & Pages",
  "Email": "Email",
  "Chat": "Chat",
  "Invoice": "Invoice",
  "Preview": "Preview",
  "Add": "Add",
  "User": "User",
  "View": "View",
  "Login v1": "Login v1",
  "Login v2": "Login v2",
  "Login": "Login",
  "Register v1": "Register v1",
  "Register v2": "Register v2",
  "Register": "Register",
  "Forget Password v1": "Forget Password v1",
  "Forget Password v2": "Forget Password v2",
  "Forgot Password v1": "Forgot Password v1",
  "Forgot Password v2": "Forgot Password v2",
  "Forgot Password": "Forgot Password",
  "Reset Password v1": "Reset Password v1",
  "Reset Password v2": "Reset Password v2",
  "Reset Password": "Reset Password",
  "Miscellaneous": "Miscellaneous",
  "Coming Soon": "Coming Soon",
  "Not Authorized": "Not Authorized",
  "Under Maintenance": "Under Maintenance",
  "Error": "Error",
  "Statistics": "Statistics",
  "Actions": "Actions",
  "Access Control": "Access Control",
  "User Interface": "User Interface",
  "Icons": "Icons",
  "Chip": "Chip",
  "Dialog": "Dialog",
  "Expansion Panel": "Expansion Panel",
  "Combobox": "Combobox",
  "Textfield": "Textfield",
  "Range Slider": "Range Slider",
  "Menu": "Menu",
  "Snackbar": "Snackbar",
  "Tabs": "Tabs",
  "Form Elements": "Form Elements",
  "Form Layouts": "Form Layouts",
  "Authentication": "Authentication",
  "Page Not Found - 404": "Page Not Found - 404",
  "Not Authorized - 401": "Not Authorized - 401",
  "Server Error - 500": "Server Error - 500",
  "2": "2",
  "Gamification": "Gamification",
  "Forms": "Forms",
  "Timeline": "Timeline",
  "Disabled Menu": "Disabled Menu",
  "Help Center": "Help Center",
  "Verify Email": "Verify Email",
  "Verify Email v1": "Verify Email v1",
  "Verify Email v2": "Verify Email v2",
  "Two Steps": "Two Steps",
  "Two Steps v1": "Two Steps v1",
  "Two Steps v2": "Two Steps v2",
  "Custom Input": "Custom Input",
  "Extensions": "Extensions",
  "Tour": "Tour",
  "Register Multi-Steps": "Register Multi-Steps",
  "Wizard Examples": "Wizard Examples",
  "Checkout": "Checkout",
  "Create Deal": "Create Deal",
  "Property Listing": "Property Listing",
  "Roles & Permissions": "Roles & Permissions",
  "Roles": "Roles",
  "Simple Table": "Simple Table",
  "Tables": "Tables",
  "Data Table": "Data Table",
  "Permissions": "Permissions",
  "Apps": "Apps",
  "Misc": "Misc",
  "Wizard Pages": "Wizard Pages",
  "Form Wizard": "Form Wizard",
  "Numbered": "Numbered",
  "Editors": "Editors",
  "Front Pages": "Front Pages",
  "Landing": "Landing",
  "checkout": "checkout",
  "Payment": "Payment",
  "Swiper": "Swiper",
  "Product": "Product",
  "Category": "Category",
  "Order": "Order",
  "Details": "Details",
  "Customer": "Customer",
  "Manage Review": "Manage Review",
  "Referrals": "Referrals",
  "Settings": "Settings",
  "Overview": "Overview",
  "My Course": "My Course",
  "Course Details": "Course Details",
  "Academy": "Academy",
  "Logistics": "Logistics",
  "Dashboard": "Dashboard",
  "Fleet": "Fleet",
  "Widgets": "Widgets",
  "5": "5",
  "10": "10",
  "20": "20",
  "25": "25",
  "50": "50",
  "100": "100",
  "$vuetify": {
    "badge": "Badge",
    "noDataText": "",
    "close": "Close",
    "open": "open",
    "dataFooter": {
      "itemsPerPageText": "Items per page:",
      "itemsPerPageAll": "All",
      "pageText": "{0}-{1} of {2}",
      "firstPage": "First Page",
      "prevPage": "Previous Page",
      "nextPage": "Next Page",
      "lastPage": "Last Page"
    },
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "previous": "previous",
        "next": "next",
        "currentPage": "currentPage",
        "page": "page",
        "first": "first",
        "last": "last"
      }
    },
    "input": {
      "clear": "clear",
      "appendAction": "appendAction",
      "prependAction": "prependAction",
      "counterSize": "counterSize",
      "otp": "otp"
    },
    "fileInput": {
      "counterSize": "counterSize"
    },
    "rating": {
      "ariaLabel": {
        "item": "item"
      }
    }
  }
}
