<script setup>
const props = defineProps({
  contents: Object,
  curIlgan: String,
})
const { ...five } = fiveMixin()
</script>

<template>
    <div class="cont-pages">
        <div class="tit">
            <span class="main-tit ganji-txt" :class="five.getFiveClass(five.getGanjiColorFive(props.curIlgan))">
                 {{ contents.icon }} {{ contents.title }}
            </span>            
        </div>
        <div class="inner-cont">
            <span class="nor-txt">
                {{ contents.body.descriptions[0] }}
            </span>
            <div class="gray-box">
                <span class="gray-tit">
                    🎓 {{contents.body.mainTitle }}
                </span>
                <span v-for="job in contents.body.jobField" class="gray-cont list">
                    {{ job }}
                </span>
            </div>
            <span class="nor-txt">
                {{ contents.body.descriptions[1] }}
            </span>
            <span class="nor-txt">
                {{ contents.body.descriptions[2] }}
            </span>
        </div>
        <span class="bottom-txt">

        </span>
    </div>
</template>