<script>
import {
  AnalysisSajuCard,
  FiveElementDistribution,
  LuckScore,
  SolarTermsChart,
  YinYangHarmony,
} from '@/components/analysis'
</script>
<template>
  <section class="chart-section">
    <VRow>
      <AnalysisSajuCard :birth-data="birthData" :daeuns-data="daeunsData" :lang-mode="langMode" />
      <LuckScore :height="'180px'" />
    </VRow>

    <div class="row-title">Today</div>
    <VRow>
      <FiveElementDistribution :mode="'daily'" />
      <YinYangHarmony :mode="'daily'" />
      <SolarTermsChart />
    </VRow>

    <div class="row-title">My Chart</div>
    <VRow>
      <FiveElementDistribution />
      <YinYangHarmony />
      <BigLuckAnalyze :mode="'daily'" />
      <!-- <TestChart /> -->
      <DayLuckFlow />
      <LuckScore />
      <MBTIDistribution />
      <BigLuckAnalyze />
    </VRow>

    <div class="row-title"></div>
    <!-- <VRow>
      <FiveElementDistribution />
      <BigLuckAnalyze />
      <YinYangHarmony />
      <BigLuckFlow />
      <YearLuckFlow />
      <DayLuckFlow />
    </VRow>
    <VRow>
      <LuckCalendar />
      <SolarTermsChart />
    </VRow>
    <VRow>
      <TestGantt />
    </VRow>
    <VRow>
    </VRow> -->
  </section>
</template>
