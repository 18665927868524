<script setup>
import { defineProps } from 'vue'

import IconClose01 from '@/components/svg/IconClose01.vue'
import IconPrev02 from '@/components/svg/IconPrev02.vue'
import IconNext02 from '@/components/svg/IconNext02.vue'
import IconList from '@/components/svg/IconList.vue'

// props 정의
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  curPage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['updateIndexNo'])

const goPage = (id) => {
  let no = id
  if (id <= 0) no = props.items.length
  if (id > props.items.length) no = 1
  emit('updateIndexNo', no)
}

// 키보드 이벤트 핸들러
const handleKeydown = (event) => {
  if (event.key === 'ArrowLeft') {
    goPage(props.curPage - 1)
  }
  if (event.key === 'ArrowRight') {
    goPage(props.curPage + 1)
  }
}

// 컴포넌트가 마운트될 때 이벤트 리스너 추가
onMounted(() => {
  window.addEventListener('keydown', handleKeydown)
})

// 컴포넌트가 언마운트될 때 이벤트 리스너 제거
onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown)
})

const openFlag = ref('')
const openContentsList = (val) => {
  openFlag.value = val
}

const formatPageNumber = (pageNumber) => {
  return String(pageNumber).padStart(2, '0')
}
</script>

<template>
  <div class="list-box" :class="openFlag">
    <ul class="list-li-box">
      <li class="li" v-for="item in items" :key="item.id" @click="goPage(item.id)">
        {{ item.title }}
      </li>
    </ul>
  </div>
  <div class="list-page-box">
    <IconList @click="openContentsList('open')" v-if="openFlag === ''" />
    <IconClose01 @click="openContentsList('')" v-if="openFlag === 'open'" />
    <span class="no-box">
      <span class="no">{{ formatPageNumber(curPage) }}</span>
      <span> / </span>
      <span class="no">{{ formatPageNumber(items.length) }}</span>
    </span>
    <IconPrev02 @click="goPage(curPage - 1)" />
    <IconNext02 @click="goPage(curPage + 1)" />
  </div>
</template>
