<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })
import useFunctions from '@/composables/useFunctions'
import { useRouter } from 'vue-router'
import { formatCustomDate } from '@/composables/functions'

const router = useRouter()
const { ...useFun } = useFunctions()
const emit = defineEmits()

const props = defineProps({
  prPayOwnList: {
    type: Array,
    required: true,
  },
  tabType: {
    type: String,
    required: true,
  },
})

const goPayView = (orderId, productType) => {
  router.push(
    `/mypage/subscrPaymentView/${orderId}?tabType=${props.tabType}&productType=${productType}`,
  )
}

// 숫자에 천 단위 구분 쉼표를 추가하는 함수
const formatNumber = (num) => {
  if (num < 1000) return num
  return new Intl.NumberFormat().format(num)
}
</script>
<template>
  <div
    class="row-box"
    v-for="(item, index) in prPayOwnList"
    :key="index"
    @click="
      item?.productDetail?.pointEventType !== 'USE'
        ? goPayView(item?.productDetail?.productTypeId, item?.productDetail?.productType)
        : null
    "
  >
    <div class="text-box">
      <div class="date">
        <span v-if="item?.productDetail?.pointEventType === 'USE'"
          >{{ formatCustomDate(item?.productDetail?.createdAt) }}
        </span>
        <span v-else>
          {{ formatCustomDate(item?.approvedAt) }}
        </span>
      </div>
      <div class="title">
        <span
          v-if="
            item?.productDetail?.productType === 'POINT' ||
            item?.productDetail?.pointEventType === 'USE'
          "
        >
          <span v-if="item?.productDetail?.productType === 'POINT'">
            포인트 충전 {{ item?.productName }}
          </span>
          <span v-else>{{ item?.productDetail?.description }}</span>
        </span>
        <span v-else>{{ item?.productName }}</span>
      </div>
      <div v-if="item?.productDetail?.productType === 'POINT'">
        <span v-if="item?.productDetail?.bonusAmount"
          >보너스 포인트 {{ formatNumber(item?.productDetail?.bonusAmount) }} P</span
        >

        <span v-if="item?.productDetail?.expiryDate" class="txt-red">
          유효기간 {{ item?.productDetail?.expiryDate }}
        </span>
      </div>
      <div v-else>
        <div class="date2" v-if="item?.productDetail?.pointEventType !== 'USE'">
          {{ formatCustomDate(item?.productDetail?.startDate) }} ~
          {{ item?.productDetail?.endDate ? formatCustomDate(item?.productDetail?.endDate) : '' }}
        </div>
      </div>
    </div>

    <div class="price">
      <div class="txt">
        <span
          :class="
            item?.orderStatus === 'CANCELED' &&
            (item?.productDetail?.productType === 'POINT' ||
              item?.productDetail?.pointEventType === 'USE')
              ? 'line'
              : ''
          "
        >
          <span
            v-if="
              item?.productDetail?.productType === 'POINT' ||
              item?.productDetail?.pointEventType === 'USE'
            "
          >
            {{ formatNumber(item?.productDetail?.totalAmount) }}
          </span>
          <span v-else>
            {{ formatNumber(item?.totalAmount) }}
          </span>
          {{
            item?.productDetail?.productType === 'POINT' ||
            item?.productDetail?.pointEventType === 'USE'
              ? 'P'
              : '원'
          }}
        </span>
        <span class="txt-red" v-if="item?.orderStatus === 'CANCELED'">취소완료</span>
      </div>
      <i
        class="icon-arrow"
        :class="{ 'st-use': item?.productDetail?.pointEventType === 'USE' }"
      ></i>
    </div>
  </div>
</template>
