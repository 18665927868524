/** GNB > contentType Map */
export const gnbContentTypeMap = {
  // '/main': 'G_home',
  '/calendar': 'G_calendar',
  '/manse': 'G_manse',
  '/saju': 'G_member',
  '/customer/notice': 'G_nangman_center',
  '/guide/info': 'gnb_btn_service',
}

/** 낭만센터 NangmanCenter > ContentType Map */
export const nangmanCenterContentTypeMap = {
  '/customer/notice': 'N_tab_notice',
  '/customer/event': 'N_tab_event',
  '/customer/essay': 'N_tab_essay',
  '/customer/faq': 'N_tab_faq',
  '/customer/word': 'N_tab_guide',
  // '/customer/inquiry': 'N_tab_inquiry',
  // '/customer/counseling': 'N_tab_counseling',
}

/** 사용자메뉴 UserMenu > ContentType Map */
export const userMenuContentTypeMap = {
  '/mypage/myinfo': 'UM_myinfo',
  '/mypage/moreinfo': 'UM_moreinfo',
  '/mypage/inquiry': 'UM_inquiry',
  '/mypage/inquiryList': 'UM_inquiry_list',
};