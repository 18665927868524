<script setup>
import { useStore } from 'vuex'

import { useDeviceDetect } from '@/composables/useDeviceDetect'
import IlganPopIntro from '@/pages/manse/components/IlganPopIntro.vue';
import { getIlganPopup } from '@/api/IlganPop';
import { watch } from 'vue';
import '@/assets/nangman_style/popup/ilgan_description_mobile.scss'
import axios from 'axios';
import IlganPopContent from '@/pages/manse/components/IlganPopContent.vue';
import { DEV_MODE } from '@/main';

definePage({ meta: { layout: 'empty' } });

const store = useStore();

/** 디바이스 감지 */
const { isMobile } = useDeviceDetect();

/** 모바일 사용자 토큰 */
const accessToken = ref(null);
/** 모바일 사용자 ID */
const userId = ref(null);
/** 모바일 사용자로부터 전달받은 ilganInfo */
const ilganInfo = ref({
  char: '갑',
  whichDay: '목'
});
const bottomInset = ref(0);

/** 일간팝업 API 요청 param */
const ilganCode = 
  computed(() => `일간팝업_${ilganInfo.value.char}${ilganInfo.value.whichDay}`);

/** code를 활용하여 정보 요청 */
const ilganDataRaw = ref({});

const ilganData = computed(() => ilganDataRaw.value || {}); 

// 컬러코드 데이터
const colorMap = {
  화: "fifive01",
  수: "fifive02",
  목: "fifive03",
  금: "fifive04",
  토: "fifive05",
};
const fiveElements = computed(() => colorMap[ilganInfo.value.whichDay] || "");

/** 인트로 <-> 컨텐츠 표시 상태값 */
const isContentVisible = ref(false);

onMounted(async () => {
  window.addEventListener('message', handleMessage);
  document.addEventListener('message', handleMessage);
  document.addEventListener('contextmenu', disableRightClick)
});

onBeforeUnmount(() => {
  document.removeEventListener('contextmenu', disableRightClick)
});

onUnmounted(() => {
  window.removeEventListener('message', handleMessage);
  document.removeEventListener('message', handleMessage);
});

watch(() => ilganInfo.value, async (newInfo) => {
  if (newInfo && newInfo.char && newInfo.whichDay) {
    fetchIlgan();
    await nextTick();
    document.addEventListener('contextmenu', disableRightClick)
  }
});

/** 모바일 앱과 통신 */
const handleMessage = async (event) => {
  try {
    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
    } else {
      data = event.data;
    }

    // React DevTools 메시지 무시
    if (data.source === 'react-devtools-content-script') {
      console.log('Ignoring React DevTools message.');
      return;
    }

    // PC 테스트용
    if (data.key === '__VUE_DEVTOOLS_VUE_DETECTED_EVENT__') {
      fetchIlgan();
      return;
    }

    if (data) {
      if (!isMobile && DEV_MODE) {
        accessToken.value = store.getters['user/token'];
        userId.value = store.getters['user/id'];
      } else {
        accessToken.value = data.accessToken;
        userId.value = data.authUserId;
        ilganInfo.value = data.ilganInfo;
        bottomInset.value = data.bottom;

        if (accessToken.value) {
          axios.defaults.headers.common['Authorization'] = accessToken.value;
        }
      }
    }
  } catch (e) {
    console.error('Invalid message received:', `${e}`);
  }
};

/** 마우스 우클릭 방지 */
const disableRightClick = (event) => {
  event.preventDefault()
};

/** 일간팝업 데이터 요청 */
const fetchIlgan = async () => {
  const data = await getIlganPopup(ilganCode.value);
  ilganDataRaw.value = data;
  await nextTick();
}

/** 팝업 닫기 */
const close = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: "closeIlgan" }));
  }
}

/** 콘텐트로 넘어가기 */
const showIlganContent = () =>{
  isContentVisible.value = true;
}

/** 다시보기 */
const showIntro = () =>{
  isContentVisible.value = false;
}

const actionLogin = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: "login" }));
  }
}
</script>

<template>
  <div class="ilgan-inner-cd mobile" :class="fiveElements">
    <button v-if="!isContentVisible" class="dial-close ilganIntro" @click="close"></button>
    <div
      v-if="Object.keys(ilganData).length && !isContentVisible"
      class="pop-detail-body"
    >
      <IlganPopIntro          
        :ilganData="ilganData" 
        :fiveElements="fiveElements"
        :accessToken="accessToken"
        @show-content-page="showIlganContent"
        @login="actionLogin"
      />
    </div>
    <IlganPopContent
      v-if="isContentVisible"
      :ilganData="ilganData"
      @close="close"
      @showIntro="showIntro"
    />
  </div>
</template>