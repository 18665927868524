export const useLogsStore = defineStore('logs', () => {
  /** 로그 컨텐츠 리스트 */
  const logContents = ref(null);

  /** 컨텐츠 로그용 아이디 데이터 저장 */
  const contentLogIds = computed(() => {
    if (!logContents.value) return null;
    return logContents.value.reduce((acc, item, index) => {
      acc[item.contentType] = index + 1; // 1부터 시작하는 인덱스 사용
      return acc;
    }, {});
  });

  /** 로그 컨텐츠 리스트 업데이트 */
  const setLogContents = (logData) => logContents.value = logData;

  return {
    logContents,
    contentLogIds,
    setLogContents,
  }
});