import axios from "axios"
import { clarify } from "@/utils/clear"
import qs from 'qs'

const endpoint = import.meta.env.VITE_APP_GW_URL
const systemId = import.meta.env.VITE_APP_SYSTEMID

export const getArticles = async ({boardId, params}) => {
  const trimmedParams = new URLSearchParams(clarify(params))
  const response = await axios.get(`${endpoint}/board/${boardId}/articles`, {params: trimmedParams})
  return response.data
}

export const getMultiArticles = async ({ params }) => {
  const response = await axios.get(`${endpoint}/board/articles`, {
    params,
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'repeat' })
  })
  return response.data
}

const articleApi = () => {
  const findAll = async (boardId, body) => {
    const params = new URLSearchParams(clarify(body))
    const response = await axios.get(`${endpoint}/boards/${boardId}/articles`, { params })
    return response.data
  }

  const list = async (boardId, body) => {
    const params = new URLSearchParams(clarify(body))
    const response = await axios.get(`${endpoint}/board/${boardId}/articles`, { params })
    return response.data
  }

  const listComment = async (boardId, body) => {
    const params = new URLSearchParams(clarify(body))
    const response = await axios.get(
      `${endpoint}/board/${boardId}/systems/${systemId}/articleCount`,
      { params }
    )
    return response.data
  }

  const findById = async (boardId, id) => {
    const response = await axios.get(`${endpoint}/boards/${boardId}/articles/${id}`)
    return response.data
  }

  const save = async (article) => {
    const response = await axios.post(`${endpoint}/board/${article.boardId}/articles/save`, article)
    return response.data
  }

  const deleteArticle = async (boardId, id) => {
    return await axios.delete(`${endpoint}/board/${boardId}/articles/${id}/delete`)
  }

  const uploadImage = async (boardId, f) => {
    const formData = new FormData()
    formData.append("file", f)
    const response = await axios.post(
      `${endpoint}/boards/${boardId}/article/image/upload`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    return response.data
  }

  const downloadFile = async (boardId, id, fileId) => {
    const response = await axios.get(
      `${endpoint}/boards/${boardId}/articles/${id}/files/${fileId}/download`,
      { responseType: "arraybuffer" }
    )
    return response
  }

  const reaction = async (id, boardId) => {
    const response = await axios.post(`${endpoint}/boards/${boardId}/articles/${id}/reaction`)
    return response
  }

  const get = async (boardId, articleId, body) => {
    const params = new URLSearchParams(clarify(body))
    const response = await axios.get(`${endpoint}/board/${boardId}/articles/${articleId}`, { params })
    return response.data
  }

  
  return {
    findAll,
    list,
    listComment,
    findById,
    save,
    delete: deleteArticle,
    uploadImage,
    downloadFile,
    reaction,
    get,
  }
}

export default articleApi
