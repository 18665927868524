<script setup>
import { nextTick, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import { DEV_MODE } from '@/main';

/** API */
import { getDailyFortuneDetailAPI, purchaseDailyCategoryAPI, purchaseDailyFortuneAPI, getDailyCategoryFreeCountAPI, getDailyFortuneCheckAPI } from '@/api/calendarApi';
import { addContentLogAPI } from '@/api/logServiceApi';

/** pinia */
import { usePaymentsStore } from '@/store/modules/payments';

/** composables */
import fiveMixin from '@/composables/fiveMixin';
import useFunctions from '@/composables/useFunctions';
import { useCalendar, useCalendarFilter, useGesture } from '@/composables';
import { useSnackbar } from '@/composables/useSnackbar';
import { useScrollLock } from '@/composables/useScrollLock';
import Snackbar from '@/components/common/Snackbar.vue';
import { useDailyFortune } from '@/composables/calendar/useDailyFortune';
import { useMySaju } from '@/composables/calendar/useMySaju';
import { useServicePrice } from '@/composables/calendar/useServicePrice';
import { usePurchaseFortune } from '@/composables/calendar/usePurchaseFortune';

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3';
import SubscribeInfo from '@/components/calendar/SubscriptionInfo.vue';
import DailyFortuneDrawer from '@/components/calendar/drawer/DailyFortuneDrawer.vue';
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue';
import CalendarSidebar from '@/components/calendar/CalendarSidebar.vue';
import CalendarHeader from '@/components/calendar/CalendarHeader.vue';
import PopMySaju from '@/components/calendar/PopMySaju.vue';
import AlertComponent from '@/pages/manse/components/Alert.vue';
import ConfirmComponent from '@/pages/manse/components/Confirm.vue';
import PointConfirm from '@/components/calendar/PointConfirm.vue';
import LoadingIndicator from '@/components/common/LoadingIndicator.vue';
import IconCalendarFill from '@/assets/icon/IconCalendarFill.vue';

/** 스타일 */
import '@/assets/nangman_style/calendar/calendar_web.scss';
import { getDayElementCount } from '@/composables/useSajuFunction';

definePage({ meta: { layout: 'manse' } })

const store = useStore()
const userId = ref(null)
userId.value = store.getters['user/id']

const paymentsStore = usePaymentsStore();

const route = useRoute();
const router = useRouter();

const { getYinYang, getSkyStemFive, getEarthBranchFive, getColorFive, getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { formatNumber } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);

const isDailyFortuneLoading = ref(false);

const isDailyFortuneLoaded = ref(false);

/** 생활운세 차트 데이터 */
const lifeLuckChartData = ref({});

const isCategoryLoading = ref(false);
const isCategoryLoaded = ref(false);
const isCategoryRecommendLoading = ref(false);

/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlertRef = ref(null);
/** confirmAlert 참조 */
const dialogConfirmRef = ref(null);
/** 포인트 결제 Alert 참조 */
const pointConfirmRef = ref(null);
const dialogTitle = ref('');
const dialogTitle2 = ref('');
const dialogSubtitle = ref('');
const dialogText = ref('');
const dialogNextUrl = ref(null);

/** 일진달력 선택 여부 - true: 일진달력, false: 역학달력 */
const isDailyFortuneCalendarOpen = ref(false)

/** 일진보기 drawer 열림 상태 */
const isDailyFortuneDrawerOpen = ref(false);

/** 등록된 '내 사주' 데이터 */
const sajuData = ref({});
/** 내 사주 정보 유무 확인 */
const isSajuDataExist = computed(() => Object.keys(sajuData.value).length !== 0);

const selectedDateInfo = ref(null)
const dailyFortuneData = ref(null);

const errorMessage = ref('');

/** 날짜 클릭 시 이너뷰 열기 */
const openDailyFortuneDrawer = async ({info, actionType}) => {
  if (info === null) return;
  if (!userId.value) return;

  const date = actionType === 'opened' ? info.day : info.date;
  const dataKey = moment(date).format('YYYYMMDD');
  const activeDate = moment(date).format('YYYY-MM-DD');
  dailyFortuneData.value = monthlyFortuneData.value[dataKey];

  const dailyStatus = monthlyFortuneStatus.value[dataKey];
  const isToday = todayDate.value === activeDate;

  let contentLogParams = {
    contentType: '',
    option3: '',
  };

  if (dailyStatus) {
    if (isToday && !dailyStatus.is_viewed_today) {
      contentLogParams.contentType = 'fc_day_today_opt3';
        if (dailyStatus.is_paid) contentLogParams.option3 = '유료';
        else contentLogParams.option3 = '무료';
    } else if (dailyStatus && !dailyStatus.is_checked) {
      if (!isToday && dailyStatus.is_active) {
        contentLogParams.contentType = 'fc_day_not_today_opt3';
        if (dailyStatus.is_paid) contentLogParams.option3 = '유료';
        else contentLogParams.option3 = '무료';
      } 
    }
    addContentLogAPI(contentLogParams);
    // 로그 전송 후 날짜별 status 데이터를 업데이트 받아야 함.
  }

  if (info) selectedDateInfo.value = date;
  if (isDailyFortuneDrawerOpen) {
    try {      
      if (actionType === 'opened') {
        isDailyFortuneDrawerOpen.value = true;
        getDailyCategoryFreeCount(activeDate);
        await getDailyFortuneDetail({activeDate});
        updateDailyFortuneCalendarStatus(moment(date).format('YYYYMM'));
        if (dailyCategoryStatus.value && dailyCategoryStatus.value.재물운 && !dailyCategoryStatus.value?.재물운?.is_checked) {
          addContentLogAPI({
            contentType: 'fc_luck_category_free_opt3',
            option3: '재물운',
          });
        }
        await getDailyCategoryDetail(activeDate, '재물운');
      } else if (actionType !== 'past') {
        calendarApi.value.render();
      } else {
        showPointConfirm({
          title: moment(date).format('YYYY년 MM월 DD일'),
          subtitle: '운세를 구매 하시겠습니까?',
          point: formatNumber(dailyFortunePrice.value),
          confirmText: '구매',
          onConfirm: async () => await confirmPurchaseFortune({activeDate, dataKey}),
        })
      }
    } catch (e) {
      
    }
  }
};

const updateDailyFortuneCalendarStatus = async (yyyymm) => {
  const totalStatus = await getDailyFortuneCheckAPI();

  monthlyFortuneStatus.value = Object.fromEntries(
    Object.entries(totalStatus).filter(([key]) => key.startsWith(yyyymm))
  );

  calendarApi.value.render();
}

/** 날짜별 무료 열람 가능한 카테고리 갯수 요청 */
const getDailyCategoryFreeCount = async (activeDate) => {
  const params = {
    activeDate,
  }

  try {
    const result = await getDailyCategoryFreeCountAPI(params);
    freeCategoryCount.value = result;
  } catch (e) {
    console.error('카테고리 별 운세(생활운세) 무료 카운트 조회 실패:', e);
  }
}

/** 일운보기 상세 데이터 요청 */
const getDailyFortuneDetail = async ({activeDate, displayLoading = true}) => {
  if (displayLoading) {
    isDailyFortuneLoading.value = true;
    isDailyFortuneLoaded.value = false;
  }

  const params = {
    activeDate,
  };

  try {
    const result = await getDailyFortuneDetailAPI(params);
    
    if (result) {
      dailyFortuneDetail.value = result.data;
      dailyCategoryStatus.value = result.status;
      if (displayLoading) {
        isDailyFortuneLoaded.value = true;
      }
    } else {
      if (displayLoading) {
        isDailyFortuneLoaded.value = false;
      }
    }
  } catch (e) {
    DEV_MODE && console.error('일운보기 상세 데이터 조회 실패:', e);
    errorMessage.value = e?.response?.data.detail || e;
    if (displayLoading) {
      isDailyFortuneLoaded.value = false;
    }
  } finally {
    if (displayLoading) {
      isDailyFortuneLoading.value = false;
    }
    isCategoryLoading.value = false;
  }
}

/** 일진보기 drawer 닫기 */
const closeDailyFortuneDrawer = () => {
  if (isDailyFortuneDrawerOpen.value) {
    resetDrawerStatus();
    // nextTick(() => resetDailyFortuneData());
  }
};

const resetDrawerStatus = () => {
  isDailyFortuneDrawerOpen.value = false;
  lifeLuckChartData.value = {};
}

const displayPoint = ref('');
const showPointConfirm = ({title, subtitle, point, confirmText, onConfirm = () => {}}) => {
  dialogTitle.value = title;
  dialogSubtitle.value = subtitle;
  dialogText.value = confirmText;
  displayPoint.value = point;
  dialogNextUrl.value = onConfirm;
  pointConfirmRef.value.openConfirmDialog();
}

/** 확인 알림창 표시 */
const showDialogAlert = ({title, text = '', nextUrl = ''}) => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlertRef.value.openAlertDialog();
};

/** 확인/취소 알림창 표시 */
const showDialogConfirm = ({title, title2 = '', text = '', onConfirm = () => {}}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달
  dialogConfirmRef.value.openConfirmDialog()
};

/** 로그인 알림창 표시 */
const showLoginConfirm = () => {
  showDialogConfirm({
    title: '로그인 하시면',
    title2: '일진을 보실 수 있습니다.',
    text: '로그인 하러 가기',
    onConfirm: () => router.push({
      path: '/auth/login',
      query: { to: route.fullPath}
    }),
  });
};

/** 포인트 충전 팝업 */
const showRechargeConfirm = () => {
  showDialogConfirm({
    title: '보유 낭만 포인트가 부족합니다.',
    title2: '지금 바로 충전하시겠습니까?',
    text: '충전하기',
    onConfirm: () => router.push('/mypage/subscrPoint'),
  });
}

/** 내 사주 등록 알림창 표시 */
const showSelectConfirm = () => {
  showDialogConfirm({
    title: '내 사주 등록 후,',
    title2: '일진을 보실 수 있습니다.',
    text: '내 사주 등록',
    onConfirm: () => openPopMySaju()
  });
};

/** 일진달력 가격 조회 */
const {
  dailyFortunePrice,
  categoryPrice,
  monthlyFortunePrice,
  getDailyFortunePrice,
} = useServicePrice();

/** 일진달력 결제 관리 */
const {
  isPurchasePending,
  handlePurchaseError,
} = usePurchaseFortune({
  showRechargeConfirm,
});

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  toggleOptions,
} = useCalendarFilter({
  isKorCheckedDefault: true,
  isDailyFortuneCalendarOpen,
});

/** 달력 생성 */
const {
  calendarApi,
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  selectedData,
  firstSeason,
  firstSeasonMonth,
  monthlyFortuneData,
  monthlyFortuneStatus,
  monthlyFortuneStatusTotal,
  todayDate,
  prev,
  next,
  isToday,
  changeViewTitle,
  setPrevSelectedDate,
  getDailyFortuneCalendar,
} = useCalendar({
  isLoading,
  isLunarChecked,
  isColorChecked,
  isSeasonChecked,
  isKorChecked,
  isHolidayChecked,
  calendarType: 'web',
  isDailyFortuneCalendarOpen,
  userId,
  isSajuDataExist,
  dailyFortunePrice,
  openDailyFortuneDrawer,
  showDialogAlert,
  showLoginConfirm,
  showSelectConfirm,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

/** '내 사주' 등록/관리 로직 */
const {
  isMySajuLoading,
  isPopMySajuOpen,
  mySajuList,
  selectedMySajuId,
  getMySaju,
  openPopMySaju,
  closePopMySaju,
  selectMySaju,
  confirmSelectSaju,
  goManse,
} = useMySaju({
  calendarApi,
  userId,
  sajuData,
  viewTitle,
  getDailyFortuneCalendar,
  showDialogConfirm,
  showLoginConfirm,
});

const purchaseDailyFortune = async ({activeDate, purchaseType, callback = async () => {}}) => {
  isPurchasePending.value = true;

  const [year, month, day] = activeDate.split('-');
  const params = {
    year, // 필수
    month,  // 필수
    purchaseType, // "DAY", "MONTH" (필수)
    ...(purchaseType === "DAY" && { day }), // purchaseType: "DAY" 일 때
  }

  try {
    const result = await purchaseDailyFortuneAPI(params);    

    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('구매가 완료되었습니다.');
      await getDailyFortuneCalendar(`${year} ${month}`);
      await callback();
      calendarApi.value.render();
      if (purchaseType === 'MONTH') {
        paymentsStore.tempAddPurchaseList(viewTitle.value);
      }
    }
  } catch (e) {
    console.error('결제 실패:', e.response.data.detail);
    handlePurchaseError(e);
  } finally {
    isPurchasePending.value = false;
  }
};

/** 달 전체 일진 구매 */
const purchaseMonthly = () => {
  // 로그인 체크
  if (!userId.value) {
    showLoginConfirm();
    return;
  }
  // 내 사주 체크
  if (!isSajuDataExist.value) {
    showSelectConfirm();
    return;
  }

  const currentDate = refCalendar.value.calendar.currentData.currentDate;
  
  showPointConfirm({
    title: moment(currentDate).format('YYYY년 MM월'),
    subtitle: '해당 월의 일진 전체를 구매 하시겠습니까?',
    point: formatNumber(monthlyFortunePrice.value),
    confirmText: '구매',
    onConfirm: async () => {          
      await purchaseDailyFortune({
        activeDate: moment(currentDate).format('YYYY-MM-DD'),
        purchaseType: 'MONTH',
      });
    },
  })
}

/** '카테고리 별 운세(생활운세)' 구매 */
const purchaseDailyCategory = async ({activeDate, category, categoryPrice}) => {
  isPurchasePending.value = true;

  const params = {
    activeDate,  // yyyy-mm-dd
    category, // "STUDY", "WORK", "WEALTH", "LOVE", "HEALTH", "FAMILY", "MOVE", "RELATIONSHIP", "FAME", "SOCIAL"
    categoryPrice,  // 차감 포인트
  }

  try {
    const result = await purchaseDailyCategoryAPI(params);    
    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('운세가 구매되었습니다.');
      await getDailyCategoryDetail(activeDate, category);
      setSelectedCategory(category);
      addContentLogAPI({
        contentType: 'fc_luck_category_paid_opt3',
        option3: category,
      })
    }
  } catch (e) {
    DEV_MODE && console.error('카테고리 별 운세(생활운세) 구매 실패', e);
    handlePurchaseError(e);
  } finally {
    isPurchasePending.value = false;
  }
}

/** 일운보기 결제 */
const confirmPurchaseFortune = async ({activeDate, dataKey}) => {  
  // 결제 로직
  await purchaseDailyFortune({
    activeDate,
    purchaseType: 'DAY',
    callback: async () => {
      openDailyFortuneDrawer({info: {day: activeDate}, actionType: 'opened'});
      addContentLogAPI({
        contentType: 'fc_day_not_today_opt3',
        option3: '유료',
      });
    },
  });
}

/** 일운보기 상세 화면 */
const {
  freeCategoryCount,
  dailyFortuneDetail,
  dailyCategoryStatus,
  luckButtonDisabled,
  selectedCategoryList,
  selectedCategory,
  dailyCategoryDetail,
  dailyCategoryRecommend,
  // clickCategoryButton,
  setSelectedCategory,
  getDailyCategoryDetail,
  getDailyCategoryStatus,
  getDailyCategoryRecommend,
  resetDailyFortuneData,
} = useDailyFortune({
  lifeLuckChartData,
  isCategoryLoading,
  isCategoryLoaded,
  isCategoryRecommendLoading,
});

/** '카테고리 별 운세(생활운세)' 버튼 클릭 */
const clickCategoryButton = async (label) => {
  const formattedSelectedDate = moment(selectedDateInfo.value).format('YYYY-MM-DD');

  if (dailyCategoryStatus.value && dailyCategoryStatus.value[label] && !dailyCategoryStatus.value[label].is_checked) {
    let logParams = {
      contentType: '',
      option3: label,
    };
    if (dailyCategoryStatus.value[label].is_active && dailyCategoryStatus.value[label].is_paid) {
      logParams.contentType = 'fc_luck_category_paid_opt3';
    } else if (dailyCategoryStatus.value[label].is_active && !dailyCategoryStatus.value[label].is_paid) {
      logParams.contentType = 'fc_luck_category_free_opt3';
    } else if (!dailyCategoryStatus.value[label].is_active && !dailyCategoryStatus.value[label].is_paid && selectedCategoryList.value.length < freeCategoryCount.value) {
      logParams.contentType = 'fc_luck_category_free_opt3';
    }
    addContentLogAPI(logParams);
  }


  if (dailyCategoryStatus.value && dailyCategoryStatus.value[label]['is_active']) {
    // 열어본 운세일 때,
    dailyCategoryRecommend.value = null;
    await getDailyCategoryDetail(formattedSelectedDate, label);
  } else {
    // 열어보지 않은 운세일 때,
    if (selectedCategoryList.value.length < freeCategoryCount.value) {
      // 무료 열람일 때,
      dailyCategoryRecommend.value = null;
      await getDailyCategoryDetail(formattedSelectedDate, label);
    } else {
      // 무료 열람가능 개수를 넘었을 때,
      showPointConfirm({
        title: label,
        subtitle: '운세를 구매 하시겠습니까?',
        point: formatNumber(categoryPrice.value),
        confirmText: '구매',
        onConfirm: async () => {          
          await purchaseDailyCategory({
            activeDate: formattedSelectedDate,
            category: label,
            categoryPrice: categoryPrice.value
          });
        },
      })
    }
  }
};

/** 열려있는 팝업/모달 상태 확인 */
const isAnyPopupOpen = computed(() => 
  isPopSajuClock.value
  || isPopMySajuOpen.value
  || isDailyFortuneDrawerOpen.value
  || isLoading.value
  || (dialogAlertRef.value?.isAlertDialogVisible ?? false)
  || (dialogConfirmRef.value?.isConfirmDialogVisible ?? false)
  || (pointConfirmRef.value?.isConfirmDialogVisible ?? false)
);

/** 스크롤 제어 */
const {} = useScrollLock({activeScrollLock: isAnyPopupOpen});

/** 스낵바 */
const { snackbarRef, triggerSnackbar } = useSnackbar();

onMounted(async () => {
  if (DEV_MODE) {

    getDailyFortunePrice();
    if (userId.value) {
      paymentsStore.getSubscription();
      paymentsStore.getOwnPoint();
    }
    
  } else {
    router.push('/main')
  }
});

watch(() => isDailyFortuneDrawerOpen.value, (isOpen) => {
  if (!isOpen) {
    // 일운 보기 닫을 때 초기화
    setSelectedCategory(null);
  }
});

const clickPurchaseMonthlyFortuneTab = async () => {
  if (!monthlyFortuneStatusTotal.value) return;

  // 월 전체 일진 구매했는지 체크 필요
  if (!monthlyFortuneStatusTotal.value.is_active) purchaseMonthly();

  // 구매 후 업데이트하기
}

/** 일운 조회 */
const openDailyFortune = () => {
  // 일진달력 클릭했을 때, 내 사주 정보 확인
  if (userId.value && !isSajuDataExist.value) {
    getMySaju();
  }
  isDailyFortuneCalendarOpen.value = true;
}

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date());
  setPrevSelectedDate();
  selectedDate.value = moment().format('YYYY-MM-DD');
  changeViewTitle();
  addContentLogAPI('C_btn_today');
}
/** 사주시계 팝업 상태관리 */
const setSajuClock = (state) => {
  if (state) addContentLogAPI('C_saju_watch');
  isPopSajuClock.value = state;
}

/** 일운보기 상세 기본 형식 */
const DEFAULT_DAILY_FORTUNE_DETAIL = {
  iljuSky: '',
  iljuEarth: '',
  iljuSkyKor: '',
  iljuEarthKor: '',
  iljuSkyClass: '',
  iljuEarthClass: '',
  iljuSkyFiveChn: '',
  iljuSkyFiveKor: '',
  iljuEarthFiveChn: '',
  iljuEarthFiveKor: '',
  iljuSkyYinyang: '',
  iljuEarthYinyang: '',
  emojiIndex: 'a',
  오늘날짜: '',
  갑자의미: '',
  점수: '',
  확률: '',
  나의음양오행: {
    elementCount: { 목: 0, 화: 0, 토: 0, 금: 0, 수: 0 },
    yinCount: 0,
    yangCount: 0,
  },
  오늘음양오행: {
    elementCount: { 목: 0, 화: 0, 토: 0, 금: 0, 수: 0 },
    yinCount: 0,
    yangCount: 0,
  },
};

const getLevel = (value) => {        
  if (value === 100) return 6;
  if (value >= 80) return 5;
  if (value >= 60) return 4;
  if (value >= 40) return 3;
  if (value > 20) return 2;
  return 1;
}

/** 일운보기 상세 데이터 객체 */
const dailyCustomObject = computed(() => {
  if (!dailyFortuneDetail.value || !selectedData.value) return DEFAULT_DAILY_FORTUNE_DETAIL;

  const detail = dailyFortuneDetail.value;
  const [iljuSky, iljuEarth] = selectedData.value?.dayju.split('') || ['', ''];
  const [iljuSkyKor, iljuEarthKor] = selectedData.value?.handayju.split('') || ['', ''];
  
  const object = {
    iljuSky: iljuSky || '',
    iljuEarth: iljuEarth || '',
    iljuSkyKor: iljuSkyKor || '',
    iljuEarthKor: iljuEarthKor || '',
    iljuSkyClass: iljuSky ? getColorFive(iljuSky) : '',
    iljuEarthClass: iljuEarth ? getColorFive(iljuEarth) : '',
    iljuSkyFiveChn: iljuSky ? getSkyStemFive(iljuSky).chn : '',
    iljuSkyFiveKor: iljuSky ? getSkyStemFive(iljuSky).kor : '',
    iljuEarthFiveChn: iljuEarth ? getEarthBranchFive(iljuEarth).chn : '',
    iljuEarthFiveKor: iljuEarth ? getEarthBranchFive(iljuEarth).kor : '',
    iljuSkyYinyang: iljuSky ? getYinYang(iljuSky, 'sky') : '',
    iljuEarthYinyang: iljuEarth ? getYinYang(iljuEarth, 'earth') : '',
    emojiIndex: selectedData.value.emojiIndex,
    오늘날짜: selectedData.value.day, // yyyy-mm-dd
    갑자의미: detail['갑자의미'],
    확률: detail['일운종합']['일운']['달성확률'],
    점수: getLevel(detail['일운종합']['일운']['달성확률']),
    나의음양오행: {
      elementCount: detail.음양오행통계.오행갯수,
      yangCount: detail.음양오행통계.음양갯수['p_value'] - detail.음양오행통계.음양갯수['dl_p_value'],
      yinCount: detail.음양오행통계.음양갯수['m_value'] - detail.음양오행통계.음양갯수['dl_m_value']
    },
    오늘음양오행: {
      elementCount: getDayElementCount(detail.사주정보.today.cg.split('(')[0]),
      yangCount: detail.음양오행통계.음양갯수['dl_p_value'],
      yinCount: detail.음양오행통계.음양갯수['dl_m_value']
    },
  };

  return object;
});
</script>

<template>
  <div class="calendar-wrap v2">
    <SubscribeInfo :userId="userId"/>

    <DailyFortuneDrawer
      v-show="isDailyFortuneDrawerOpen"
      v-model:isDailyFortuneDrawerOpen="isDailyFortuneDrawerOpen"
      :selectedData="selectedData"
      :dailyFortuneData="dailyFortuneData"
      :dailyFortuneDetail="dailyFortuneDetail"
      :dailyCategoryDetail="dailyCategoryDetail"
      :dailyCategoryRecommend="dailyCategoryRecommend"
      :dailyCustomObject="dailyCustomObject"
      :sajuData="sajuData"
      :lifeLuckChartData="lifeLuckChartData"
      :dailyCategoryStatus="dailyCategoryStatus"
      :isDailyFortuneLoading="isDailyFortuneLoading"
      :isDailyFortuneLoaded="isDailyFortuneLoaded"
      :isCategoryLoading="isCategoryLoading"
      :isCategoryLoaded="isCategoryLoaded"
      :isCategoryRecommendLoading="isCategoryRecommendLoading"
      :errorMessage="errorMessage"
      :selectedCategoryList="selectedCategoryList"
      :selectedCategory="selectedCategory"
      :freeCategoryCount="freeCategoryCount"
      :categoryPrice="categoryPrice"
      :luckButtonDisabled="luckButtonDisabled"
      @clickCategoryButton="clickCategoryButton"
      @closeDrawer="closeDailyFortuneDrawer"
      @getDailyCategoryRecommend="getDailyCategoryRecommend"
      @resetDrawerStatus="resetDrawerStatus"
    />

    <div class="content-main mb-large">
      <section class="inner-base-calendar v2">
        <div style="height: 20px"></div>
        <div class="calendar-sidebar-layout">
          <CalendarSidebar
            :isLoading="isMySajuLoading"
            :isDailyFortuneCalendarOpen="isDailyFortuneCalendarOpen"
            :toggleOptions="toggleOptions"
            :sajuData="sajuData"
            :monthlyPrice="monthlyFortunePrice"
            @openDailyFortune="openDailyFortune"
            @closeDailyFortune="isDailyFortuneCalendarOpen = false"
            @openPopMySaju="openPopMySaju"
            @purchaseMonthly="purchaseMonthly"
          />

          <div class="calendar-main">
            <CalendarHeader
              :calendarType="'v2'"
              :title="viewTitle"
              :isToday="isToday()"
              :solarTerm="firstSeason"
              :solarTermMonth="firstSeasonMonth"
              :isColorChecked="isColorChecked"
              @prev="prev"
              @next="next"
              @today="today"
              @clock="setSajuClock(true)"
            />

            <div class="sec-sch-body">
              <v-row
                class="fill-height"
                @mousedown="handleStart"
                @mousemove="handleMove"
                @mouseup="handleEnd"
                @touchstart="handleStart"
                @touchmove="handleMove"
                @touchend="handleEnd"
              >
                <v-col>
                  <v-sheet height="700" style="position: relative;">
                    <button
                      v-if="isDailyFortuneCalendarOpen"
                      class="purchase-tab"
                      :class="{ active: monthlyFortuneStatusTotal?.is_active }"
                      :disabled="monthlyFortuneStatusTotal?.is_active"
                      @click="clickPurchaseMonthlyFortuneTab"
                    >
                      <IconCalendarFill class="icon-calendar"/>
                      {{ monthlyFortuneStatusTotal?.is_active ? `${viewTitle?.split('년 ')[1]} 전체 열람 가능` : `${viewTitle?.split('년 ')[1]} 전체 일진 구매 `}}
                      <i class="purchase-tab__status-icon"></i>
                    </button>
                    <FullCalendar
                      ref="refCalendar"
                      class="full-calendar web"
                      :options="calendarOptions"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </section>
    </div>

    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />

    <PopMySaju
      :visible="isPopMySajuOpen"
      :mySajuList="mySajuList"
      :selectedMySajuId="selectedMySajuId"
      @close="closePopMySaju"
      @route="goManse"
      @select="selectMySaju"
      @confirm="confirmSelectSaju"
    />

    <AlertComponent
      ref="dialogAlertRef"
      :title="dialogTitle"
      :text="dialogText"
    />

    <ConfirmComponent
      ref="dialogConfirmRef"
      :title="dialogTitle"
      :title2="dialogTitle2"
      :text="dialogText"
      :nextUrl="dialogNextUrl"
    />

    <PointConfirm
      ref="pointConfirmRef"
      :title="dialogTitle"
      :subtitle="dialogSubtitle"
      :point="displayPoint"
      :nextUrl="dialogNextUrl"
      :confirm-text="'구매'"
    />
    
    <Snackbar ref="snackbarRef" />

    <LoadingIndicator v-if="isLoading"/>
  </div>
</template>