<script setup>
import '@/assets/nangman_style/popup/PopFiveStar.scss';

const emit = defineEmits(['close']);

const popFiveStarRef = ref(true);

const currentPage = ref(0);

const close = () => emit('close');

const clickStart = () => {
  currentPage.value = 1;
}

const clickPrevPage = () => {
  if (currentPage.value < 1) return;
  else currentPage.value -= 1;
}

const clickNextPage = () => {
  if (currentPage.value > 1) return;
  else currentPage.value += 1;
}

const onSwipeLeft = () => {
  if (currentPage.value === 0 || currentPage.value > 1) return;
  else currentPage.value += 1;
}

const onSwipeRight = () => {
  if (currentPage.value < 1) return;
  else currentPage.value -= 1;
}

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: onSwipeLeft,  // 왼쪽 스와이프 → 다음 페이지 이동
  onSwipeRight: onSwipeRight, // 오른쪽 스와이프 → 이전 페이지 이동
  SWIPE_THRESHOLD: 150,
});

watch(popFiveStarRef, (newState) => {
  if (!newState) close();
});
</script>

<template>
  <VDialog
    v-model="popFiveStarRef"
    width="740px"
    height="734px"
    max-width="100%"
    class="no-drag"
  >
    <div
      class="pop-five-star-container"
      @mousedown="handleStart"
      @mousemove="handleMove"
      @mouseup="handleEnd"
      @touchstart="handleStart"
      @touchmove="handleMove"
      @touchend="handleEnd"  
    >
      <VCard v-if="currentPage === 0" class="br16 pop-five-star-inner option">
        <div class="pop-header__large">
          <button @click="close"><i class="pop-header__close"></i></button>
          <div class="pop-header__content">
            <div class="pop-header__icon">⛄️️</div>
            <div class="pop-header__text">
              <div class="pop-header__title">동지/입춘</div>
              <div class="pop-header__subtitle">어떻게 활용해야 할까요?</div>
            </div>
          </div>
        </div>
        <div class="pop-body option-intro">
          <div class="pop-body__inner option-intro">
            <div class="pop-body__title"><div class="icon option">👀</div>동지/입춘이란?</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                동지/입춘 선택은 사주팔자의 첫 기둥인 년주를 선택하는 기준 방식을 뜻합니다.
              </div>
              <div class="pop-body__desc">
                子月(자월)로 시작하는 동지를 기준으로 한해가 시작된다고 하는 이론과<br/>
                寅月(인월)로 시작하는 입춘을 기준으로 한해가 시작된다고 하는 이론이 있습니다.
              </div>
              <div class="pop-body__desc">
                이처럼 동지와 입춘의 선택에 따라 사주의 년주가 달라지게 됩니다.
              </div>
              <div class="pop-body__desc">
                동지와 입춘 적용은 <span class="underlined-txt primary">12월 중순 이후 동지 절기와 2월 초순 사이 입춘 절기 사이</span><br/>
                <span class="underlined-txt primary">겨울에 태어나신 분들의 경우</span>가 해당됩니다.
              </div>
            </div>
            
          </div>
          <button class="start-button" @click="clickStart">바로 시작하기</button>
          <div class="pop-footer">
            "사주명리학은 운명을 결정짓는 것이 아니라, 자신을 알고 세상을 바라보는 지혜를 길러주는 길입니다.<br>
            내가 가진 천간의 기운 속에서 숨겨진 가능성을 발견하고, 삶의 흐름 속에서 조화롭게 펼쳐가시길 바랍니다."
          </div>
        </div>
      </VCard>

      <VCard v-else="currentPage !== 0" class="br16 pop-five-star-inner option">
        <div class="pop-header">
          <button @click="close"><i class="pop-header__close"></i></button>
          <div class="pop-header__content">
            <div class="pop-header__icon">⛄️️</div>
            <div class="pop-header__text">
              <div class="pop-header__title">동지/입춘</div>
              <div class="pop-header__subtitle">어떻게 활용해야 할까요?</div>
            </div>
          </div>
        </div>

        <div v-if="currentPage === 1" class="pop-body">
          <div class="pop-body__inner">
            <div class="pop-body__title"><div class="icon option">❄️</div>동지를 적용할 경우</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                동지 절기는 보통 매년 12월 21일 ~ 22일에 들어옵니다.
              </div>
              <div class="pop-body__desc">
                이 날을 기점으로 양(陽)의 기운이 살아나는 전환점으로 여겨지는 중요한 절기 중 하나로<br/>
                새로운 변화, 전환, 생명의 기운이 시작되는 시기로 해석하고 있습니다.
              </div>
              <div class="pop-body__desc">
                동지가 들어오는 절입시각부터 새로운 한 해가 시작된다고 보는 이론으로<br/>
                첫 절기가 동지 즉, 자월(子月)로부터 시작되므로 동지가 한해의 시작점이라고 간명하는 경우에<br/>
                해당합니다.
              </div>
              <div class="pop-body__desc">
                동지를 선택할 경우 <b>‘동지절입 시 이후’</b> 출생자 분들은 <span class="underlined-txt">새로운 한해를 맞았다는 기준</span>이 적용됩니다.<br/>
                동지가 들어온 정확한 시분까지 체크되며, 절입시간 이전 출생자분들은 해당되지 않고 이후 출생한 분들만 적용됩니다.
              </div>
              <div class="pop-card__list option">
              <div class="pop-card option">
                <div class="pop-card__icon">✅</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    2024년 12월 21일 19시 출생자가 동지 기준을 적용한 경우
                  </div>
                  <div class="pop-card__desc">
                    <span class="colored-txt primary">동지절입 시각 이후 출생</span>하였으므로,<br/>
                    년주는 2024년의 간지인 갑진(甲辰)이 적용되지 않고, <span class="colored-txt primary">2025년의 간지인 을사(乙巳)</span>가 적용됩니다.
                    <div class="pop-body__footnote">
                      (2024년도 동지절입 : 12월 21일 18시 19분)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pop-body__desc">
              아직 해는 바뀌지 않았지만 동지를 기점으로 새로운 한해가 시작되었다는 기준을 적용했기 때문입니다.
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage === 2" class="pop-body">
          <div class="pop-body__inner">
            <div class="pop-body__title"><div class="icon option">🌷</div>입춘을 적용할 경우</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                입춘은 24절기 중 첫번째 절기로 봄이 시작되는 시점을 의미하며, 매년 2월 4일 전후에 해당합니다.
              </div>
              <div class="pop-body__desc">
                입춘대길을 대문이나 기둥에 붙여 한해의 행운과 번영을 기원하는 전통이 있었습니다.
              </div>
              <div class="pop-body__desc">
                한해의 시작점은 2월, 입춘이라고 보는 이론으로 입춘 절입시간이 되어야 비로서 새로운 한해를<br/>
                맞이했다고 적용하는 경우에 해당합니다.
              </div>
              <div class="pop-body__desc">
                입춘 기준을 선택할 경우 <b>‘입춘절입 시 이전’</b> 출생자 분들은 <span class="underlined-txt">전년도 간지</span>가 년주로 유지되고, <br/>
                <b>‘입춘절입 시 이후’</b> 출생자 분들은 <span class="underlined-txt">새로운 한해의 간지</span>가 년주로 적용됩니다.
              </div>
              <div class="pop-card__list option">
              <div class="pop-card option">
                <div class="pop-card__icon">✅</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    2024년 12월 21일 19시 출생자가 입춘 기준을 적용한 경우
                  </div>
                  <div class="pop-card__desc">
                    <span class="colored-txt primary">입춘절입 시각 이전 출생</span>하였으므로 년주는 <span class="colored-txt primary">2024년도의 간지를 적용하여 갑진(甲辰)</span>이 됩니다.
                    <div class="pop-body__footnote">
                      (2025년도 입춘절입 :  2월 3일 23시 09분)
                    </div>
                  </div>
                </div>
              </div>
              <div class="pop-card option">
                <div class="pop-card__icon">✅</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    2025년 1월 1일 11시 출생자가 입춘 기준을 적용한 경우
                  </div>
                  <div class="pop-card__desc">
                    <span class="colored-txt primary">입춘절입 시각 이전 출생</span>하였으므로 년주는 <span class="colored-txt primary">2024년도의 간지를 적용하여 갑진(甲辰)</span>이 됩니다.<br/>
                    해가 바뀌었지만 입춘 전 출생자이므로 년주는 바뀌지 않는 것입니다.
                    <div class="pop-body__footnote">
                      (2025년도 입춘절입 :  2월 3일 23시 09분)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pop-body__desc">
              물론 2025년 <b>입춘 절입시각 이후</b> 출생한 분들은 동지/입춘을 떠나 모두 <b><span class="colored-txt primary">‘을사(乙巳)’</span></b> 년주가 됩니다.
              </div>
            </div>
          </div>
        </div>

        <div class="pop-pagination">
          <div class="pop-pagination__index">0{{ currentPage }} / 02</div>
          <button
            class="pop-pagination__prev"
            @click="clickPrevPage"
          >
            <i class="icon-chevron"></i>
          </button>
          <button
            class="pop-pagination__next"
            :class="{'inactive': currentPage === 2}"
            @click="clickNextPage"
          >
            <i
              class="icon-chevron right"
              :class="{'inactive': currentPage === 2}"></i>
            <span v-if="currentPage === 2" class="tooltip">마지막 페이지입니다</span>
          </button>
        </div>
      </VCard>
    </div>
  </VDialog>
</template>