<script setup>
import { onMounted } from 'vue'
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { DEV_MODE } from '@/main'
import { addContentLogAPI } from '@/api/logServiceApi'
const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()

const emit = defineEmits()

const props = defineProps({
  prPgmode: {
    type: String,
    default: '',
  },
  prData: {
    type: Object,
    default: null,
  },
})
const PopShareBox = ref(true)
const PopSharePreviewBox = ref(false)
const close = () => {
  emit('close')
}
const closePreview = () => {
  PopSharePreviewBox.value = false
}

const openSharePreview = () => {
  PopSharePreviewBox.value = true
}

const KKOID = import.meta.env.VITE_KAKAO_APP_KEY
/* global Kakao */
onMounted(() => {
  // 카카오 SDK 초기화
  if (typeof Kakao !== 'undefined') {
    if (!Kakao.isInitialized()) {
      Kakao.init(KKOID)
    }
  } else {
    console.error('Kakao SDK가 로드되지 않았습니다.')
  }
})
// 복사 기능
const goCopy = () => {
  addContentLogAPI({
    contentType: props.prPgmode === 'rs' ? 'sl_btn_share_select_opt3' : 'manse_btn_share_select_opt3',
    option3: '텍스트',
  })
  const shareData = useFun.convertShareTxt(props.prData)
  DEV_MODE && console.log('공유 데이터 형태:', props.prData)
  navigator.clipboard
    .writeText(shareData)
    .then(() => {
      alert('복사 되었습니다.')
    })
    .catch((error) => {
      alert(error)
    })
}

// 카카오 공유 기능
const goKko = () => {
  addContentLogAPI({
    contentType: props.prPgmode === 'rs' ? 'sl_btn_share_select_opt3' : 'manse_btn_share_select_opt3',
    option3: '카카오톡',
  })
  const shareData = useFun.convertShareTxt(props.prData)
  const frontUrl = import.meta.env.VITE_WEB_BASE_URL
  if (typeof Kakao !== 'undefined' && Kakao.isInitialized()) {
    Kakao.Share.sendDefault({
      objectType: 'text',
      text: shareData,
      link: {
        mobileWebUrl: frontUrl,
        webUrl: frontUrl,
      },
    })
  } else {
    console.error('Kakao SDK가 로드되지 않았습니다.')
  }
}
</script>
<template>
  <VDialog 
  v-model="PopShareBox" 
  class="v-dialog-sm dialog-st1" 
  max-width="400px"
  @click:outside="close">
    <VCard class="br16">
      <div class="pop-inner" style="height: 360px">
        <div class="pop-tit-box tit-default">
          <div class="pop-tit-sub fnt16" style="text-align: center; width: 182px; ">사주정보 공유</div>
          <!-- <DialogCloseBtn variant="text" size="default" @click="close" /> -->
           <button class="dial-close" @click="close"></button>
        </div>
        <div class="pop-body pd-large-sh">
          <div class="pop-exp-sh">사주정보 결과를 복사하고, 공유 할 수 있는 기능입니다.</div>
          <div class="share-com-btn-box">
            <div class="" @click="goCopy()">
              <div class="icon-box">
                <i class="icon-message">붙여넣기아이콘</i>
              </div>
              <div class="txt1">텍스트 복사</div>
              <div class="txt2">원하는 곳에 붙여넣기<br />(Ctrl+V) 하여 사용하세요.</div>
            </div>
            <div class="" @click="goKko()">
              <div class="icon-box">
                <i class="icon-kko">카카오톡아이콘</i>
              </div>
              <div class="txt1">카카오톡 공유</div>
              <div class="txt2">카카오톡 친구&지인에게 <br />공유해보세요.</div>
            </div>
          </div>

          <div class="btn-guide-box">
            <button @click="openSharePreview()">
              <i class="icon-circle" /><span>공유 방법이 궁금하다면? <span class="pre-view">공유 내용 미리보기</span></span>
            </button>
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
  <VDialog v-model="PopSharePreviewBox" class="v-dialog-lg dialog-st1" max-width="640px">
    <VCard class="br16">
      <div class="pop-inner center" style="height: 642px; width: 640px; padding-top: 20px !important;">
        <div class="pop-tit-box share-pre" style="margin-bottom: 16px;">
          <div class="pop-tit-sub fnt16" style="text-align: center; width: 182px !important;">공유 내용 미리보기</div>
          <!-- <DialogCloseBtn variant="text" size="default" @click="closePreview" /> -->
          <button class="dial-close" @click="closePreview" style="width: 24px; height: 24px;"></button>
        </div>
        <div class="pop-body share-pre">
          <div class="share-preview-box-v2">
            <div class="img01" />
            <div class="img02" />
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>
