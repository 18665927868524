<script setup>
const props = defineProps({
  contents: Object,
  curIlgan: String,
})
const { ...five } = fiveMixin()

</script>

<template>
    <div class="cont-pages">
        <div class="tit">
            <span class="main-tit ganji-txt" :class="five.getFiveClass(five.getGanjiColorFive(props.curIlgan))">
                 {{ contents.icon }} {{ contents.title }}
            </span>            
        </div>
        <div class="inner-cont">
            <span class="sub-tit bold">
                {{ contents.body.subTitle }}
            </span>
            <span v-for="desc in contents.body.descriptions" class="nor-txt">
                {{ desc }}
            </span>
        </div>
    </div>
</template>