<script setup>
import IconPrev02 from '@/components/svg/IconPrev02.vue'
import IconNext02 from '@/components/svg/IconNext02.vue'
import { useDeviceDetect } from '@/composables/useDeviceDetect'

const { isMobile } = useDeviceDetect();

const formatPageNumber = (pageNumber) => {
  return String(pageNumber).padStart(2, '0')
}
// const curPage = ref(1);
const itemLength = ref(7)

const props = defineProps({
    curPage: Number,
})
const emit = defineEmits(['updateIndexNo', 'showOutro', 'noShowOutro', 'showCover'])

const visible = computed(() => {
  if (isMobile.value) {
    return props.curPage !==7;
  } else {
    return true;
  }
})

const goPage = (id) => {
  let no = id
  if (id <= 0) {
    emit('showCover')
  }
  if (id >= itemLength.value){
    emit('showOutro')
    no = itemLength.value
  } else{
    emit('noShowOutro')
  }
  emit('updateIndexNo', no)
  props.curPage = no 
}
</script>

<template>
    <div v-if="visible" class="list-page-box">
        <span class="no-box">
            <span class="no">{{ formatPageNumber(curPage) }}</span>
            <span> / </span>
            <span class="no">{{ formatPageNumber(isMobile ? itemLength - 1 : itemLength) }}</span>
        </span>
        <IconPrev02 @click="goPage(curPage - 1)" class="chevron-btn"/>
        <IconNext02 @click="goPage(curPage + 1)" class="chevron-btn"/>
    </div>
</template>