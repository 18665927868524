<script setup>
import { useAnalysisStore } from '@/store/modules/analysis'

import personalitySub01 from '@/pages/analysis/components/personalitySub01.vue'
import personalitySub02 from '@/pages/analysis/components/personalitySub02.vue'
import personalitySub03 from '@/pages/analysis/components/personalitySub03.vue'
import personalitySub04 from '@/pages/analysis/components/personalitySub04.vue'
import personalitySub05 from '@/pages/analysis/components/personalitySub05.vue'

import ContentsList from '@/pages/analysis/components/ContentsList.vue'

const store = useAnalysisStore()

const indexNo = ref(1)
const personContents = ref([
  { id: 1, title: '1. 기운방향' },
  { id: 2, title: '2. 원국 성향 결과' },
  { id: 3, title: '3. 사주 구성으로 본' },
  { id: 4, title: '4. 운기 행운 변화를' },
  { id: 5, title: '5. 운기 행운 변화를' },
  // { id: 6, title: '6. 정보수집' },
  // { id: 7, title: '7. 판단기준' },
  // { id: 8, title: '8. 관계방식' },
  // { id: 9, title: '9. 발전방향' },
  // { id: 10, title: '10. 개운방향' },
  // { id: 11, title: '11. 행운 성향 특징' },
])

const updateIndexNo = (id) => {
  indexNo.value = id
}
</script>

<template>
  <div v-if="store.selectedMenu.endsWith('nangman')" class="chart-section full-height">
    <personalitySub01 v-if="indexNo === 1" />
    <personalitySub02 v-if="indexNo === 2" />
    <personalitySub03 v-if="indexNo === 3" />
    <personalitySub04 v-if="indexNo === 4" />
    <personalitySub05 v-if="indexNo === 5" />
  </div>
  <div v-if="store.selectedMenu.endsWith('luck')" class="chart-section">22</div>
  <div v-if="store.selectedMenu.endsWith('saju')" class="chart-section">33</div>

  <div class="contentsListBtnBox">
    <ContentsList :items="personContents" :curPage="indexNo" @updateIndexNo="updateIndexNo" />
  </div>
</template>
<style>
@import '/src/assets/nangman_style/personality.scss';
</style>
