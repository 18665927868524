import { defineStore } from 'pinia'

export const useAnalysisStore = defineStore('analysis', () => {
  /** 선택된 메뉴 */
  const selectedMenu = ref('/dashboard')

  /** 하위메뉴 열림 상태 */
  const isMenuOpen = ref({})

  /** 선택 메뉴 화면 열기 */
  const clickMainMenu = (view) => {
    selectedMenu.value = view
  }

  /** 하위 메뉴 열림버튼 클릭 */
  const toggleSubmenu = (id) => {
    isMenuOpen.value[id] = !isMenuOpen.value[id]
  }

  return {
    selectedMenu,
    isMenuOpen,
    clickMainMenu,
    toggleSubmenu,
  }
})

export const menuItems = [
  {
    id: 'dashboard',
    label: '대시보드',
    view: '/dashboard',
  },
  {
    id: 'personality',
    label: '성향분석',
    view: '/personality',
    children: [
      { id: '', label: '낭만오행 성향분석', view: '/personality/nangman' },
      { id: '', label: '운기별 성향분석', view: '/personality/luck' },
      { id: '', label: '사주로 보는 성향', view: '/personality/saju' },
    ],
  },
  {
    id: 'saju-analysis',
    label: '사주분석',
    view: '/saju-analysis',
  },
  {
    id: 'luck-analysis',
    label: '운세분석',
    view: '/luck-analysis',
  },
  {
    id: 'five',
    label: '오행도분석',
    view: '/five',
    children: [
      { id: '', label: '나의 오행도', view: '/five/my' },
      { id: '', label: '음양오행', view: '/five/lunar' },
      { id: '', label: '합/충/형/파/해', view: '/five/hap' },
      { id: '', label: '대운', view: '/five/deaun' },
      { id: '', label: '용신', view: '/five/sin' },
    ],
  },
  {
    id: 'energy-compatibility',
    label: '에너지궁합',
    view: '/energy-compatibility',
  },
  {
    id: 'life-calendar',
    label: '라이프 캘린더',
    view: '/life-calendar',
  },
  {
    id: 'five-guuide',
    label: '오행도란?',
    view: '/five-guide',
  },
]
