<script setup>
import '@/assets/nangman_style/popup/PopFiveStar.scss';

const emit = defineEmits(['close']);

const popFiveStarRef = ref(true);

const currentPage = ref(0);

const close = () => emit('close');

const clickStart = () => {
  currentPage.value = 1;
}

const clickPrevPage = () => {
  if (currentPage.value < 1) return;
  else currentPage.value -= 1;
}

const clickNextPage = () => {
  if (currentPage.value > 2) return;
  else currentPage.value += 1;
}

const onSwipeLeft = () => {
  if (currentPage.value === 0 || currentPage.value > 2) return;
  else currentPage.value += 1;
}

const onSwipeRight = () => {
  if (currentPage.value < 1) return;
  else currentPage.value -= 1;
}

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: onSwipeLeft,  // 왼쪽 스와이프 → 다음 페이지 이동
  onSwipeRight: onSwipeRight, // 오른쪽 스와이프 → 이전 페이지 이동
  SWIPE_THRESHOLD: 150,
});

watch(popFiveStarRef, (newState) => {
  if (!newState) close();
});
</script>

<template>
  <VDialog
    v-model="popFiveStarRef"
    width="740px"
    height="734px"
    max-width="100%"
    class="no-drag"
  >
    <div
      class="pop-five-star-container"
      @mousedown="handleStart"
      @mousemove="handleMove"
      @mouseup="handleEnd"
      @touchstart="handleStart"
      @touchmove="handleMove"
      @touchend="handleEnd"  
    >
      <VCard v-if="currentPage === 0" class="br16 pop-five-star-inner option">
        <div class="pop-header__large">
          <button @click="close"><i class="pop-header__close"></i></button>
          <div class="pop-header__content">
            <div class="pop-header__icon">🌙</div>
            <div class="pop-header__text">
              <div class="pop-header__title">야자시/조자시</div>
              <div class="pop-header__subtitle">어떻게 활용해야 할까요?</div>
            </div>
          </div>
        </div>
        <div class="pop-body option-intro">
          <div class="pop-body__inner option-intro">
            <div class="pop-body__title"><div class="icon option">👀</div>야자시(夜子時) / 조자시(朝子時)란?</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                사주팔자를 간명할 때 <span class="underlined-txt primary">출생 시간이 자시(子時)의 경계선에 있는 분들에 한하여 대입하는 관법</span>으로<br/>
                시(時) 간지를 정확히 계산하는 개념입니다.
              </div>
              <div class="pop-body__desc">
                낭만만세력에서는 하루의 흐름을 더 정밀하게 해석하기 위해 야자시/조자시 옵션을 제공해 드리고<br/>
                있으니, 출생 시간이 자시(子時)의 중요한 경계선에 있다면 참고하셔서 비교하여 검토해 보시기 <br/>
                바랍니다.
              </div>
              <div class="pop-body__desc">
                야자시/조자시 적용 여부에 따라 일간이 태어난 당일 또는 다음 날의 간지로 바뀔 수도 있습니다.
              </div>
            </div>
            
          </div>
          <button class="start-button" @click="clickStart">바로 시작하기</button>
          <div class="pop-footer">
            "사주명리학은 운명을 결정짓는 것이 아니라, 자신을 알고 세상을 바라보는 지혜를 길러주는 길입니다.<br>
            내가 가진 천간의 기운 속에서 숨겨진 가능성을 발견하고, 삶의 흐름 속에서 조화롭게 펼쳐가시길 바랍니다."
          </div>
        </div>
      </VCard>

      <VCard v-else="currentPage !== 0" class="br16 pop-five-star-inner option">
        <div class="pop-header">
          <button @click="close"><i class="pop-header__close"></i></button>
          <div class="pop-header__content">
            <div class="pop-header__icon">🌙</div>
            <div class="pop-header__text">
              <div class="pop-header__title">야자시/조자시</div>
              <div class="pop-header__subtitle">어떻게 활용해야 할까요?</div>
            </div>
          </div>
        </div>

        <div v-if="currentPage === 1" class="pop-body">
          <div class="pop-body__inner">
            <div class="pop-body__title"><div class="icon option">📖</div>야자시와 조자시의 개념</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                사주명리학에서 시간은 12지지(子~亥)로 나누어지며, 각 시진(時辰)은 2시간 단위로 배정됩니다.<br/>
                때문에 하루가 마무리되는 시간은 해시(亥時)까지입니다.
              </div>
              <div class="pop-body__desc">
                표준시를 적용하는 분들은 22시 59분까지를,<br/>
                한국시를 적용하는 분들은 23시 29분까지를 당일이 마감되는 시간으로 구분하게 됩니다.
              </div>
              <div class="pop-body__desc">
                때문에 자시(子時)부터는 새로운 날이 시작되었다고 보는 것이 통상적인 구분법입니다.
              </div>
              <div class="pop-body__desc">
                그러나 야자시/조자시의 개념을 적용하게 되면 다음과 같이 분리 정의합니다.
              </div>
              <div class="pop-body__desc">
                자시(子時)의 2시간 구간에 24시의 전, 후가 포함되어 있어 하루의 흐름을 보다 정밀하게 구분하여<br/>
                해석해야 한다는 것입니다.
              </div>
              <div class="pop-card__list option">
              <div class="pop-card">
                <div class="pop-card__text">
                  <div class="pop-card__desc">
                    <span class="list-tit">표준시 관점 자시(子時)</span>
                    <span class="list-desc">23시 ~ 01시</span>
                  </div>
                </div>
              </div>
              <div class="pop-card">
                <div class="pop-card__text">
                  <div class="pop-card__desc">
                    <span class="list-tit">한국시 관점 자시(子時)</span>
                    <span class="list-desc">23시 30분 ~ 01시 30분</span>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage === 2" class="pop-body">
          <div class="pop-body__inner">
            <div class="pop-body__title"><div class="icon option">📖</div>야자시와 조자시의 개념</div>
            <div class="pop-body__content">
              <div class="pop-body__desc">
                자시(子時)는 새로운 날의 시작이지만, 자정을 기준으로<br/>
                <span class="underlined-txt">자정 이전은 당일 개념의</span> <span class="colored-txt primary bold">야자시</span>로<br/>
                <span class="underlined-txt">자정 이후는 새로운 날의 개념인</span> <span class="colored-txt primary bold">조자시</span>를 적용하는 분석법입니다.
              </div>
              <div class="pop-card__list option">
              <div class="pop-card option">
                <div class="pop-card__icon">🕚</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    야자시 개념 적용시 : 23시~24시까지 (한국시 : 23시 30분 ~ 00시 30분)
                  </div>
                  <div class="pop-card__desc">
                    엄밀하게는 다음날이 아직 되지 않았으니 당일의 연장선으로 간지를 적용하자는 것입니다.
                  </div>
                </div>
              </div>
              <div class="pop-card option">
                <div class="pop-card__icon">🕚</div>
                <div class="pop-card__text">
                  <div class="pop-card__title">
                    조자시 개념 적용시 : 00시~01시까지 (한국시 : 00시 30분 ~ 01시 30분)
                  </div>
                  <div class="pop-card__desc">
                    00시 이후부터는 진짜 새로운 하루가 시작되었으니 새로운 날인 다음날의 간지를 적용하자는 것입니다.
                  </div>
                </div>
              </div>
            </div>
            <div class="pop-body__desc">
              야자시(夜子時)와 조자시(朝子時)는 서로 독립적인 개념이 아니라, 함께 적용되는 개념입니다.
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage === 3" class="pop-body">
          <div class="pop-body__inner">
            <div class="jasi-table-img"></div>
            <div class="jasi-img"></div>
          </div>
        </div>

        <div class="pop-pagination">
          <div class="pop-pagination__index">0{{ currentPage }} / 03</div>
          <button
            class="pop-pagination__prev"
            @click="clickPrevPage"
          >
            <i class="icon-chevron"></i>
          </button>
          <button
            class="pop-pagination__next"
            :class="{'inactive': currentPage === 3}"
            @click="clickNextPage"
          >
            <i 
              class="icon-chevron right"
              :class="{'inactive': currentPage === 3}"></i>
            <span v-if="currentPage === 3" class="tooltip">마지막 페이지입니다</span>
          </button>
        </div>
      </VCard>
    </div>
  </VDialog>
</template>