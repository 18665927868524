<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import moment from 'moment'

import { DEV_MODE } from '@/main'
import { useLogsStore } from '@/store/modules/logsStore'

/** API */
import { calendarFilter } from '@/api/EowayApi'
import { getGroupUserlist } from '@/api/UserGroup'
import { fastSearch } from '@/api/Eoway'
import { addContentLogAPI, getLogStatsAPI } from '@/api/logServiceApi'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'
import { useMySaju } from '@/composables/calendar/useMySaju'
import { useDeviceDetect } from '@/composables/useDeviceDetect'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import { VBottomSheet, VNavigationDrawer } from 'vuetify/lib/components/index.mjs'
import CalendarHeaderMobile from '@/components/calendar/CalendarHeaderMobile.vue'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import ToggleBox from '@/components/calendar/ToggleBox.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
import PointConfirm from '@/components/calendar/PointConfirm.vue'
import LoadingIndicator from '@/components/common/LoadingIndicator.vue'
import ToggleDrawer from '@/components/calendar_mobile/ToggleDrawer.vue'
import DailyFortuneDrawer from '@/components/calendar/drawer/DailyFortuneDrawer.vue'

/** 스타일 */
import '@/assets/nangman_style/calendar/calendar_mobile.scss'

definePage({ meta: { layout: 'empty' } })

const route = useRoute();
const router = useRouter();
const store = useStore();

const logsStore = useLogsStore()

const { getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { formatNumber, ...useFunc } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlertRef = ref(null);
/** confirmAlert 참조 */
const dialogConfirmRef = ref(null);
/** 포인트 결제 Alert 참조 */
const pointConfirmRef = ref(null);
const dialogTitle = ref('');
const dialogTitle2 = ref('');
const dialogSubtitle = ref('');
const dialogText = ref('');
const dialogNextUrl = ref(null);

/** 역학달력 Drawer */
const isDrawerOpen = ref(false);
// /** 일진달력 Drawer */
// const isTodayLuckDrawerOpen = ref(false);

/** 일진달력 사이드바 열림 상태 */
const isDailyFortuneSidebarOpen = ref(false);
/** 일진달력 선택 여부 - true: 일진달력, false: 역학달력 */
const isDailyFortuneCalendarOpen = ref(false);
/** 일진보기 drawer 열림 상태 */
const isDailyFortuneViewOpen = ref(false);

/** 모바일 사용자 토큰 */
const accessToken = ref(null);
/** 모바일 사용자 ID */
const authUserId = ref(null);
const userId = ref(null);

const isDailyFortuneDrawerOpen = ref(false);

/** 표시될 내 사주 데이터 */
const mySajuObject = computed(() => {
  if (!isSajuDataExist) return null;
  const data = sajuData.value;
  return {
    ilgan: data.sajugangi.split('')[1] || null,
    name: data.guestUserName || null,
    gender: useFunc.getGenderTxt(data.gender),
    age: data.age ? `(만${data.age}세)` : '',
    ageKor: data.ageKor ? `${data.ageKor}세` : '',
    solar: data.birth ? useFunc.formatCustomDate(data.birth) : '-',
    week: data.birth ? `(${useFunc.getDayOfWeekOne(data.birth)})` : '',
    time: five.getBirthTimeTxt(data.birthTime) || '',
    lunar: useFunc.formatCustomDate(data.lunar) || '-',
    city: data.bornAreaInfo?.korCityName || '',
    korTime: useFunc.formatOption4Txt(data.option4) ,
    earlyTime: useFunc.formatOption1Txt(data.option1),
    season: useFunc.formatOption2Txt(data.option2),
  }
});

/** 내 사주 정보 열림 상태 */
const isOpenSajuInfo = ref(false);

const openDailyFortuneDrawer = () => {
  sajuError.value = isDailyFortuneDrawerOpen.value
  // if (!userId.value) return;
  // if (info) selectedDateInfo.value = info;
  if (isDailyFortuneDrawerOpen) {
    // const dataKey = moment(info.date).format('YYYYMMDD');
    // dailyLuckData.value = monthlyLuckData[dataKey];
    isDailyFortuneDrawerOpen.value = true;
  } // 이너뷰 열기
}

// const openTodayLuckDrawer = () => {
//   sajuError.value = isTodayLuckDrawerOpen.value
//   if (isTodayLuckDrawerOpen) {
//     isTodayLuckDrawerOpen.value = true;
//   }
// }

const sajuListParam = ref({
  birthStart: null,
  birthEnd: null,
  startDate: null,
  endDate: null,
  genderOption: 0,
  page: 1,
  size: 10,
  searchKeyWord: '',
  sortOption: null,
  userGroupId: null,
  activeOpt1: false,
  activeOpt2: false,
  activeOpt3: false,
  activeOpt4: false,
  fiveEles: [],
  gender: null,
  filterFlag: false,
  isFavorite: null,
})

const sajuParamData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
});

/** 등록된 '내 사주' 데이터 */
const sajuData = ref({});
/** 내 사주 정보 유무 확인 */
const isSajuDataExist = computed(() => Object.keys(sajuData.value).length !== 0);

const sajuError = ref('');

/** 날짜 별 일진 차감 포인트 */
const dailyFortunePrice = ref(60);
/** '카테고리 별 운세(생활운세)' 차감될 포인트 */
const categoryPrice = ref(20);
/** 달 전체 일진 차감 포인트 */
const monthlyFortunePrice = ref(1000);

const displayPoint = ref('');
const showPointConfirm = ({title, subtitle, point, confirmText, onConfirm = () => {}}) => {
  dialogTitle.value = title;
  dialogSubtitle.value = subtitle;
  dialogText.value = confirmText;
  displayPoint.value = point;
  dialogNextUrl.value = onConfirm;
  pointConfirmRef.value.openConfirmDialog();
}

/** 확인 알림창 표시 */
const showDialogAlert = ({title, text = '', nextUrl = ''}) => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlertRef.value.openAlertDialog();
};

/** 확인/취소 알림창 표시 */
const showDialogConfirm = ({title, title2 = '', text = '', onConfirm = () => {}}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달
  dialogConfirmRef.value.openConfirmDialog()
};

/** 로그인 알림창 표시 */
const showLoginConfirm = () => {
  showDialogConfirm({
    title: '로그인 하시면',
    title2: '일진을 보실 수 있습니다.',
    text: '로그인 하러 가기',
    onConfirm: isMobile.value
      ? () => sendMessageToApp('login')
      : () => router.push({
          path: '/auth/login',
          query: { to: route.fullPath}
        })
    ,
  });
};

/** 내 사주 등록 알림창 표시 */
const showSelectConfirm = () => {
  showDialogConfirm({
    title: '내 사주 등록 후,',
    title2: '일진을 보실 수 있습니다.',
    text: '내 사주 등록',
    onConfirm: () => openPopMySaju()
  });
}

/** 디바이스 감지 */
const { isMobile } = useDeviceDetect();

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  toggleOptions,
} = useCalendarFilter();

/** 달력 생성 */
const {
  calendarApi,
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
} = useCalendar({
  isLoading,
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  calendarType: 'mobile',
  isDailyFortuneCalendarOpen,
  isSajuDataExist,
  userId: accessToken,
  sajuData,
  dailyFortunePrice,
  openDailyFortuneDrawer,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

/** '내 사주' 등록/관리 로직 */
const {
  isMySajuLoading,
  isPopMySajuOpen,
  mySajuList,
  selectedMySajuId,
  getMySaju,
  openPopMySaju,
  closePopMySaju,
  selectMySaju,
  confirmSelectSaju,
  goManse,
} = useMySaju({
  calendarApi,
  userId: accessToken,
  sajuData,
  showDialogConfirm,
  showLoginConfirm,
});

const purchaseDailyFortune = async ({yyyymmdd, purchaseType, callback = async () => {}}) => {
  isPurchasePending.value = true;

  const [year, month, day] = yyyymmdd.split('-');
  const params = {
    year, // 필수
    month,  // 필수
    purchaseType, // "DAY", "MONTH" (필수)
    ...(purchaseType === "DAY" && { day }), // purchaseType: "DAY" 일 때
  }

  try {
    const result = await purchaseDailyFortuneAPI(params);    

    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('운세가 구매되었습니다.');
      await getDailyFortuneCalendar(`${year} ${month}`);
      await callback();
      calendarApi.value.render();
    }
  } catch (e) {
    console.error('결제 실패:', e.response.data.detail);
    handlePurchaseError(e);
  } finally {
    isPurchasePending.value = false;
  }
}

const handlePurchaseError = (e) => {
  if (e.response.data.detail === '포인트가 부족합니다.') {
    showRechargeConfirm();
  }
}

/** 포인트 충전 팝업 */
const showRechargeConfirm = () => {
  showDialogConfirm({
    title: '보유 낭만 포인트가 부족합니다.',
    title2: '지금 바로 충전하시겠습니까?',
    text: '충전하기',
    onConfirm: () => router.push('/mypage/subscrPoint'),
  });
}

/** 달 전체 일진 구매 */
const purchaseMonthly = () => {
  // 로그인 체크
  if (!accessToken.value) {
    showLoginConfirm();
    return;
  }
  // 내 사주 체크
  if (!isSajuDataExist.value) {
    showSelectConfirm();
    return;
  }

  const currentDate = refCalendar.value.calendar.currentData.currentDate;
  
  showPointConfirm({
    title: moment(currentDate).format('YYYY년 MM월'),
    subtitle: '운세를 구매 하시겠습니까?',
    point: formatNumber(monthlyFortunePrice.value),
    confirmText: '구매',
    onConfirm: async () => {          
      await purchaseDailyFortune({
        yyyymmdd: moment(currentDate).format('YYYY-MM-DD'),
        purchaseType: 'MONTH',
      });
    },
  })
}

/** '카테고리 별 운세(생활운세)' 구매 */
const purchaseDailyCategory = async ({yyyymmdd, category, categoryPrice}) => {
  isPurchasePending.value = true;

  const params = {
    activeDate: yyyymmdd,  // yyyy-mm-dd
    category, // "STUDY", "WORK", "WEALTH", "LOVE", "HEALTH", "FAMILY", "MOVE", "RELATIONSHIP", "FAME", "SOCIAL"
    categoryPrice,  // 차감 포인트
  }

  try {
    const result = await purchaseDailyCategoryAPI(params);    
    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('운세가 구매되었습니다.');
      await getDailyFortuneDetail({yyyymmdd, displayLoading: false});
      await getDailyCategoryDetail(moment(selectedDateInfo.value).format('YYYY-MM-DD'), category)
      setSelectedCategory(category);
    }
  } catch (e) {
    DEV_MODE && console.error('카테고리 별 운세(생활운세) 구매 실패', e);
    handlePurchaseError(e);
  } finally {
    isPurchasePending.value = false;
  }
}

/** 일운보기 결제 */
const confirmPurchaseFortune = async ({yyyymmdd, dataKey}) => {  
  // 결제 로직
  await purchaseDailyFortune({yyyymmdd, purchaseType: 'DAY', callback: async () => openDailyFortuneDrawer({info: {day: yyyymmdd}, actionType: 'opened'})});
  
  // await getDailyFortuneDetail({yyyymmdd});
}

// const getUserFilter = async (userId) => {
//   try {
//     const result = await calendarFilter({ id: userId })
//   } catch (e) {
//     DEV_MODE && console.log('schedule_app getUserFilter error:', e)
//   }
// }

onMounted(async () => {
  if (!logsStore.logContents) {
    const result = await getLogStatsAPI();
    logsStore.setLogContents(result);
  }
  window.addEventListener('message', handleMessage);
})

onUnmounted(() => {
  window.removeEventListener('message', handleMessage);
})

const handleMessage = async (event) => {
  try {
    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
    } else {
      data = event.data;
    }

    // React DevTools 메시지 무시
    if (data.source === 'react-devtools-content-script') {
      console.log('Ignoring React DevTools message.');
      return;
    }

    if (data) {
      if (!isMobile) {
        accessToken.value = store.getters['user/token'];
        userId.value = store.getters['user/id'];
      } else {
        accessToken.value = data.accessToken;
        userId.value = data.authUserId;
      }
    }
  } catch (e) {
    console.error('Invalid message received:', `${e}`);
  }
};

const getGrouplistAPI = async () => {
  let url = `/users/userGroups`;
  axios.defaults.headers.common['Authorization'] = accessToken.value;
  const response = await axios.get(url);
  return response.data;
}

const getSajuParams = async (id) => {
  if (!id) return;

  try {
    const result = await getDetail(id);

    const dateTime = result.integrationBirth.split('T');
    const [year, month, day] = dateTime[0].split('-');
    const [hour, min] = dateTime[1]?.split(':');    
    
    sajuParamData.value.year = year;
    sajuParamData.value.month = month.padStart(2, '0');
    sajuParamData.value.day = day.padStart(2, '0');
    sajuParamData.value.hour = hour.padStart(2, '0');
    sajuParamData.value.min = min.padStart(2, '0');
    sajuParamData.value.gender = result.gender === 'MEN' ? 1 : 0;
    sajuParamData.value.userName = result.guestUserName;
    sajuParamData.value.isLunar = result.isLuna === null ? false : result.isLuna;
    sajuParamData.value.lunar = result.lunar;
    sajuParamData.value.isLeapYear = result.isLeapYear;
    sajuParamData.value.bornAreaId = result.bornAreaId;
    sajuParamData.value.birthTime = result.birthTime;
    sajuParamData.value.unknownTime = result.unknownTime
    sajuParamData.value.option1 = result.option1;
    sajuParamData.value.option2 = result.option2;
    sajuParamData.value.option3 = result.option3;
    sajuParamData.value.option4 = result.option4;
    sajuParamData.value.option5 = result.option5;
    sajuParamData.value.addressTxt = result.bornArea;
    // sajuParamData.value.lang = result.lang
    // sajuParamData.value.langYN = result.langYN
    // sajuParamData.value.userGroupId = result.userGroupId
  } catch (e) {
    DEV_MODE && console.error('사주정보 조회 오류:', e);
    sajuError.value = e;
  };
};

const padNumber = (num) => num.toString().padStart(2, '0');

const convertManseForceParams = () => {
  const { year, month, day, hour, min } = sajuParamData.value;

  sajuParamData.value.birth = `${year}-${padNumber(month)}-${padNumber(day)}`;  
  
  if (sajuParamData.value.birthTime === '25:00') {
    sajuParamData.value.unknownTime = 'Y';
  } else {
    sajuParamData.value.birthTime = `${padNumber(hour)}:${padNumber(min)}`;
  }
  if (!sajuParamData.value.unknownTime) sajuParamData.value.unknownTime = 'N';
  if (!sajuParamData.value.isLeapYear) sajuParamData.value.isLeapYear = false;
  if (sajuParamData.value.bornAreaId === null) delete sajuParamData.value.bornAreaId;
}

const getManseForce = async () => {
  convertManseForceParams();

  try {
    const result = await fastSearch(sajuParamData.value);    
    sajuData.value = result;
  } catch (e) {
    DEV_MODE && console.error('만세력 조회 오류:', e);
    sajuError.value = `getManseForce: ${e}`;
  }
}

watch (() => accessToken.value, async (newToken) => {
  if (DEV_MODE && newToken) {

    try {
      const groupList = await getGrouplistAPI();

      if (groupList && groupList.length > 0) {
        sajuListParam.userGroupId = groupList[0].userGroupId;
        const result = await getGroupUserlist(sajuListParam);

        mySajuList.value = result.content;
        if (mySajuList.value && mySajuList.value.length > 0) {
          const guestUserId = mySajuList.value[mySajuList.value.length - 1].guestUserId;
          if (guestUserId) {
            await getSajuParams(guestUserId);
            await getManseForce();
          }
        }
      }
    } catch (e) {
      console.error('내 사주 정보 호출 실패:', e);
      sajuError.value = e
    }
  }
});

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  selectedDate.value = moment().format('YYYY-MM-DD')
  changeViewTitle();
};

/** 사주시계 팝업  */
const setSajuClock = (state) => {
  if (state) addContentLogAPI('C_saju_watch');
  isPopSajuClock.value = state;
}

/** 역학달력/일진달력 전환 버튼 */
const clickBottomLabel = () => {
  if (isDailyFortuneCalendarOpen.value) {
    addContentLogAPI('C_tab_saju_calendar');
    isDailyFortuneCalendarOpen.value = false;
  } else {
    if (DEV_MODE && !isSajuDataExist.value) {
      getMySaju();
    } else {
      if (accessToken.value && !isSajuDataExist.value) {
        getMySaju();
      }
    }
    addContentLogAPI('C_tab_luck_calendar');
    isDailyFortuneCalendarOpen.value = true;
  };
}

const errorMessage = ref('');
const sendMessageToApp = (type) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({type, error: errorMessage.value }));
    errorMessage.value = '';
  }
};
</script>

<template>
  <div class="calendar-wrap mobile">
    <VNavigationDrawer
      v-if="isDailyFortuneSidebarOpen && DEV_MODE"
      v-model="isDailyFortuneSidebarOpen"
      app
      temporary
      location="right"
      width="100px"
      class="drawer-mobile-w100"
    >
      <div v-if="!isPopMySajuOpen" class="header">
        <div class="title">일진달력</div>
        <button class="close" @click="isDailyFortuneSidebarOpen = false"></button>
      </div>
      <div v-if="!isPopMySajuOpen" class="calendar-sidebar mobile">
        <div v-if="!isLoading && isDailyFortuneCalendarOpen" class="section">
          <div v-if="!isSajuDataExist" class="note">
            <i class="pin"></i>
            <div>내 사주를 선택하면,<br />나의 일진을 확인할 수 있습니다.</div>
            <button
              class="mysaju-button"
              @click="openPopMySaju"
            >
              내 사주 선택하기
            </button>
          </div>

          <div v-else class="mysaju-box">
            <div class="info-box">
              <div class="ilgan-icon">
                {{ mySajuObject.ilgan }}
              </div>
              <div class="name-box">
                <div v-if="mySajuObject.name" class="name">
                  <div class="name-text">{{ mySajuObject.name }}</div>
                  <button
                    :class="['button', { close: isOpenSajuInfo }]"
                    @click="isOpenSajuInfo = !isOpenSajuInfo"
                  ></button>
                </div>
                <div v-else class="name">-</div>
                <div class="age">
                  {{ mySajuObject.gender }} {{ mySajuObject.ageKor }} {{ mySajuObject.age }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="isSajuDataExist && isOpenSajuInfo" class="divider"></div>
          <div v-if="isSajuDataExist && isOpenSajuInfo" class="mysaju-box">
            <div class="birth-box">
              <div class="birth-item">
                <span class="label solar">양력</span>
                <span class="txts">
                  {{ mySajuObject.solar }}
                  {{ mySajuObject.week }}
                  {{ mySajuObject.time }}
                </span>
              </div>
              <div class="birth-item">
                <span class="label lunar">음력</span>
                <span class="txts">{{ mySajuObject.lunar }}</span>
              </div>
              <div class="birth-item">
                <span class="label">옵션</span>
                <span class="txts">
                  <span v-if="mySajuObject.city">
                    {{ `${mySajuObject.city},` }}&nbsp;
                  </span>
                  {{ mySajuObject.korTime }}
                  {{ mySajuObject.earlyTime }}
                  {{ mySajuObject.season }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <button v-if="isDailyFortuneCalendarOpen" class="section calendar-banner-mobile">
          <i class="icon-cal"></i>
          <div class="text">일진달력을<br>활용하는 방법은?</div>
        </button>

        <div v-if="isDailyFortuneCalendarOpen" class="section">
          <div class="promotion">
            <div class="promotion-info">
              <i class="icon point"></i>
              <div class="text">
                포인트로 이번 달<br>전체 일진 보기
              </div>
            </div>  
            <button
              class="promotion-button"
              @click="purchaseMonthly"
            >
              {{ formatNumber(monthlyFortunePrice) }} p
            </button>
          </div>
        </div>

        <div v-if="DEV_MODE" class="section">
          <div class="promotion">
            <div class="promotion-info">
              <i class="icon crown"></i>
              정기구독 이용하고,<br>무료로 일진 보기
            </div>  
            <button
              class="promotion-button sub"
              @click="handleShowSub"
            >
              구독상품 보기
            </button>
          </div>
        </div>
      </div>
      
      <div v-if="isPopMySajuOpen" style="background-color: #fff;">
        <div class="popup-container mobile">
          <div class="header">
            <div class="title">내 사주 선택</div>
            <button class="close" @click="closePopMySaju"></button>
          </div>

          <div class="popup-body">
            <div class="section">
              * 저장목록 > 내사주 그룹에 있는 사주만 선택 가능합니다.<br>
              * 등록 후, 변경이 어려우니 신중해 주세요!
            </div>

            <div v-if="!mySajuList || mySajuList.length === 0" class="section empty">
              <div>
                사주를 [내 사주] 그룹으로<br>새롭게 저장해 주세요!
              </div>
              <button class="add-button" @click="clickRoute">사주 조회하러 가기</button>
            </div>

            <div v-else class="section list">
              <div
                v-for="(item, index) in mySajuList"
                :key="index" 
                :class="['mysaju-item-box', 'a-link', {'selected': selectedMySajuId === item.guestUserId}]"
                @click="selectMySaju(item.guestUserId)"
              >
                <div class="ganji-box-l">
                  <span
                    class="first ganji-txt1"
                    :class="five.getColorFive(five.formatGanJi(item?.sajugangi, 2))"
                    >{{ five.formatGanJi(item?.sajugangi, 2) }}
                  </span>
                  <span
                    class="second ganji-txt1"
                    :class="five.getColorFive(five.formatGanJi(item?.sajugangi, 6))"
                    >{{ five.formatGanJi(item?.sajugangi, 6) }}</span
                  >
                </div>

                <div class="birth-box">
                  <div class="name-box row">
                    <span v-if="item.guestUserName" class="name">{{ item.guestUserName }}</span>
                    <span v-else class="name">-</span>
                    <span class="age"> {{ useFunc.getGenderTxt(item.gender) }} {{ item?.ageKor }}세 (만{{ item?.age }}세) </span>
                  </div>

                  <div class="name-row">
                    <div class="birth-item">
                      <span class="label solar">양력</span>
                      <span class="txts"
                        >{{ useFunc.formatCustomDate(item?.birth) }}({{
                          useFunc.getDayOfWeekOne(item.birth)
                        }}) {{ useFunc.getBirthTimeTxt(item.birthTime) }}
                      </span>
                    </div>

                    <div class="birth-item">
                      <span class="label lunar">음력</span>
                      <span class="txts">{{ useFunc.formatCustomDate(item?.lunar) }}</span>
                    </div>
                  </div>

                  <div class="birth-item">
                    <span class="label">옵션</span>
                    <span class="txts">
                      {{ item?.bornAreaInfo?.korCityName }}
                      <span v-if="item?.bornAreaInfo?.korCityName">,</span>
                      {{ useFunc.formatOption4Txt(item?.option4) }}
                      {{ useFunc.formatOption1Txt(item?.option1) }}
                      {{ useFunc.formatOption2Txt(item?.option2) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="mySajuList && mySajuList.length !== 0" class="footer">
            <button 
              :class="['confirm', {'active': selectedMySajuId}]"
              :disabled="!selectedMySajuId"
              @click="confirmSelectSaju"
            >
              선택
            </button>
          </div>
        </div>
      </div>
    </VNavigationDrawer>
    <ToggleDrawer
      v-show="isDrawerOpen"
      v-model="isDrawerOpen"
      :toggleOptions="toggleOptions"
      @close="isDrawerOpen = false"
    />
    <!-- <DailyFortuneDrawer
      v-show="isDailyFortuneViewOpen"
      v-model:isDailyFortuneViewOpen="isDailyFortuneViewOpen"
      :selectedDateInfo="selectedDateInfo"
      :dailyFortuneDetail="dailyFortuneDetail"
      :dailyCategoryDetail="dailyCategoryDetail"
      :dailyCustomObject="dailyCustomObject"
      :sajuData="sajuData"
      :dailyCategoryStatus="dailyCategoryStatus"
      :isDailyFortuneLoading="isDailyFortuneLoading"
      :isDailyFortuneLoaded="isDailyFortuneLoaded"
      :isCategoryLoading="isCategoryLoading"
      :errorMessage="errorMessage"
      :selectedCategoryList="selectedCategoryList"
      :selectedCategory="selectedCategory"
      :freeCategoryCount="freeCategoryCount"
      :categoryPrice="categoryPrice"
      :luckButtonDisabled="luckButtonDisabled"
      :popupType="popupType"
      @clickCategoryButton="clickCategoryButton"
      @togglePopup="togglePopup"
      @closePopup="closePopup"
      @closeInnerView="closeInnerView"
      @retryOpenDailyFortuneDrawer="retryOpenDailyFortuneDrawer"
    /> -->
    <!-- <button v-if="DEV_MODE" @click="openTodayLuckDrawer">드로어{{ selectedMySajuId }}</button>
    <button v-if="DEV_MODE" @click="openDailyFortuneDrawer">하단뷰</button>
    <VBottomSheet v-model="isDailyFortuneDrawerOpen" height="100vh" class="bottom-sheet">
      <div class="bottom-view">
        message: {{ accessToken }}
        message: {{ authUserId }}
        message: {{ sajuError }}
      </div>
    </VBottomSheet> -->

    <div class="content-main">
      <section class="inner-base">
        <div>
          <CalendarHeaderMobile
            :calendarType="'mobile'"
            :title="viewTitle"
            :isToday="isToday()"
            :solarTerm="firstSeason"
            :solarTermMonth="firstSeasonMonth"
            :isColorChecked="isColorChecked"
            :isDailyFortuneCalendarOpen="isDailyFortuneCalendarOpen"
            @prev="prev"
            @next="next"
            @today="today"
            @clock="setSajuClock(true)"
            @option="isDrawerOpen = true"
            @sidebar="isDailyFortuneSidebarOpen = true"
          />

          <div class="sec-sch-body mobile">
            <v-row
              class="fill-height"
              @mousedown="handleStart"
              @mousemove="handleMove"
              @mouseup="handleEnd"
              @touchstart="handleStart"
              @touchmove="handleMove"
              @touchend="handleEnd"
            >
              <v-col>
                <v-sheet>
                  <FullCalendar
                    ref="refCalendar"
                    class="full-calendar mobile"
                    :options="calendarOptions"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </div>

          <div class="bottom-label" :class="{default: isDailyFortuneCalendarOpen}">
            <div class="summary">{{ isDailyFortuneCalendarOpen ? '역학으로 보는 현재시간과 절기!' : '나의 일진이 궁금하다면?' }}</div>
            <button class="button" @click="clickBottomLabel">{{isDailyFortuneCalendarOpen ? '편리한 역학달력' : '일진달력' }}<i class="arrow"></i></button>
          </div>
        </div>
      </section>
    </div>

    <LoadingIndicator v-if="isLoading"/>
  
    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />
  
    <AlertComponent
      ref="dialogAlertRef"
      :title="dialogTitle"
      :text="dialogText"
    />

    <ConfirmComponent
      ref="dialogConfirmRef"
      :title="dialogTitle"
      :title2="dialogTitle2"
      :text="dialogText"
      :nextUrl="dialogNextUrl"
    />

    <PointConfirm
      ref="pointConfirmRef"
      :title="dialogTitle"
      :subtitle="dialogSubtitle"
      :point="displayPoint"
      :nextUrl="dialogNextUrl"
      :confirm-text="'구매'"
    />

    <Snackbar ref="snackbarRef" />

    <LoadingIndicator v-if="isLoading"/>
  </div>
</template>

<style lang="scss">
.intro-layout {
  width: 100vw;
  height: 100%;
  .main {
    padding: 100px !important;
    margin: 0 !important;
  }
}

.scroll-to-top {
  display: none !important;
}
</style>