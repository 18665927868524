<script setup>
// 🧩 일간 팝업 - 로그인 전 / 로그인 후 진입 화면 (바로시작하기버튼)

const { ...five } = fiveMixin();
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useDeviceDetect } from '@/composables/useDeviceDetect';

const store = useStore()
const router = useRouter()

const emit = defineEmits(['login']);

const props = defineProps({
    ilganData:{
      type: Object,
    },
    fiveElements:{
      type:String
    },
    accessToken: {
      type: String
    },
})
// 디바이스 체크
const { isMobile } = useDeviceDetect();
// 로그인 여부 구분
const userId = ref(null)
userId.value = store.getters['user/id']

/** 로그인 여부에 따른 버튼 동작 */
const handleCBtn = () =>{
    // PC/모바일 분기
    if (!isMobile.value) {
        if(!userId.value){
            router.push(`/auth/login`);
        } else{
            emit('show-content-page')
        }
    } else {
        if(!userId.value){
            emit('login');
        } else{
            emit('show-content-page')
        }
    }
}

/** 마우스 우클릭 방지 */
const disableRightClick = (event) => {
  event.preventDefault()
}

onMounted(() => {
  if (isMobile.value) userId.value = props.accessToken;
  document.addEventListener('contextmenu', disableRightClick)
})

onBeforeUnmount(() => {
  document.removeEventListener('contextmenu', disableRightClick)
})

/** 조사 구분 */
// 두 번째 글자로 판단
const checkJongsung = (name) => {
  if (name.length < 2) return '이';
  const secondChar = name.charCodeAt(1);
  const isThereLastChar = (secondChar - 0xac00) % 28;
  return isThereLastChar ? '이' : '가';
};

// 툴팁 내용 동일 -> 일간만 받는걸로 수정
const tooltipContent = computed(() => {
  const keyword = props.ilganData.title
  return [
  props.ilganData.data.descTit,
    `${keyword}의 상징 키워드`,
    `${keyword}${checkJongsung(keyword)} 가진 힘, 어떤 모습일까?`,
    `${keyword}${checkJongsung(keyword)} 가야 할 길`,
    `${keyword}에게 어울리는 무대는?`,
    `${keyword}에게 에너지를 주는 기운`,
    `${keyword}${checkJongsung(keyword)} 가져야 할 마음가짐`
  ]
})
</script>

<template>
<div class="inner-box">
    <div class="ilgan-title">
        <div class="title">
            <span
                class="tit-txt ganji-txt"
                :class="props.fiveElements">
                {{ props.ilganData.icon }} <br/>
                {{props.ilganData.title}}
            </span>
            <span class="sub-tit">
                {{ props.ilganData.subtitle }}
            </span>
        </div>
        <div class="five-square">
            <span class="txt-round  "
            :class="five.getColorFive(props.ilganData.data.ilgan)">
            {{ five.getHanjaTxt(props.ilganData.data.ilgan, 'sky') }}
        </span>
        </div>
    </div>
    <div class="initial-description">
        <span class="des-tit"
        :class="props.fiveElements">
        💬 {{ props.ilganData.data.descTit }}
        </span>
        <div class="des-content-area">
            <span v-for="cont in props.ilganData.data.descCont" class="des-content">
                {{ cont }}
            </span>
        </div>
    </div>
    <div class="continue-btn"> 
        <button 
            class="c-btn"
            @click="handleCBtn">
            {{ userId ? '바로 시작하기' : '로그인 후 이어보기' }}
            <div v-if="!userId" class="tooltip">
            <div class="tooltip-tit">
                🔓 로그인 하면 더 볼 수 있어요!
            </div>
            <div v-for="cont in tooltipContent" class="tooltip-cont">
                <span class="cont">
                    {{ cont }}
                </span>
            </div>
        </div>
        </button>
    </div>
    <div v-if="!isMobile" class="bottom-description">
        "사주명리학은 운명을 결정짓는 것이 아니라, 자신을 알고 세상을 바라보는 지혜를 길러주는 길입니다. <br />
        내가 가진 천간의 기운 속에서 숨겨진 가능성을 발견하고, 삶의 흐름 속에서 조화롭게 펼쳐가시길 바랍니다."
    </div>
    <div v-else class="bottom-description">
        "사주명리학은 운명을 결정짓는 것이 아니라,<br>
        자신을 알고 세상을 바라보는 지혜를 길러주는 길입니다.<br/>
        내가 가진 천간의 기운 속에서 숨겨진 가능성을 발견하고,<br>
        삶의 흐름 속에서 조화롭게 펼쳐가시길 바랍니다."
    </div>
</div>
</template>
