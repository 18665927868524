<script setup>
import { nextTick, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import moment from 'moment'

import { DEV_MODE } from '@/main'
import { useLogsStore } from '@/store/modules/logsStore'

/** API */
import { addContentLogAPI, getLogStatsAPI } from '@/api/logServiceApi'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'
import { useMySaju } from '@/composables/calendar/useMySaju'
import { useDeviceDetect } from '@/composables/useDeviceDetect'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import { VNavigationDrawer } from 'vuetify/lib/components/index.mjs'
import CalendarHeaderMobile from '@/components/calendar/CalendarHeaderMobile.vue'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
import PointConfirm from '@/components/calendar/PointConfirm.vue'
import LoadingIndicator from '@/components/common/LoadingIndicator.vue'
import ToggleDrawer from '@/components/calendar_mobile/ToggleDrawer.vue'
import DailyFortuneDrawer from '@/components/calendar/drawer/DailyFortuneDrawer.vue'
import Snackbar from '@/components/common/Snackbar.vue';

/** 스타일 */
import '@/assets/nangman_style/calendar/calendar_mobile.scss'
import { useServicePrice } from '@/composables/calendar/useServicePrice'
import { usePurchaseFortune } from '@/composables/calendar/usePurchaseFortune'
import { useDailyFortune } from '@/composables/calendar/useDailyFortune'
import { useSnackbar } from '@/composables/useSnackbar'
import { getDailyCategoryFreeCountAPI, getDailyFortuneCheckAPI, getDailyFortuneDetailAPI, purchaseDailyCategoryAPI, purchaseDailyFortuneAPI } from '@/api/calendarApi'
import { getDayElementCount } from '@/composables/useSajuFunction'
import { usePaymentsStore } from '@/store/modules/payments'

definePage({ meta: { layout: 'empty' } })

const route = useRoute();
const router = useRouter();
const store = useStore();

const logsStore = useLogsStore();
const paymentsStore = usePaymentsStore();

const { getYinYang, getSkyStemFive, getEarthBranchFive, getColorFive, getFiveClass, getGanjiColorFive, formatGanJi, ...five } = fiveMixin();
const { formatNumber, ...useFunc } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlertRef = ref(null);
/** confirmAlert 참조 */
const dialogConfirmRef = ref(null);
/** 포인트 결제 Alert 참조 */
const pointConfirmRef = ref(null);
const dialogTitle = ref('');
const dialogTitle2 = ref('');
const dialogSubtitle = ref('');
const dialogText = ref('');
const dialogNextUrl = ref(null);

const isDailyFortuneLoading = ref(false);

const isDailyFortuneLoaded = ref(false);

/** 생활운세 차트 데이터 */
const lifeLuckChartData = ref({});

const isCategoryLoading = ref(false);
const isCategoryLoaded = ref(false);
const isCategoryRecommendLoading = ref(false);

/** 역학달력 Drawer */
const isDrawerOpen = ref(false);
// /** 일진달력 Drawer */
// const isTodayLuckDrawerOpen = ref(false);

/** 일진달력 사이드바 열림 상태 */
const isDailyFortuneSidebarOpen = ref(false);
/** 일진달력 선택 여부 - true: 일진달력, false: 역학달력 */
const isDailyFortuneCalendarOpen = ref(false);
/** 일진보기 drawer 열림 상태 */
const isDailyFortuneDrawerOpen = ref(false);

/** 모바일 사용자 토큰 */
const accessToken = ref(null);
/** 모바일 사용자 ID */
const authUserId = ref(null);
const userId = ref(null);
userId.value = store.getters['user/id'];

const errorMessage = ref('');

/** 표시될 내 사주 데이터 */
const mySajuObject = computed(() => {
  if (!isSajuDataExist) return null;
  const data = sajuData.value;
  return {
    ilgan: data.sajugangi.split('')[1] || null,
    name: data.guestUserName || null,
    gender: useFunc.getGenderTxt(data.gender),
    age: data.age ? `(만${data.age}세)` : '',
    ageKor: data.ageKor ? `${data.ageKor}세` : '',
    solar: data.birth ? useFunc.formatCustomDate(data.birth) : '-',
    week: data.birth ? `(${useFunc.getDayOfWeekOne(data.birth)})` : '',
    time: five.getBirthTimeTxt(data.birthTime) || '',
    lunar: useFunc.formatCustomDate(data.lunar) || '-',
    city: data.bornAreaInfo?.korCityName || '',
    korTime: useFunc.formatOption4Txt(data.option4) ,
    earlyTime: useFunc.formatOption1Txt(data.option1),
    season: useFunc.formatOption2Txt(data.option2),
  }
});

/** 내 사주 정보 열림 상태 */
const isOpenSajuInfo = ref(false);
/** 날짜 클릭 시 이너뷰 열기 */
const openDailyFortuneDrawer = async ({info, actionType}) => {
  if (info === null) return;
  if (!accessToken.value && !userId.value) return;
  

  const date = actionType === 'opened' ? info.day : info.date;
  const dataKey = moment(date).format('YYYYMMDD');
  const activeDate = moment(date).format('YYYY-MM-DD');

  dailyFortuneData.value = monthlyFortuneData.value[dataKey];

  const dailyStatus = monthlyFortuneStatus.value[dataKey];
  const isToday = todayDate.value === activeDate;

  let contentLogParams = {
    contentType: '',
    option3: '',
  };

  if (dailyStatus) {
    if (isToday && !dailyStatus.is_viewed_today) {
      contentLogParams.contentType = 'fc_day_today_opt3';
        if (dailyStatus.is_paid) contentLogParams.option3 = '유료';
        else contentLogParams.option3 = '무료';
    } else if (dailyStatus && !dailyStatus.is_checked) {
      if (!isToday && dailyStatus.is_active) {
        contentLogParams.contentType = 'fc_day_not_today_opt3';
        if (dailyStatus.is_paid) contentLogParams.option3 = '유료';
        else contentLogParams.option3 = '무료';
      } 
    }
    addContentLogAPI(contentLogParams);
    // 로그 전송 후 날짜별 status 데이터를 업데이트 받아야 함.
  }

  if (info) selectedDateInfo.value = date;
  if (isDailyFortuneDrawerOpen) {
    try {      
      if (actionType === 'opened') {
        isDailyFortuneDrawerOpen.value = true;
        getDailyCategoryFreeCount(activeDate);
        await getDailyFortuneDetail({activeDate});
        updateDailyFortuneCalendarStatus(moment(date).format('YYYYMM'));
        if (dailyCategoryStatus.value && dailyCategoryStatus.value.재물운 && !dailyCategoryStatus.value?.재물운?.is_checked) {
          addContentLogAPI({
            contentType: 'fc_luck_category_free_opt3',
            option3: '재물운',
          });
        }
        await getDailyCategoryDetail(activeDate, '재물운');
      } else if (actionType !== 'past') {
        calendarApi.value.render();
      } else {
        showPointConfirm({
          title: moment(date).format('YYYY년 MM월 DD일'),
          subtitle: '운세를 구매 하시겠습니까?',
          point: formatNumber(dailyFortunePrice.value),
          confirmText: '구매',
          onConfirm: async () => await confirmPurchaseFortune({activeDate, dataKey}),
        });
      }
    } catch (e) {

    }
  }
};

const updateDailyFortuneCalendarStatus = async (yyyymm) => {
  const totalStatus = await getDailyFortuneCheckAPI();

  monthlyFortuneStatus.value = Object.fromEntries(
    Object.entries(totalStatus).filter(([key]) => key.startsWith(yyyymm))
  );
  
  calendarApi.value.render();
}

/** 등록된 '내 사주' 데이터 */
const sajuData = ref({});
/** 내 사주 정보 유무 확인 */
const isSajuDataExist = computed(() => Object.keys(sajuData.value).length !== 0);

const selectedDateInfo = ref(null)
const dailyFortuneData = ref(null);

const sajuError = ref('');

const displayPoint = ref('');
const showPointConfirm = ({title, subtitle, point, confirmText, onConfirm = () => {}}) => {
  dialogTitle.value = title;
  dialogSubtitle.value = subtitle;
  dialogText.value = confirmText;
  displayPoint.value = point;
  dialogNextUrl.value = onConfirm;
  pointConfirmRef.value.openConfirmDialog();
}

/** 확인 알림창 표시 */
const showDialogAlert = ({title, text = '', nextUrl = ''}) => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlertRef.value.openAlertDialog();
};

/** 확인/취소 알림창 표시 */
const showDialogConfirm = ({title, title2 = '', text = '', onConfirm = () => {}}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달
  dialogConfirmRef.value.openConfirmDialog()
};

/** 로그인 알림창 표시 */
const showLoginConfirm = () => {  
  showDialogConfirm({
    title: '로그인 하시면',
    title2: '일진을 보실 수 있습니다.',
    text: '로그인 하러 가기',
    onConfirm: isMobile.value
      ? () => sendMessageToApp({ type: 'login' })
      : () => router.push({
          path: '/auth/login',
          query: { to: route.fullPath}
        })
    ,
  });
};

/** 내 사주 등록 알림창 표시 */
const showSelectConfirm = () => {
  showDialogConfirm({
    title: '내 사주 등록 후,',
    title2: '일진을 보실 수 있습니다.',
    text: '내 사주 등록',
    onConfirm: () => {
      openDailyFortuneSidebar();
      openPopMySaju();
    }
  });
};

/** 포인트 충전 팝업 */
const showRechargeConfirm = () => {
  // showDialogConfirm({
  //   title: '보유 낭만 포인트가 부족합니다.',
  //   title2: '지금 바로 충전하시겠습니까?',
  //   text: '충전하기',
  //   onConfirm: () => router.push('/mypage/subscrPoint'),
  // });
  showDialogAlert({
    title: '포인트 충전 후 이용해주세요.',
    text: '확인',
    onConfirm: () => {},
  })
};

/** 디바이스 감지 */
const { isMobile } = useDeviceDetect();

/** 스낵바 */
const { snackbarRef, triggerSnackbar } = useSnackbar();

/** 일진달력 가격 조회 */
const {
  dailyFortunePrice,
  categoryPrice,
  monthlyFortunePrice,
  getDailyFortunePrice,
} = useServicePrice();

/** 일진달력 결제 관리 */
const {
  isPurchasePending,
  handlePurchaseError,
} = usePurchaseFortune({
  showRechargeConfirm,
});

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  toggleOptions,
} = useCalendarFilter({isDailyFortuneCalendarOpen});

/** 달력 생성 */
const {
  calendarApi,
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  selectedData,
  firstSeason,
  firstSeasonMonth,
  monthlyFortuneData,
  monthlyFortuneStatus,
  monthlyFortuneStatusTotal,
  todayDate,
  prev,
  next,
  isToday,
  changeViewTitle,
  setPrevSelectedDate,
  getDailyFortuneCalendar,
} = useCalendar({
  sajuError,
  isLoading,
  isLunarChecked,
  isColorChecked,
  isSeasonChecked,
  isKorChecked,
  isHolidayChecked,
  calendarType: 'mobile',
  isDailyFortuneCalendarOpen,
  userId: isMobile.value ? accessToken : userId,
  isSajuDataExist,
  dailyFortunePrice,
  openDailyFortuneDrawer,
  showDialogAlert,
  showLoginConfirm,
  showSelectConfirm,
  triggerSnackbar,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
  SWIPE_THRESHOLD: 80,
});

/** '내 사주' 등록/관리 로직 */
const {
  isMySajuLoading,
  isPopMySajuOpen,
  mySajuList,
  selectedMySajuId,
  getMySaju,
  openPopMySaju,
  closePopMySaju,
  selectMySaju,
  confirmSelectSaju,
  goManse,
} = useMySaju({
  calendarApi,
  userId: accessToken,
  sajuData,
  errorMessage,
  showDialogConfirm,
  showLoginConfirm,
  triggerSnackbar,
});

const purchaseDailyFortune = async ({activeDate, purchaseType, callback = async () => {}}) => {
  isPurchasePending.value = true;

  const [year, month, day] = activeDate.split('-');
  const params = {
    year, // 필수
    month,  // 필수
    purchaseType, // "DAY", "MONTH" (필수)
    ...(purchaseType === "DAY" && { day }), // purchaseType: "DAY" 일 때
  }

  try {
    const result = await purchaseDailyFortuneAPI(params);    

    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('운세가 구매되었습니다.');
      await getDailyFortuneCalendar(`${year} ${month}`);
      await callback();
      calendarApi.value.render();
    }
  } catch (e) {
    console.error('결제 실패:', e.response.data.detail);
    handlePurchaseError(e);
    // sajuError.value = `purchaseDailyFortune error: ${e}`;
  } finally {
    isPurchasePending.value = false;
  }
}

/** 달 전체 일진 구매 */
const purchaseMonthly = () => {
  // 로그인 체크
  if (!accessToken.value) {
    showLoginConfirm();
    return;
  }
  // 내 사주 체크
  if (!isSajuDataExist.value) {
    showSelectConfirm();
    return;
  }

  const currentDate = refCalendar.value.calendar.currentData.currentDate;
  
  showPointConfirm({
    title: moment(currentDate).format('YYYY년 MM월'),
    subtitle: '해당 월의 일진 전체를 구매 하시겠습니까?',
    point: formatNumber(monthlyFortunePrice.value),
    confirmText: '구매',
    onConfirm: async () => {          
      await purchaseDailyFortune({
        activeDate: moment(currentDate).format('YYYY-MM-DD'),
        purchaseType: 'MONTH',
      });
    },
  })
}

/** '카테고리 별 운세(생활운세)' 구매 */
const purchaseDailyCategory = async ({activeDate, category, categoryPrice}) => {
  isPurchasePending.value = true;

  const params = {
    activeDate,  // yyyy-mm-dd
    category, // "STUDY", "WORK", "WEALTH", "LOVE", "HEALTH", "FAMILY", "MOVE", "RELATIONSHIP", "FAME", "SOCIAL"
    categoryPrice,  // 차감 포인트
  }

  try {
    const result = await purchaseDailyCategoryAPI(params);    
    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('운세가 구매되었습니다.');
      await getDailyCategoryDetail(activeDate, category);
      setSelectedCategory(category);
      addContentLogAPI({
        contentType: 'fc_luck_category_paid_opt3',
        option3: category,
      });
    }
  } catch (e) {
    DEV_MODE && console.error('카테고리 별 운세(생활운세) 구매 실패', e);
    handlePurchaseError(e);
  } finally {
    isPurchasePending.value = false;
  }
}

/** 일운보기 결제 */
const confirmPurchaseFortune = async ({activeDate, dataKey}) => {  
  // 결제 로직
  await purchaseDailyFortune({
    activeDate,
    purchaseType: 'DAY',
    callback: async () => {
      openDailyFortuneDrawer({info: {day: activeDate}, actionType: 'opened'});
      addContentLogAPI({
        contentType: 'fc_day_not_today_opt3',
        option3: '유료',
      });
    },
  });
}

/** 날짜별 무료 열람 가능한 카테고리 갯수 요청 */
const getDailyCategoryFreeCount = async (activeDate) => {
  const params = {
    activeDate,
  }

  try {
    const result = await getDailyCategoryFreeCountAPI(params);
    freeCategoryCount.value = result;
  } catch (e) {
    console.error('카테고리 별 운세(생활운세) 무료 카운트 조회 실패:', e);
    sajuError.value = `getDailyCategoryFreeCount error: ${e}`;
  }
}

/** 일운보기 상세 데이터 요청 */
const getDailyFortuneDetail = async ({activeDate, displayLoading = true}) => {
  if (displayLoading) {
    isDailyFortuneLoading.value = true;
    isDailyFortuneLoaded.value = false;
  }

  const params = {
    activeDate,
  };

  try {
    const result = await getDailyFortuneDetailAPI(params);
    
    if (result) {
      dailyFortuneDetail.value = result.data;
      dailyCategoryStatus.value = result.status;
      if (displayLoading) {
        isDailyFortuneLoaded.value = true;
      }
    } else {
      if (displayLoading) {
        isDailyFortuneLoaded.value = false;
      }
    }
  } catch (e) {
    DEV_MODE && console.error('일운보기 상세 데이터 조회 실패:', e);
    sajuError.value = `getDailyFortuneDetail error: ${e}`;
    errorMessage.value = e?.response?.data.detail || e;
    if (displayLoading) {
      isDailyFortuneLoaded.value = false;
    }
  } finally {
    if (displayLoading) {
      isDailyFortuneLoading.value = false;
    }
    isCategoryLoading.value = false;
  }
}

/** 일운보기 상세 화면 */
const {
  freeCategoryCount,
  dailyFortuneDetail,
  dailyCategoryStatus,
  luckButtonDisabled,
  selectedCategoryList,
  selectedCategory,
  dailyCategoryDetail,
  dailyCategoryRecommend,
  // clickCategoryButton,
  setSelectedCategory,
  getDailyCategoryDetail,
  getDailyCategoryRecommend,
  resetDailyFortuneData,
} = useDailyFortune({
  lifeLuckChartData,
  isCategoryLoading,
  isCategoryLoaded,
  isCategoryRecommendLoading,
});

/** '카테고리 별 운세(생활운세)' 버튼 클릭 */
const clickCategoryButton = async (label) => {
  dailyCategoryRecommend.value = null;
  const formattedSelectedDate = moment(selectedDateInfo.value).format('YYYY-MM-DD');

  if (dailyCategoryStatus.value && dailyCategoryStatus.value[label] && !dailyCategoryStatus.value[label].is_checked) {
    let logParams = {
      contentType: '',
      option3: label,
    };
    if (dailyCategoryStatus.value[label].is_active && dailyCategoryStatus.value[label].is_paid) {
      logParams.contentType = 'fc_luck_category_paid_opt3';
    } else if (dailyCategoryStatus.value[label].is_active && !dailyCategoryStatus.value[label].is_paid) {
      logParams.contentType = 'fc_luck_category_free_opt3';
    } else if (!dailyCategoryStatus.value[label].is_active && !dailyCategoryStatus.value[label].is_paid && selectedCategoryList.value.length < freeCategoryCount.value) {
      logParams.contentType = 'fc_luck_category_free_opt3';
    }
    addContentLogAPI(logParams);
  }

  if (dailyCategoryStatus.value && dailyCategoryStatus.value[label]['is_active']) {
    // 열어본 운세일 때,
    await getDailyCategoryDetail(formattedSelectedDate, label);
  } else {
    // 열어보지 않은 운세일 때,
    if (selectedCategoryList.value.length < freeCategoryCount.value) {
      // 무료 열람일 때,
      await getDailyCategoryDetail(formattedSelectedDate, label);
    } else {
      // 무료 열람가능 개수를 넘었을 때,
      showPointConfirm({
        title: label,
        subtitle: '운세를 구매 하시겠습니까?',
        point: formatNumber(categoryPrice.value),
        confirmText: '구매',
        onConfirm: async () => {          
          await purchaseDailyCategory({
            activeDate: formattedSelectedDate,
            category: label,
            categoryPrice: categoryPrice.value
          });
        },
      })
    }
  }
};

onMounted(async () => {
  if (!DEV_MODE) router.push('/empty');

  if (!logsStore.logContents) {
    const result = await getLogStatsAPI();
    logsStore.setLogContents(result);
  }
  getDailyFortunePrice();
  document.addEventListener('message', handleMessage);
  window.addEventListener('message', handleMessage);
})

onUnmounted(() => {
  document.removeEventListener('message', handleMessage);
  window.removeEventListener('message', handleMessage);
})

watch(() => errorMessage.value, (newError) => {
  // if (DEV_MODE) sendMessageToApp({type: 'error', message: newError});
  // if (DEV_MODE) triggerSnackbar(`${JSON.stringify(newError)}`);
})

watch([() => accessToken.value, () => userId.value], ([newToken, newUserId]) => {
  if (newToken) {
    paymentsStore.getSubscription();
    paymentsStore.getOwnPoint();
  }
  if (newUserId) {    
    paymentsStore.getSubscription();
    paymentsStore.getOwnPoint();
  }
}, {immediate: true})

const handleMessage = async (event) => {
  try {
    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
    } else {
      data = event.data;
    }

    // React DevTools 메시지 무시
    if (data.source === 'react-devtools-content-script') {
      console.log('Ignoring React DevTools message.');
      return;
    }

    if (data?.type === 'focusOut') {
      isPopSajuClock.value = false;
      isPopMySajuOpen.value = false;
      closeDailyFortuneSidebar();
      closeDailyFortuneDrawer();
      dialogAlertRef.value.close();
      dialogConfirmRef.value.close();
      pointConfirmRef.value.close();
      return;
    }
    if (data?.type === 'focusIn' && (accessToken.value || userId.value)) {
      await getMySaju();
      paymentsStore.getSubscription();
      paymentsStore.getOwnPoint();
      return;
    }

    if (data) {
      if (!isMobile.value) {
        accessToken.value = store.getters['user/token'];
        userId.value = store.getters['user/id'];
      } else {
        accessToken.value = data.accessToken;
        userId.value = data.authUserId;
        if (accessToken.value) {
          // sajuError.value = `handleMessage get data: ${accessToken.value}`
          axios.defaults.headers.common['Authorization'] = accessToken.value;
          axios.defaults.headers.common['authUserId'] = userId.value;
          getMySaju();
        }
      }
    }
  } catch (e) {
    console.error('Invalid message received:', `${e}`);
    sajuError.value = `handleMessage error: ${e}`;
  }
};

watch(sajuError, (newError) => {
  if (newError) {
    if (isMobile.value) {
      // console.log('========================================')
      // console.log('newError:', newError)
      // console.log('========================================')
      
      // sendMessageToApp({ type: 'error', message: `${newError}` });
    }
  }
});

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  selectedDate.value = moment().format('YYYY-MM-DD')
  changeViewTitle();
  addContentLogAPI('C_btn_today');
};

/** 사주시계 팝업  */
const setSajuClock = (state) => {
  if (state) addContentLogAPI('C_saju_watch');
  isPopSajuClock.value = state;
}

/** 역학달력/일진달력 전환 버튼 */
const clickBottomLabel = async () => {
  if (isDailyFortuneCalendarOpen.value) {
    addContentLogAPI('C_tab_saju_calendar');
    isDailyFortuneCalendarOpen.value = false;
  } else {
    if (!accessToken.value) sendMessageToApp({ type: 'noToken' });

    if (DEV_MODE && !isSajuDataExist.value) {
      await getMySaju();
    } else {
      // if (accessToken.value && !isSajuDataExist.value) {
      //   getMySaju();
      // }
    }
    addContentLogAPI('C_tab_luck_calendar');
    isDailyFortuneCalendarOpen.value = true;
  };
}

const sendMessageToApp = ({type, message, route}) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type,
        message: message || errorMessage.value,
        route,
      }
    ));
    errorMessage.value = '';
  }
};

/** 일진보기 Drawer 닫기 */
const closeDailyFortuneDrawer = () => {
  if (isDailyFortuneDrawerOpen.value) {
    isDailyFortuneDrawerOpen.value = false;
    resetDailyFortuneData();
  }
};

/** 일운보기 상세 기본 형식 */
const DEFAULT_DAILY_FORTUNE_DETAIL = {
  iljuSky: '',
  iljuEarth: '',
  iljuSkyKor: '',
  iljuEarthKor: '',
  iljuSkyClass: '',
  iljuEarthClass: '',
  iljuSkyFiveChn: '',
  iljuSkyFiveKor: '',
  iljuEarthFiveChn: '',
  iljuEarthFiveKor: '',
  iljuSkyYinyang: '',
  iljuEarthYinyang: '',
  오늘날짜: '',
  갑자의미: '',
  점수: '',
  확률: '',
  나의음양오행: {
    elementCount: { 목: 0, 화: 0, 토: 0, 금: 0, 수: 0 },
    yinCount: 0,
    yangCount: 0,
  },
  오늘음양오행: {
    elementCount: { 목: 0, 화: 0, 토: 0, 금: 0, 수: 0 },
    yinCount: 0,
    yangCount: 0,
  },
};

const getLevel = (value) => {        
  if (value === 100) return 6;
  if (value >= 80) return 5;
  if (value >= 60) return 4;
  if (value >= 40) return 3;
  if (value > 20) return 2;
  return 1;
}

/** 일운보기 상세 데이터 객체 */
const dailyCustomObject = computed(() => {
  if (!dailyFortuneDetail.value || !selectedData.value) return DEFAULT_DAILY_FORTUNE_DETAIL;

  const detail = dailyFortuneDetail.value;
  const [iljuSky, iljuEarth] = selectedData.value?.dayju.split('') || ['', ''];
  const [iljuSkyKor, iljuEarthKor] = selectedData.value?.handayju.split('') || ['', ''];
  
  const object = {
    iljuSky: iljuSky || '',
    iljuEarth: iljuEarth || '',
    iljuSkyKor: iljuSkyKor || '',
    iljuEarthKor: iljuEarthKor || '',
    iljuSkyClass: iljuSky ? getColorFive(iljuSky) : '',
    iljuEarthClass: iljuEarth ? getColorFive(iljuEarth) : '',
    iljuSkyFiveChn: iljuSky ? getSkyStemFive(iljuSky).chn : '',
    iljuSkyFiveKor: iljuSky ? getSkyStemFive(iljuSky).kor : '',
    iljuEarthFiveChn: iljuEarth ? getEarthBranchFive(iljuEarth).chn : '',
    iljuEarthFiveKor: iljuEarth ? getEarthBranchFive(iljuEarth).kor : '',
    iljuSkyYinyang: iljuSky ? getYinYang(iljuSky, 'sky') : '',
    iljuEarthYinyang: iljuEarth ? getYinYang(iljuEarth, 'earth') : '',
    오늘날짜: selectedData.value.day, // yyyy-mm-dd
    갑자의미: detail['갑자의미'],
    확률: detail['일운종합']['일운']['달성확률'],
    점수: getLevel(detail['일운종합']['일운']['달성확률']),
    나의음양오행: {
      elementCount: detail.음양오행통계.오행갯수,
      yangCount: detail.음양오행통계.음양갯수['p_value'] - detail.음양오행통계.음양갯수['dl_p_value'],
      yinCount: detail.음양오행통계.음양갯수['m_value'] - detail.음양오행통계.음양갯수['dl_m_value']
    },
    오늘음양오행: {
      elementCount: getDayElementCount(detail.사주정보.today.cg.split('(')[0]),
      yangCount: detail.음양오행통계.음양갯수['dl_p_value'],
      yinCount: detail.음양오행통계.음양갯수['dl_m_value']
    },
  };

  return object;
});

const clickRoute = () => {
  sendMessageToApp({ type:'route', route: { tab: 'PerpetualInfoContainer' } });
  nextTick(() => {
    closePopMySaju();
    closeDailyFortuneSidebar();
  });
}

const openDailyFortuneSidebar = () => {
  isDailyFortuneSidebarOpen.value = true;
}

const closeDailyFortuneSidebar = async () => {
  isDailyFortuneSidebarOpen.value = false;
  await getDailyFortuneCalendar(viewTitle.value);
}

const sajuganjiArray = ref([]);
const splitToSkyEarth = (sajugangi) => {
  if (!sajugangi) return;
  if (sajugangi.length !== 8) {
    throw new Error("문자열 길이가 8이 아닙니다.");
  }
  const positionNames = ['시', '일', '월', '년'];
  // 앞 4글자
  const skyArr = sajugangi.slice(0, 4).split("");
  // 뒤 4글자
  const earthArr = sajugangi.slice(4).split("");

  // skyArr[i]와 earthArr[i]를 묶어서 객체 만들기
  return skyArr.map((char, i) => ({
    sky: char,
    earth: earthArr[i],
    pos: positionNames[i],
  }));
};
watch(sajuData, (newData) => {
  if (newData) {
    sajuganjiArray.value = splitToSkyEarth(newData.sajugangi); 
  }
})
</script>

<template>
  <div class="calendar-wrap mobile">
    <VNavigationDrawer
      v-show="isDailyFortuneSidebarOpen && DEV_MODE"
      v-model="isDailyFortuneSidebarOpen"
      app
      temporary
      location="right"
      width="100px"
      class="drawer-mobile-w100"
    >
      <div v-if="!isPopMySajuOpen" class="header">
        <div class="title">일진달력</div>
        <button class="close" @click="closeDailyFortuneSidebar"></button>
      </div>
      <div v-if="!isPopMySajuOpen" class="calendar-sidebar mobile">
        <div class="subscription-section mobile">
          <div v-if="(accessToken || userId) && !paymentsStore.subInfo" class="sub-content">
            <div class="plan-info">
              오늘의 날씨처럼, 오늘의 일진과 특별한 하루를<br>낭만 만세력에서 확인해 보세요!
            </div>
            <div class="plan-info">
              <div class="plan-name">지금 저장소 멤버십 시작하기</div>
              <div class="plan-period link" @click="clickService">
                서비스 안내 바로가기
                <i class="arrow"></i>
              </div>
            </div>
          </div>
          <div v-if="(accessToken || userId) && paymentsStore.subInfo" class="sub-content">
            <div class="plan-info">
              <div class="plan-name">{{ paymentsStore.formattedSubInfoName }}</div>
              <div class="plan-period">{{ paymentsStore.formattedSubInfoPeriod }}</div>
            </div>
            <div class="point-info">
              <div class="point"><i class="point"></i>{{ paymentsStore.formattedPoints }} p</div>
              <button class="recharge" @click="clickRecharge">충전</button>
            </div>
          </div>
        </div>

        <div v-if="!isLoading && isDailyFortuneCalendarOpen" class="section">
          <div v-if="!isSajuDataExist" class="note">
            <i class="pin"></i>
            <div>내 사주를 선택하면,<br />나의 일진을 확인할 수 있습니다.</div>
            <button
              class="mysaju-button"
              @click="openPopMySaju"
            >
              내 사주 선택하기
            </button>
          </div>

          <div v-else class="mysaju-box">
            <div class="info-box">
              <div class="ilgan-icon">
                {{ mySajuObject.ilgan }}
              </div>
              <div class="name-box">
                <div v-if="mySajuObject.name" class="name">
                  <div class="name-text">{{ mySajuObject.name }}</div>
                  <button
                    :class="['button', { close: isOpenSajuInfo }]"
                    @click="isOpenSajuInfo = !isOpenSajuInfo"
                  ></button>
                </div>
                <div v-else class="name">-</div>
                <div class="age">
                  {{ mySajuObject.gender }} {{ mySajuObject.ageKor }} {{ mySajuObject.age }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="isSajuDataExist && isOpenSajuInfo" class="divider"></div>
          <div v-if="isSajuDataExist && isOpenSajuInfo" class="mysaju-box">
            <div class="birth-box">
              <div class="birth-item">
                <span class="label solar">양력</span>
                <span class="txts">
                  {{ mySajuObject.solar }}
                  {{ mySajuObject.week }}
                  {{ mySajuObject.time }}
                </span>
              </div>
              <div class="birth-item">
                <span class="label lunar">음력</span>
                <span class="txts">{{ mySajuObject.lunar }}</span>
              </div>
              <div class="birth-item">
                <span class="label">옵션</span>
                <span class="txts">
                  <span v-if="mySajuObject.city">
                    {{ `${mySajuObject.city},` }}&nbsp;
                  </span>
                  {{ mySajuObject.korTime }}
                  {{ mySajuObject.earlyTime }}
                  {{ mySajuObject.season }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="false && isSajuDataExist && isOpenSajuInfo && sajuganjiArray" class="birth-item" style="width: 100%;">
            <span class="label" style="color: transparent">사주</span>
            <div style="display: flex; flex-direction: row; gap: 14px; width: 100%; justify-content: flex-start;">
              <div
                v-for="(item, index) in sajuganjiArray"
                :key="index"
                class="saju-element__column-default"
              >
                <div class="saju-element__icon md" :class="getColorFive(item.sky)">
                  <div class="saju-element__icon-text">{{ item.sky }}</div>
                </div>
                <div class="saju-element__icon md" :class="getColorFive(item.earth)">
                  <div class="saju-element__icon-text">{{ item.earth }}</div>
                </div>
                <div class="saju-element__column-label">
                  {{ item.pos }}
                </div>
              </div>
            </div>
            <div>

            </div>
          </div>
        </div>

        <button v-if="isDailyFortuneCalendarOpen" class="section calendar-banner-mobile">
          <i class="icon-cal"></i>
          <div class="text">일진달력을<br>활용하는 방법은?</div>
        </button>

        <div v-if="false && isDailyFortuneCalendarOpen" class="section">
          <div class="promotion">
            <div class="promotion-info">
              <i class="icon point"></i>
              <div class="text">
                포인트로 이번 달<br>전체 일진 보기
              </div>
            </div>  
            <button
              class="promotion-button"
              @click="purchaseMonthly"
            >
              {{ formatNumber(monthlyFortunePrice) }} p
            </button>
          </div>
        </div>

        <div v-if="false && DEV_MODE" class="section">
          <div class="promotion">
            <div class="promotion-info">
              <i class="icon crown"></i>
              정기구독 이용하고,<br>무료로 일진 보기
            </div>  
            <button
              class="promotion-button sub"
              @click="handleShowSub"
            >
              구독상품 보기
            </button>
          </div>
        </div>
      </div>
      
      <div v-if="isPopMySajuOpen" style="background-color: #fff;">
        <div class="popup-container mobile">
          <div class="header">
            <div class="title">내 사주 선택</div>
            <button class="close" @click="closePopMySaju"></button>
          </div>

          <div class="popup-body">
            <div class="section">
              * 저장목록 > 내사주 그룹에 있는 사주만 선택 가능합니다.<br>
              * 등록 후, 변경이 어려우니 신중해 주세요!
            </div>

            <div v-if="!mySajuList || mySajuList.length === 0" class="section empty">
              <div>
                사주를 [내 사주] 그룹으로<br>새롭게 저장해 주세요!
              </div>
              <button class="add-button" @click="clickRoute">사주 조회하러 가기</button>
            </div>

            <div v-else class="section list">
              <div
                v-for="(item, index) in mySajuList"
                :key="index" 
                :class="['mysaju-item-box', 'a-link', {'selected': selectedMySajuId === item.guestUserId}]"
                @click="selectMySaju(item.guestUserId)"
              >
                <div class="ganji-box-l">
                  <span
                    class="first ganji-txt1"
                    :class="getColorFive(formatGanJi(item?.sajugangi, 2))"
                    >{{ formatGanJi(item?.sajugangi, 2) }}
                  </span>
                  <span
                    class="second ganji-txt1"
                    :class="getColorFive(formatGanJi(item?.sajugangi, 6))"
                    >{{ formatGanJi(item?.sajugangi, 6) }}</span
                  >
                </div>

                <div class="birth-box">
                  <div class="name-box row">
                    <span v-if="item.guestUserName" class="name">{{ item.guestUserName }}</span>
                    <span v-else class="name">-</span>
                    <span class="age"> {{ useFunc.getGenderTxt(item.gender) }} {{ item?.ageKor }}세 (만{{ item?.age }}세) </span>
                  </div>

                  <div class="name-row">
                    <div class="birth-item">
                      <span class="label solar">양력</span>
                      <span class="txts"
                        >{{ useFunc.formatCustomDate(item?.birth) }}({{
                          useFunc.getDayOfWeekOne(item.birth)
                        }}) {{ useFunc.getBirthTimeTxt(item.birthTime) }}
                      </span>
                    </div>

                    <div class="birth-item">
                      <span class="label lunar">음력</span>
                      <span class="txts">{{ useFunc.formatCustomDate(item?.lunar) }}</span>
                    </div>
                  </div>

                  <div class="birth-item">
                    <span class="label">옵션</span>
                    <span class="txts">
                      {{ item?.bornAreaInfo?.korCityName }}
                      <span v-if="item?.bornAreaInfo?.korCityName">,</span>
                      {{ useFunc.formatOption4Txt(item?.option4) }}
                      {{ useFunc.formatOption1Txt(item?.option1) }}
                      {{ useFunc.formatOption2Txt(item?.option2) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="mySajuList && mySajuList.length !== 0" class="footer">
            <button 
              :class="['confirm', {'active': selectedMySajuId}]"
              :disabled="!selectedMySajuId"
              @click="confirmSelectSaju"
            >
              선택
            </button>
          </div>
        </div>
      </div>
    </VNavigationDrawer>
    <ToggleDrawer
      v-show="isDrawerOpen"
      v-model="isDrawerOpen"
      :toggleOptions="toggleOptions"
      @close="isDrawerOpen = false"
    />
    <DailyFortuneDrawer
      v-show="isDailyFortuneDrawerOpen"
      v-model:isDailyFortuneDrawerOpen="isDailyFortuneDrawerOpen"
      :selectedData="selectedData"
      :dailyFortuneData="dailyFortuneData"
      :dailyFortuneDetail="dailyFortuneDetail"
      :dailyCategoryDetail="dailyCategoryDetail"
      :dailyCategoryRecommend="dailyCategoryRecommend"
      :dailyCustomObject="dailyCustomObject"
      :sajuData="sajuData"
      :lifeLuckChartData="lifeLuckChartData"
      :dailyCategoryStatus="dailyCategoryStatus"
      :isDailyFortuneLoading="isDailyFortuneLoading"
      :isDailyFortuneLoaded="isDailyFortuneLoaded"
      :isCategoryLoading="isCategoryLoading"
      :isCategoryLoaded="isCategoryLoaded"
      :isCategoryRecommendLoading="isCategoryRecommendLoading"
      :errorMessage="errorMessage"
      :selectedCategoryList="selectedCategoryList"
      :selectedCategory="selectedCategory"
      :freeCategoryCount="freeCategoryCount"
      :categoryPrice="categoryPrice"
      :luckButtonDisabled="luckButtonDisabled"
      @clickCategoryButton="clickCategoryButton"
      @closeDrawer="closeDailyFortuneDrawer"
      @getDailyCategoryRecommend="getDailyCategoryRecommend" 
    />

    <div class="content-main">
      <section class="inner-base">
        <div>
          <CalendarHeaderMobile
            :calendarType="'mobile'"
            :title="viewTitle"
            :isToday="isToday()"
            :solarTerm="firstSeason"
            :solarTermMonth="firstSeasonMonth"
            :isColorChecked="isColorChecked"
            :isDailyFortuneCalendarOpen="isDailyFortuneCalendarOpen"
            @prev="prev"
            @next="next"
            @today="today"
            @clock="setSajuClock(true)"
            @option="isDrawerOpen = true"
            @sidebar="isDailyFortuneSidebarOpen = true"
          />

          <div class="sec-sch-body mobile">
            <v-row
              class="fill-height"
              @mousedown="handleStart"
              @mousemove="handleMove"
              @mouseup="handleEnd"
              @touchstart="handleStart"
              @touchmove="handleMove"
              @touchend="handleEnd"
            >
              <v-col>
                <v-sheet>
                  <FullCalendar
                    ref="refCalendar"
                    class="full-calendar mobile"
                    :options="calendarOptions"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </div>

          <div class="bottom-label" :class="{default: isDailyFortuneCalendarOpen}">
            <div class="summary">{{ isDailyFortuneCalendarOpen ? '역학으로 보는 현재시간과 절기!' : '나의 일진이 궁금하다면?' }}</div>
            <button class="button" @click="clickBottomLabel">{{isDailyFortuneCalendarOpen ? '편리한 역학달력' : '일진보기' }}<i class="arrow"></i></button>
          </div>
        </div>
      </section>
    </div>

    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />
  
    <AlertComponent
      ref="dialogAlertRef"
      :title="dialogTitle"
      :text="dialogText"
    />

    <ConfirmComponent
      ref="dialogConfirmRef"
      :title="dialogTitle"
      :title2="dialogTitle2"
      :text="dialogText"
      :nextUrl="dialogNextUrl"
    />

    <PointConfirm
      ref="pointConfirmRef"
      :title="dialogTitle"
      :subtitle="dialogSubtitle"
      :point="displayPoint"
      :nextUrl="dialogNextUrl"
      :confirm-text="'구매'"
    />

    <Snackbar ref="snackbarRef" />

    <LoadingIndicator v-if="isLoading"/>
  </div>
</template>

<style lang="scss">
.intro-layout {
  width: 100vw;
  height: 100%;
  .main {
    padding: 100px !important;
    margin: 0 !important;
  }
}

.scroll-to-top {
  display: none !important;
}
</style>