<script setup>
const props = defineProps({
  contents: Object,
  curIlgan: String,
})
const { ...five } = fiveMixin()

/** 본질 키워드 굵게 */
const parseBoldText = (text) => {
  if (!text) return '';

  // "의 본질은" 뒤에 나오는 "" / '' / ‘ ’ 안의 내용을 감싸는 정규식
  return text.replace(/의 본질은\s*([\"'‘’])([^\"'‘’]+)[\"'‘’]/g, '의 본질은 <span class="bold-text">$1$2$1</span>');
};

// 변환된 데이터를 계산 속성(computed)으로 저장
const formattedDescriptions = computed(() =>
  props.contents.body.description.map(desc => parseBoldText(desc))
);
</script>

<template>
    <div class="cont-pages">
        <div class="tit">
            <span class="main-tit ganji-txt" :class="five.getFiveClass(five.getGanjiColorFive(props.curIlgan))">
              {{ contents.icon }} {{ contents.title }}
            </span>            
        </div>
        <div class="inner-cont" style="gap: 16px;">
            <span v-for="desc in formattedDescriptions" class="nor-txt" v-html="desc"></span>
        </div>
    </div>
</template>