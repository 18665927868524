<script setup>
import { onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import moment from 'moment'

import { DEV_MODE } from '@/main'

/** API */
import { calendarFilter } from '@/api/EowayApi'
import { getGroupUserlist } from '@/api/UserGroup'
import { fastSearch } from '@/api/Eoway'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import { VBottomSheet, VNavigationDrawer } from 'vuetify/lib/components/index.mjs'
import CalendarHeaderMobile from '@/components/calendar/CalendarHeaderMobile.vue'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import ToggleBox from '@/components/calendar/ToggleBox.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'

/** 스타일 */
import '@/assets/nangman_style/calendar/calendar_mobile.scss'
import { addContentLogAPI, getLogStatsAPI } from '@/api/logServiceApi'
import BottomLabel from '@/components/calendar_mobile/BottomLabel.vue'
import { useLogsStore } from '@/store/modules/logsStore'
import LoadingIndicator from '@/components/common/LoadingIndicator.vue'

definePage({ meta: { layout: 'empty' } })

const logsStore = useLogsStore();

const { getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { ...useFunc } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlert = ref(null);
const dialogTitle = ref('');
const dialogText = ref('');

/** 역학달력 Drawer */
const isDrawerOpen = ref(false);
/** 일진달력 Drawer */
const isTodayLuckDrawerOpen = ref(false);

const isTodayLuckChecked = ref(false);

/** 모바일 사용자 토큰 */
const accessToken = ref(null);
/** 모바일 사용자 ID */
const authUserId = ref(null);
const userId = ref(null);

const isDailyFortuneDrawerOpen = ref(false);

const openDailyFortuneDrawer = () => {
  sajuError.value = isDailyFortuneDrawerOpen.value
  // if (!userId.value) return;
  // if (info) selectedDateInfo.value = info;
  if (isDailyFortuneDrawerOpen) {
    // const dataKey = moment(info.date).format('YYYYMMDD');
    // dailyLuckData.value = monthlyLuckData[dataKey];
    isDailyFortuneDrawerOpen.value = true;
  } // 이너뷰 열기
}

const openTodayLuckDrawer = () => {
  sajuError.value = isTodayLuckDrawerOpen.value
  if (isTodayLuckDrawerOpen) {
    isTodayLuckDrawerOpen.value = true;
  }
}

const sajuListParam = ref({
  birthStart: null,
  birthEnd: null,
  startDate: null,
  endDate: null,
  genderOption: 0,
  page: 1,
  size: 10,
  searchKeyWord: '',
  sortOption: null,
  userGroupId: null,
  activeOpt1: false,
  activeOpt2: false,
  activeOpt3: false,
  activeOpt4: false,
  fiveEles: [],
  gender: null,
  filterFlag: false,
  isFavorite: null,
})

const sajuParamData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
});

const sajuData = ref({});
/** '내 사주' 목록 */
const mySajuList = ref({});
/** 선택된 '내 사주' id */
const selectedMySajuId = ref(null);

const sajuError = ref('');

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  toggleOptions,
} = useCalendarFilter();

/** 달력 생성 */
const {
  calendarApi,
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
} = useCalendar({
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  calendarType: 'mobile',
  isTodayLuckChecked,
  userId,
  sajuData,
  openDailyFortuneDrawer,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

onMounted(async () => {
  if (!logsStore.logContents) {
    const result = await getLogStatsAPI();
    logsStore.setLogContents(result);
  };
  window.addEventListener('message', handleMessage);
})

onUnmounted(() => {
  window.removeEventListener('message', handleMessage);
})

const handleMessage = async (event) => {
  try {
    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
    } else {
      data = event.data;
    }

    // React DevTools 메시지 무시
    if (data.source === 'react-devtools-content-script') {
      console.log('Ignoring React DevTools message.');
      return;
    }

    if (data) {
      accessToken.value = data.accessToken;
      // authUserId.value = data.authUserId;
      // userId.value = data.authUserId;
    }
  } catch (e) {
    console.error('Invalid message received:', `${e}`);
  }
};

const getGrouplistAPI = async () => {
  let url = `/users/userGroups`;
  axios.defaults.headers.common['Authorization'] = accessToken.value;
  const response = await axios.get(url);
  return response.data;
}

const getSajuParams = async (id) => {
  if (!id) return;

  try {
    const result = await getDetail(id);

    const dateTime = result.integrationBirth.split('T');
    const [year, month, day] = dateTime[0].split('-');
    const [hour, min] = dateTime[1]?.split(':');    
    
    sajuParamData.value.year = year;
    sajuParamData.value.month = month.padStart(2, '0');
    sajuParamData.value.day = day.padStart(2, '0');
    sajuParamData.value.hour = hour.padStart(2, '0');
    sajuParamData.value.min = min.padStart(2, '0');
    sajuParamData.value.gender = result.gender === 'MEN' ? 1 : 0;
    sajuParamData.value.userName = result.guestUserName;
    sajuParamData.value.isLunar = result.isLuna === null ? false : result.isLuna;
    sajuParamData.value.lunar = result.lunar;
    sajuParamData.value.isLeapYear = result.isLeapYear;
    sajuParamData.value.bornAreaId = result.bornAreaId;
    sajuParamData.value.birthTime = result.birthTime;
    sajuParamData.value.unknownTime = result.unknownTime
    sajuParamData.value.option1 = result.option1;
    sajuParamData.value.option2 = result.option2;
    sajuParamData.value.option3 = result.option3;
    sajuParamData.value.option4 = result.option4;
    sajuParamData.value.option5 = result.option5;
    sajuParamData.value.addressTxt = result.bornArea;
    // sajuParamData.value.lang = result.lang
    // sajuParamData.value.langYN = result.langYN
    // sajuParamData.value.userGroupId = result.userGroupId
  } catch (e) {
    DEV_MODE && console.error('사주정보 조회 오류:', e);
    sajuError.value = e;
  };
};

const padNumber = (num) => num.toString().padStart(2, '0');

const convertManseForceParams = () => {
  const { year, month, day, hour, min } = sajuParamData.value;

  sajuParamData.value.birth = `${year}-${padNumber(month)}-${padNumber(day)}`;  
  
  if (sajuParamData.value.birthTime === '25:00') {
    sajuParamData.value.unknownTime = 'Y';
  } else {
    sajuParamData.value.birthTime = `${padNumber(hour)}:${padNumber(min)}`;
  }
  if (!sajuParamData.value.unknownTime) sajuParamData.value.unknownTime = 'N';
  if (!sajuParamData.value.isLeapYear) sajuParamData.value.isLeapYear = false;
  if (sajuParamData.value.bornAreaId === null) delete sajuParamData.value.bornAreaId;
}

const getManseForce = async () => {
  convertManseForceParams();

  try {
    const result = await fastSearch(sajuParamData.value);    
    sajuData.value = result;
  } catch (e) {
    DEV_MODE && console.error('만세력 조회 오류:', e);
    sajuError.value = `getManseForce: ${e}`;
  }
}



const showDialogAlert = (title, text = '', nextUrl = '') => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlert.value.openAlertDialog();
};

watch (() => accessToken.value, async (newToken) => {
  if (DEV_MODE && newToken) {

    try {
      const groupList = await getGrouplistAPI();

      if (groupList && groupList.length > 0) {
        sajuListParam.userGroupId = groupList[0].userGroupId;
        const result = await getGroupUserlist(sajuListParam);

        mySajuList.value = result.content;
        if (mySajuList.value && mySajuList.value.length > 0) {
          const guestUserId = mySajuList.value[mySajuList.value.length - 1].guestUserId;
          if (guestUserId) {
            await getSajuParams(guestUserId);
            await getManseForce();
          }
        }
      }
    } catch (e) {
      sajuError.value = e
    }
  }
});

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  selectedDate.value = moment().format('YYYY-MM-DD')
  changeViewTitle();
};

/** 사주시계 팝업  */
const setSajuClock = (state) => {
  if (state) addContentLogAPI('C_saju_watch');
  isPopSajuClock.value = state;
}

const clickBottomLabel = () => {
  showDialogAlert('일진달력 서비스 오픈 준비 중\n여러분의 일상에 새로운 의미를 더해드릴게요.\n많은 기대와 관심으로 조금만 기다려주세요!');
  if (isTodayLuckChecked.value) {
    addContentLogAPI('C_tab_saju_calendar');
    // isTodayLuckChecked.value = false;
  } else {
    addContentLogAPI('C_tab_luck_calendar');
    // isTodayLuckChecked.value = true;
  };
}

/** '내 사주' 클릭 */
const selectMySaju = async (id) => {
  selectedMySajuId.value = id;
  userId.value = id;
}

const confirmSelectSaju = async () => {
  // closePopMySaju();
  isTodayLuckDrawerOpen.value = false;
  if (selectedMySajuId) {
    await getSajuParams(selectedMySajuId.value);
    await getManseForce();
    calendarApi.value.render();
  }
}
</script>

<template>
  <div class="calendar-wrap mobile">
    <VNavigationDrawer
      v-if="isDrawerOpen"
      v-model="isDrawerOpen"
      app
      temporary
      location="right"
      class="drawer-mobile"
    >
      <div class="drawer-header">
        역학달력 옵션
        <button class="close" @click="isDrawerOpen = false"></button>
      </div>
      <ToggleBox :options="toggleOptions"/>
    </VNavigationDrawer>

    <div class="content-main">
      <section class="inner-base">
        <div>
          <CalendarHeaderMobile
            :calendarType="'mobile'"
            :title="viewTitle"
            :isToday="isToday()"
            :solarTerm="firstSeason"
            :solarTermMonth="firstSeasonMonth"
            :isColorChecked="isColorChecked"
            @prev="prev"
            @next="next"
            @today="today"
            @clock="setSajuClock(true)"
            @option="isDrawerOpen = true"
          />

          <div class="sec-sch-body mobile">
            <v-row
              class="fill-height"
              @mousedown="handleStart"
              @mousemove="handleMove"
              @mouseup="handleEnd"
              @touchstart="handleStart"
              @touchmove="handleMove"
              @touchend="handleEnd"
            >
              <v-col>
                <v-sheet>
                  <FullCalendar
                    ref="refCalendar"
                    class="full-calendar mobile"
                    :options="calendarOptions"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </div>

          <BottomLabel
            :isTodayLuckChecked="isTodayLuckChecked"
            @clickBottomLabel="clickBottomLabel"
          />
        </div>
      </section>
    </div>

    <LoadingIndicator v-if="isLoading"/>
  
    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />
  
    <AlertComponent
      ref="dialogAlert"
      :title="dialogTitle"
      :text="dialogText"
    />
  </div>
</template>

<style lang="scss">
.intro-layout {
  width: 100vw;
  height: 100%;
  .main {
    padding: 100px !important;
    margin: 0 !important;
  }
}

.scroll-to-top {
  display: none !important;
}
</style>