import { defineStore } from 'pinia';
import moment from 'moment';
import { ownPoint, paymentSubsrc } from '@/api/PaymentApi';
import { nextTick, onMounted } from 'vue';

export const usePaymentsStore = defineStore('payments', () => {
  /** 구독중인 서비스 */
  const subInfo = ref(null);
  /** 월 전체 일진 구매 여부 */
  const isMonthlyFortunePaid = ref(false);

  const formattedSubInfoName = computed(() => {
    if (!subInfo.value) return '-';
    else {
      return subInfo.value.productName;
      // const [type, name] = subInfo.value.productName.replace(' ', '').split('-');
      // return `${name} (${type})`;
    }
  });
  
  const formattedSubInfoPeriod = computed(() => {
    if (!subInfo.value) return '';
    else {
      const startDate = moment(subInfo.value.startDate).format('YYYY.MM.DD');
      const endDate = moment(subInfo.value.endDate).format('YYYY.MM.DD');
      return `${startDate} ~ ${endDate}`;
    }
  });

  /** 보유 포인트 */
  const points = ref(null);

  /** 포인트 포맷팅 */
  const formattedPoints = computed(() => {
    if (points.value === null) return '-';
    else if (points.value < 1000) return points.value;
    else return points.value.toLocaleString('en-US');
  });

  /** 구독중인 서비스 조회 */
  const getSubscription = async () => {
    try {
      const result = await paymentSubsrc({});
      subInfo.value = result;
    } catch (e) {
      subInfo.value = null;
    }
  };

  /** 보유 포인트 조회 */
  const getOwnPoint = async () => {
    try {
      const result = await ownPoint({});
      points.value = result;
    } catch (e) {
      points.value = null;
    }
  };

  /** 구독/포인트 초기화 */
  const clearPaymentsInfo = () => {
    subInfo.value = null;
    points.value = null;
  };

  // '월 전체 일진 구매' 임시 로직
  const tempPurchaseList = ref([]);
  const tempAddPurchaseList = (viewTitle) => {
    tempPurchaseList.value.push(viewTitle);
    tempUpdateIsMonthlyFortunePaid(viewTitle);

    nextTick(() => {
      sessionStorage.setItem(
        'purchaseList',
        JSON.stringify(tempPurchaseList.value)
      );
    });
  };
  const tempUpdateIsMonthlyFortunePaid = (viewTitle) => {
    const matchData = tempPurchaseList.value.find(data => data === viewTitle);
    if (matchData) {
      isMonthlyFortunePaid.value = true;
    } else {
      isMonthlyFortunePaid.value = false;
    }
  }
  onMounted(() => {
    const stored = sessionStorage.getItem('purchaseList');
    if (stored) {
      tempPurchaseList.value = JSON.parse(stored);
      tempUpdateIsMonthlyFortunePaid(moment().format('YYYY년 M월'))
    }
  });

  return {
    subInfo,
    isMonthlyFortunePaid,
    formattedSubInfoName,
    formattedSubInfoPeriod,
    formattedPoints,
    getSubscription,
    getOwnPoint,
    clearPaymentsInfo,
    tempAddPurchaseList,
    tempUpdateIsMonthlyFortunePaid
  };
});
