<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import articleApi from '@/api/ArticleApi'
import boardApi from '@/api/BoardApi'
import { formatCustomDate } from '@/composables/functions'
import { BoardTitleBox } from '@/components/board'
import IconView from '@/components/svg/IconView.vue'
import { patchArticleLike } from '@/api/EowayApi'
import {
  branchShareDataMap,
  convertPathToBranchPage,
  createBranchLink,
} from '@/composables/useBranch'
import { DEV_MODE } from '@/main'

const props = defineProps({
  board: {
    type: String,
    default: '',
  },
  boardId: {
    type: Number,
    default: 0,
  },
  articleId: {
    type: String,
    default: '',
  },
})

const route = useRoute()
const router = useRouter()
const store = useStore()

const {
  VITE_KAKAO_CHANNEL_BASE_URL,
  VITE_KAKAO_CHANNEL_PUBLIC_ID,
  VITE_APP_AWS_S3_ENDPOINT,
  VITE_SHARE_FEED_THUMBNAIL_PATH,
  VITE_WEB_BASE_URL,
} = import.meta.env

const ADD_KAKAO_CHANNEL_URL = `${VITE_KAKAO_CHANNEL_BASE_URL}/${VITE_KAKAO_CHANNEL_PUBLIC_ID}`

const userId = store.getters['user/id']

const listData = ref({})
const likeData = ref({ likeCnt: null, likeClicked: false })

const boardTitle = ref(null)
const comment = ref(null)
// const memberInfo = ref(null)
const memberId = ref(null)
console.log('board는 무엇인가요?', props.board)
const getBoardInfo = async () => {
  try {
    const result = await boardApi().findById(props.boardId)
    boardTitle.value = result.boardTtl
  } catch (error) {
    alert(error.response.data?.message)
  }
}

const getView = async () => {
  try {
    const result = await articleApi().get(props.boardId, props.articleId, {})

    if (!result) {
      router.back()
      return
    }

    listData.value = result?.data
    comment.value = listData.value.comments[0]?.curseMasking
    // memberInfo.value = store.getters[""]
    memberId.value = store.getters['user/id']

    likeData.value.likeCnt = listData.value.likeCnt
    likeData.value.likeClicked = listData.value.likeChecked
  } catch (error) {
    alert(error)
  }
}

const goViewList = () => {
  router.push({
    path: `/customer/${props.board}`,
    query: {
      // 쿼리 파라미터 추가
      tabCate: tabCate.value,
    },
  })
}

const del = async () => {
  if (confirm('삭제하시겠습니까?')) {
    try {
      const result = await articleApi().delete(props.boardId, props.articleId, {})
      if (result) {
        goViewList()
      }
    } catch (error) {
      alert(error.response.data?.message)
    }
  }
}

const tabCate = ref(null)

onMounted(async () => {
  getView()
  getBoardInfo()

  tabCate.value = route.query.cate

  /** Branch SDK 로드 */
  const script = document.createElement('script')
  script.src = 'https://cdn.branch.io/branch-latest.min.js'
  script.async = true
  document.head.appendChild(script)
  /** Branch - 스크립트 로드 완료 시 필요한 설정 */
  const options = { open_app: true }
  script.onload = () => {
    branch.init(import.meta.env.VITE_BRANCH_KEY, options, () => {})
  }
})

onUnmounted(() => {
  /** Branch - 언마운트 시 SDK 스크립트 제거 (선택 사항) */
  const script = document.querySelector('script[src="https://cdn.branch.io/branch-latest.min.js"]')
  if (script) {
    document.head.removeChild(script)
  }
})

const noDate = computed(() => ['word', 'faq'].includes(props.board))

/** 카카오 채널 추가하기 */
const addKakaoChannel = () => {
  window.open(ADD_KAKAO_CHANNEL_URL, '_blank')
}

/** 좋아요 버튼 클릭 */
const patchLike = async () => {
  try {
    const result = await patchArticleLike(props.articleId)
    likeData.value = result
  } catch (error) {}
}

/** 공유하기 버튼 클릭 */
const onPressShare = (item) => {
  if (!item) return null
  else shareWithKakao(item)
}

/** 카카오 공유하기 */
const shareWithKakao = async (item) => {
  // 앱에 전달할 페이지
  const page = convertPathToBranchPage(route.path)

  // 카톡 공유하기 메시지
  const message =
    page && branchShareDataMap[page]
      ? branchShareDataMap[page].messagePreset
      : branchShareDataMap['default'].messagePreset

  // 카톡 공유하기 이미지
  const thumbnailUrl = `${VITE_APP_AWS_S3_ENDPOINT}${VITE_SHARE_FEED_THUMBNAIL_PATH}`
  const imageUrl = item?.thumbnail?.fileUri || thumbnailUrl

  const branchLinkPayload = {
    page,
    contentId: item.articleId,
    contentTitle: item.articleTtl,
    imageUrl,
    login: userId,
  }

  let branchUrl = ''
  try {
    branchUrl = await createBranchLink(branchLinkPayload)
  } catch (e) {
    DEV_MODE && console.log('branch link 생성에 실패하였습니다.')
  }

  const baseUrl = DEV_MODE ? 'https://d25web.thegam.io' : VITE_WEB_BASE_URL
  const webUrl = `${baseUrl}${route.path}`

  if (typeof Kakao !== 'undefined') {
    if (!Kakao.isInitialized()) {
      await Kakao.init(import.meta.env.VITE_KAKAO_APP_KEY)
    }
  } else {
    DEV_MODE && console.error('Kakao SDK가 로드되지 않았습니다.')
    return
  }

  Kakao.Share.sendDefault({
    objectType: 'feed',
    content: {
      title: item.articleTtl,
      description: message,
      imageUrl,
      link: {
        webUrl,
        mobileWebUrl: branchUrl,
        androidExecutionParams: `page=${page}&articleId=${item.articleId}`,
        iosExecutionParams: `page=${page}&articleId=${item.articleId}`,
      },
    },
    buttons: [
      {
        title: '자세히 보기',
        link: {
          webUrl,
          mobileWebUrl: branchUrl,
          androidExecutionParams: `page=${page}&articleId=${item.articleId}`,
          iosExecutionParams: `page=${page}&articleId=${item.articleId}`,
        },
      },
    ],
  })
}

/** 파일 다운 시 원래 이름으로 다운되도록 */
const downloadFile = async (url, filename) => {
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  } catch (error) {
    console.error('File download failed:', error)
  }
}
</script>

<template>
  <div class="">
    <BoardTitleBox :board="board" />

    <div class="board-view-tit-st2-box">
      <div class="like-box">
        <button class="icon-box" @click="patchLike()">
          <IconLike class="icon-like" :class="{ active: likeData.likeClicked }" />
        </button>
        <button class="icon-share none-txt w24" @click="onPressShare(listData)">공유하기</button>
      </div>
      <div class="tit-box">
        <div class="tit-b">
          <div>{{ listData.articleTtl }}</div>
          <div class="art-icon-box">
            <span
              v-for="(icon, index2) in listData.icon ? listData.icon.split(',') : []"
              :key="index2"
              class="txt"
              :class="icon"
            >
              {{ icon === 'RECO' ? '추천' : icon }}
            </span>
          </div>
        </div>
        <div class="tit-s">
          {{ listData.articleTtlSub }}
        </div>
      </div>
      <div v-if="!noDate" class="date-box">
        <div v-if="props.board === 'event'">
          {{ formatCustomDate(listData.eventStartDt) }} ~
          {{ formatCustomDate(listData.eventEndDt) }}
        </div>
        <div v-else class="date">
          {{ formatCustomDate(listData.displayDate) }}
        </div>
        <div class="hit">
          <span class="icon-hit"><IconView /></span><span>{{ listData.viewCount }}</span>
        </div>
      </div>
    </div>
    <div class="article-content-box">
      <div class="" v-html="listData.articleCtn" />
    </div>
    <div class="board-del-btn-box">
      <button v-if="memberId === listData.userId" class="btn-del-line-3" @click="del()">
        삭제
      </button>
    </div>
    <div v-if="comment" class="board-answer-box">
      <div class="answer-tit">만세력 운영진 답변</div>
      <div class="answer-cont">
        <div class="" v-html="comment" />
      </div>
    </div>
    <div class="article-file-box" v-if="listData.files && listData.files.length > 0">
      <div class="tit-s">첨부파일</div>
      <div class="file-list">
        <div
          v-for="file in listData.files"
          :key="file.id"
          class="item-list"
          @click="downloadFile(file.fileUri, file.fileNameClient)"
        >
          {{ file.fileNameClient }}
        </div>
      </div>
    </div>

    <div class="article-keyword-box" v-if="listData.keywords && listData.keywords.length > 0">
      <span v-for="(keyword, index) in listData.keywords" :key="index" class="key-text">
        #{{ keyword }}
      </span>
    </div>
    <div class="line-wd100"></div>
    <section class="btn-com gap-xl ceneter">
      <button class="btn-line w-large bg-w" @click="goViewList()">목록으로</button>
    </section>
  </div>
</template>
