import { defineStore } from "pinia";

export const useLoginStore = defineStore('login', () => {
  /** 로그인 모달 열림 상태 */
  const isLoginModalVisible = ref(false);

  /** 사용자 로그인 해결 상태 */
  let resolveLogin = null;

  /** 로그인 모달 열기 */
  const showLoginModal = async () => {
    isLoginModalVisible.value = true;

    return new Promise((resolve, reject) => {
      resolveLogin = resolve;
    });
  };

  /** 로그인 모달 > 로그인 */
  const handleLoginConfirm = () => {
    isLoginModalVisible.value = false;
    resolveLogin(true);
  };

  /** 로그인 모달 > 취소 */
  const handleLoginCancel = () => {
    isLoginModalVisible.value = false;
    resolveLogin(false);
  };

  return {
    isLoginModalVisible,
    showLoginModal,
    handleLoginConfirm,
    handleLoginCancel,
  }  
})