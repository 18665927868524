<script setup>
import { useRoute } from 'vue-router'

definePage({ meta: { layout: 'empty' } });

const route = useRoute();
const router = useRouter();
const pathVal = ref('');

onMounted(async () => {
  pathVal.value = route.query.path;
  router.push(pathVal.value)
})
</script>

<template>
  <div class="empty-page__container">빈 페이지</div>
</template>

<style>
.empty-page__container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
