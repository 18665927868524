/**
 * 스와이프 제스처를 처리하기 위한 커스텀 훅.
 *
 * @param {Object} options - 제스처 훅의 옵션.
 * @param {Function} [options.onSwipeLeft] - 왼쪽으로 스와이프할 때 호출되는 콜백 함수.
 * @param {Function} [options.onSwipeRight] - 오른쪽으로 스와이프할 때 호출되는 콜백 함수.
 * @param {number} [options.SWIPE_THRESHOLD=100] - 스와이프를 감지하기 위한 임계값(픽셀 단위). 기본값은 100.
 * @returns {Object} - 제스처 핸들러를 요소에 연결하기 위한 메서드 집합.
 */
export function useGesture({
  onSwipeLeft,
  onSwipeRight,
  SWIPE_THRESHOLD = 100 // 스와이프를 감지하기 위한 기본 임계값
} = {}) {
  /** 드래그/스와이프 시작 X좌표 */
  const startX = ref(0);
  /** 드래그/스와이프 동작 상태 */
  const isDragging = ref(false);
  /** 드래그/스와이프 X좌표 이동 거리 */
  const translateX = ref(0);

  const handleStart = (event) => {
    const point = event.touches ? event.touches[0] : event;
    startX.value = point.clientX;
    isDragging.value = true;
  };

  const handleMove = (event) => {
    if (!isDragging.value) return;

    const point = event.touches ? event.touches[0] : event;
    const deltaX = point.clientX - startX.value;

    if (Math.abs(deltaX) > SWIPE_THRESHOLD) {
      isDragging.value = false;
      if (deltaX > 0) {
        onSwipeRight?.();
      } else {
        onSwipeLeft?.();
      }
      translateX.value = 0;
    }
  };

  const handleEnd = () => {
    if (isDragging.value) {
      isDragging.value = false;
      translateX.value = 0;
    }
  };

  return {
    handleStart,
    handleMove,
    handleEnd,
  };
}